import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import EnergyData from "../../../Common/EnergyData";
import Divider from "@mui/material/Divider";
import "../Trackers/Trackers.scss";
import Popover from "@mui/material/Popover";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import TableNoCheckBox from "../../../Common/TableNoCheckBox";
import useErrorHandler from "../../../../utils/errorHandler";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import ButtonSlider from "../../../Common/ButtonSlider";
import TimestampFormatter from "../../../Common/TimestampFormatter";
import SmbDetails from "./SmbDetails";
const invService = new CosmicInverterService();

function SMB() {
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [smbIndex, setSmbIndex] = useState(0);

  const handleFetchError = useErrorHandler();

  const columns = [
    { field: "name", title: "SMB" },

    { field: "current", title: "Current (A)" },
    { field: "voltage", title: "Voltage (V)" },
    { field: "energy", title: "Power (kW)" },
    { field: "status", title: "Status" },

    { field: "raised_at", title: "Last Updated Timestamp" },
  ];

  const PowerPlant = [
    {
      title: "Cumulative",
      subtitle: "Energy Loss",
      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1: "568* MWh",

      subtitle2: "Apparent",
      value2: "525* MVah",

      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#00529A",
    },
    {
      title: "Daily",
      subtitle: "Energy Loss",

      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1: "568* MWh",

      subtitle2: "Apparent",
      value2: "525* MVah",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#FDA737",
    },
    {
      title: "Weekly",
      subtitle: "Energy Loss",
      energy: "25999",
      subtitle1: "Active (Fundamental)",
      value1: "568* MWh",

      subtitle2: "Apparent",
      value2: "525* MVah",
      PlantIcon: "https://s3.amazonaws.com/smarttrak.co/v2Images/energySMB.svg",
      colorCode: "#04724D",
    },
  ];
  const [anchorEl, setAnchorEl] = useState(
    Array.from({ length: PowerPlant.length }, () => null)
  );
  const [loadingState, setLoadingState] = useState({
    smbList: false,
  });
  const [id, setId] = useState(localStorage.getItem("plantId"));
  const [currentPage, setCurrentPage] = useState(0);
  const [smbDataArray, setSmbDataArray] = useState({
    devices_data: [],
    device_summary: {},
  });

  const [tableData, setTableData] = useState([]);
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);

  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);
  useEffect(() => {
    handleSMBList(id);
  }, [id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const visibleSmbs = [
    {
      device_details: { name: "SMBs Dashboard" },
    },
    ...smbDataArray.devices_data,
  ];
  const handleSmbClick = (smbDevice, id, index) => {
    setSmbIndex(index);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handlePopoverOpen = (index, event) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };
  const handlePopoverClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const handleSMBList = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, smbList: true }));
      const res = await invService.getDeviceLevelData(id, "smbs");
      if (res.status === 200) {
        setSmbDataArray(res.data);
        const tableDatas = res.data.devices_data.map((item) => ({
          name: item?.device_details?.name ?? "--",

          current: item?.device_data?.current_total_A.toFixed(2),
          voltage: item?.device_data?.voltage_average_V.toFixed(2),
          energy: item?.device_data?.power_total_W.toFixed(2),
          status: item?.device_details?.edge_status === true ? "ON" : "OFF",
          raised_at: (
            item?.device_data?.timestamp_edge_local.match(
              /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/
            ) || []
          )
            .slice(1)
            .join(" "),
        }));
        setTableData(tableDatas);
      }
      setLoadingState((prevState) => ({ ...prevState, smbList: false }));
    } catch (error) {
      console.log(error);

      setLoadingState((prevState) => ({ ...prevState, smbList: false }));
      handleFetchError(error, "SMB List");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="home-container padding_top_0vh">
      {loadingState.smbList && <SimpleBackdrop open={loadingState.smbList} />}

      <Grid container className="grid-container">
      <div className="sticky_class">
        <div className="btnSlides" style={{ position: "relative" }}>
          <ButtonSlider
            isValueData={visibleSmbs}
            handleInvDeatil={handleSmbClick}
            activeIndex={smbIndex}
            itemwidth={120}
          />

          {loadingState.smbList && (
            <SimpleBackdrop open={loadingState.smbList} />
          )}
        </div>
        </div>
        {smbIndex === 0 ? (
          <>
            <Grid
              item
              lg={5}
              xs={12}
              sm={12}
              md={12}
              className="total_power_container"
            >
              <Paper className="smb-paper">
                <p className="Poppins_24px_rem" style={{ color: "#2F67F8" }}>
                  SMB
                </p>
                <div className="center_div">
                  <div className="smb-one-div">
                    <div className="flex-start-column-two">
                      <p className="raleway_14px_blue">
                        Total Power Generation
                      </p>
                      <p
                        className="raleway_32px_rem center_div_nogap"
                        style={{ fontFamily: "Poppins" }}
                      >
                        {smbDataArray?.device_summary?.power_total_W_total?.value.toFixed(
                          2
                        ) ?? "--"}
                        <span className="Poppins_24px_black">
                          {" "}
                          {smbDataArray?.device_summary?.power_total_W_total
                            ?.unit ?? "--"}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="smb-one-div">
                    <div>
                      <img
                        src="https://s3.amazonaws.com/smarttrak.co/v2Images/vcbStatus.svg"
                        alt="invStatus"
                        style={{ width: "3.125rem", height: "3.125rem" }}
                      />
                    </div>
                    <div className="flex-start-column-two">
                      <p className="raleway_14px_blue">Total Working Strings</p>
                      <p
                        className="raleway_32px_rem"
                        style={{ fontFamily: "Poppins" }}
                      >
                        {smbDataArray?.active_devices ?? "--"}/
                        <span className="Poppins_24px_black">
                          {smbDataArray?.total_devices ?? "--"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              lg={6.9}
              xs={12}
              sm={12}
              md={12}
              className="center_center_column"
            >
              <Grid container className="grid-container">
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sm={12}
                  md={12}
                  className="center_center_flex_gap"
                >
                  <Paper className="gen-power_paper">
                    <p
                      className="Poppins_24px_rem"
                      style={{ color: "#212121", textAlign: "center" }}
                    >
                      Generated Energy
                    </p>
                  </Paper>
                </Grid>

                <Grid
                  item
                  lg={3.5}
                  xs={12}
                  sm={3.5}
                  md={3.5}
                  className="energy-grid"
                >
                  <Paper className="energy-paper">
                    <EnergyData
                      ACEnergy={"--"}
                      unit="kWh"
                      title="Today’s"
                      colorCode="#00529A"
                    />
                  </Paper>
                </Grid>
                <Divider className="divider-style" orientation="vertical" />
                <Grid
                  item
                  lg={3.5}
                  xs={12}
                  sm={3.5}
                  md={3.5}
                  className="energy-grid"
                >
                  <Paper className="energy-paper">
                    <EnergyData
                      ACEnergy={"--"}
                      unit="kWh"
                      title="Yesterday’s"
                      colorCode="#FDA737"
                    />
                  </Paper>
                </Grid>
                <Divider className="divider-style" orientation="vertical" />
                <Grid
                  item
                  lg={3.5}
                  xs={12}
                  sm={3.5}
                  md={3.5}
                  className="energy-grid"
                >
                  <Paper className="energy-paper">
                    <EnergyData
                      ACEnergy={"--"}
                      unit="kWh"
                      title="Monthly"
                      colorCode="#04724D"
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            {PowerPlant.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.95}
                xs={12}
                sm={12}
                md={3.9}
                className="power-plant-grid-exc"
              >
                <Paper className="power-plant-paper-exc">
                  <div className="center_div">
                    <div className="icon-exc-div">
                      <img
                        src={ele.PlantIcon}
                        alt="plant"
                        style={{ width: "2rem" }}
                      />
                    </div>
                    <div className="flex-start-column">
                      <p className="Poppins_18px_rem">{ele.title}</p>
                      <p className="Poppins_18px_rem">{ele.subtitle}</p>
                    </div>
                  </div>
                  <div
                    // onMouseEnter={(event) => handlePopoverOpen(index, event)}
                    //  onMouseLeave={() => handlePopoverClose(index)}
                    onClick={(event) => handlePopoverOpen(index, event)}
                  >
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/ep_info-filled.svg"
                      alt="plant"
                      style={{ width: "1rem", cursor: "pointer" }}
                    />
                  </div>
                </Paper>
                <Paper className="powerplant-sec-paper">
                  <div
                    className="powerplant-div-exc"
                    style={{
                      borderLeft: `0.2rem solid ${ele.colorCode}`,
                    }}
                  >
                    <p className="raleway_14px_rem">{ele.subtitle1}</p>
                    <p className="Poppins_18px_rem">
                      {ele.value1 ? ele.value1 : "--"}
                    </p>
                  </div>
                  <div
                    className="powerplant-div-exc"
                    style={{
                      borderLeft: `0.2rem solid ${ele.colorCode}`,
                    }}
                  >
                    <p className="raleway_14px_rem">{ele.subtitle2}</p>
                    <p className="Poppins_18px_rem">
                      {ele.value2 ? ele.value2 : "--"}
                    </p>
                  </div>
                </Paper>
                <Popover
                  key={index}
                  open={Boolean(anchorEl[index])}
                  anchorEl={anchorEl[index]}
                  onClose={() => handlePopoverClose(index)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  PaperProps={{ className: "customPopoverPaper-exc" }}
                >
                  <div className="center_div">
                    <p className="raleway_14px_rem">{ele.subtitle1}</p>:
                    <p className="Poppins_18px_rem">
                      {ele.value1 ? ele.value1 : "--"}
                    </p>
                  </div>
                  <div className="center_div">
                    <p className="raleway_14px_rem">{ele.subtitle2}</p>:
                    <p className="Poppins_18px_rem">
                      {ele.value2 ? ele.value2 : "--"}
                    </p>
                  </div>
                </Popover>
              </Grid>
            ))}

            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={12}
              className="notification-grid"
            >
              <Paper className="notification-paper-one">
                <div style={{ padding: "1rem" }}>
                  <p className="Poppins_24px_black">Important Notifications</p>
                </div>
                <Divider
                  sx={{ mt: 1 }}
                  style={{ border: "0.0625rem solid #D9D9D9" }}
                />
              </Paper>
              {/* {NotificationData.map((ele, index) => ( */}
              <Paper
                className="notification-paper"
                // key={index}
                sx={
                  {
                    // borderBottom:
                    //   index === NotificationData.length - 1
                    //     ? "none"
                    //     : "0.125rem solid #D9D9D9",
                  }
                }
              >
                <p className="Poppins_18px_rem">No Notification yet</p>
                {/* <div
                    style={{
                      padding: "1rem 1.5rem",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      gap: "0.5rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <WarningRoundedIcon style={{ color: "#FFA287" }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "baseline",
                      }}
                    >
                      <p className="Poppins_18px_rem">
                        {ele.title}
                        <span style={{ fontWeight: "400" }}>
                          &nbsp;{ele.disc}
                        </span>
                      </p>
                    </div>
                  </div> */}
              </Paper>
              {/* ))} */}
            </Grid>
            <Grid
              item
              lg={12}
              xs={12}
              sm={12}
              md={11.9}
              className="center_center_column"
            >
              <div className="space-btn-center">
                <div className="flex-start-column">
                  <p
                    className="Poppins_14px_rem"
                    style={{
                      fontWeight: "400",
                      display: "flex",
                      gap: "0.5rem",
                    }}
                  >
                    Last Updated on{" "}
                    <p
                      className="Poppins_14px_rem"
                      style={{
                        color: "#5E6064",
                        fontWeight: "500",
                        fontStyle: "italic",
                        textAlign: "center",
                      }}
                    >
                      (Data refreshed every 2 mins)
                    </p>
                  </p>

                  <p className="Poppins_18px_rem">
                    {smbDataArray?.last_updated_timestamp ? (
                      <TimestampFormatter
                        dateString={smbDataArray?.last_updated_timestamp}
                      />
                    ) : (
                      "--"
                    )}
                  </p>
                </div>

                <div></div>
              </div>

              <TableNoCheckBox
                data={tableData}
                columns={columns}
                page={page}
                rowsPerPage={rowsPerPage}
                order={order}
                orderBy={orderBy}
                handlePageChange={handleChangePage}
                handleRowsPerPageChange={handleChangeRowsPerPage}
                handleRowClick={handleSmbClick}
                searchQuery={searchQuery}
                handleSearchChange={handleSearchChange}
                serchText="Search SMB"
                titleText="SMB List"
              />
            </Grid>
          </>
        ) : (
          <SmbDetails />
        )}
      </Grid>
    </div>
  );
}

export default SMB;
