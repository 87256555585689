import React from 'react';

const TrackerIcon = (props) => {
  const {
    fillColor = '#111111', 
    width = "24",
    height = "24",
    ...rest                
  } = props;
  return ( <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 512"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0,512) scale(0.1,-0.1)"
      fill={fillColor}
      stroke="none"
    >
      <path d="M626 5109 c-62 -15 -153 -68 -197 -116 -22 -24 -55 -74 -72 -111 l-32 -67 0 -2256 0 -2255 37 -76 c45 -91 103 -147 196 -191 l67 -32 1936 0 1935 0 76 37 c91 45 147 103 191 196 l32 67 0 2255 0 2255 -32 67 c-44 93 -100 151 -191 196 l-76 37 -1915 2 c-1134 0 -1932 -3 -1955 -8z m3848 -161 c55 -16 138 -99 154 -154 18 -60 18 -3768 0 -3828 -16 -55 -99 -138 -154 -154 -60 -18 -3768 -18 -3828 0 -55 16 -138 99 -154 154 -18 60 -18 3768 0 3828 15 51 99 137 148 153 53 17 3777 17 3834 1z m-2185 -4308 c976 -3 1652 -9 1632 -14 -48 -12 -133 -99 -149 -152 -28 -94 -8 -169 63 -239 27 -28 66 -56 85 -62 53 -17 -3217 -18 -3274 -1 -55 16 -138 99 -154 154 -7 26 -12 108 -12 216 l0 174 72 -35 72 -36 1665 -5z m2339 -310 c-16 -59 -96 -141 -154 -158 -56 -16 -446 -15 -394 1 49 16 133 102 148 153 28 94 8 168 -63 240 -31 31 -65 55 -86 60 -23 6 35 11 191 14 l226 5 69 35 70 35 3 -170 c2 -113 -1 -185 -10 -215z m-573 125 c50 -49 15 -135 -55 -135 -41 0 -80 39 -80 80 0 41 39 80 80 80 19 0 40 -9 55 -25z" />
      <path d="M2405 3989 c-367 -54 -681 -282 -849 -616 -213 -425 -126 -943 214 -1283 440 -439 1140 -439 1580 0 98 98 163 191 225 322 198 420 108 925 -225 1258 -248 248 -605 368 -945 319z m374 -174 c179 -44 320 -124 456 -260 137 -137 216 -276 262 -461 24 -95 24 -333 0 -428 -46 -182 -128 -328 -261 -462 -134 -133 -280 -215 -462 -261 -95 -24 -333 -24 -428 0 -182 46 -328 128 -462 261 -133 134 -215 280 -261 462 -24 95 -24 333 0 428 78 313 293 561 587 679 56 22 134 43 230 61 49 9 276 -3 339 -19z" />
      <path d="M2425 3505 c-289 -63 -505 -330 -505 -625 0 -237 143 -468 352 -569 194 -94 386 -94 577 0 147 73 272 221 324 384 18 57 22 93 22 190 -1 104 -5 130 -27 193 -38 106 -83 177 -164 257 -150 150 -372 216 -579 170z m226 -155 c177 -34 327 -177 374 -355 65 -251 -94 -513 -350 -580 -342 -89 -669 238 -580 580 24 90 71 170 139 232 117 109 264 152 417 123z" />
      <path d="M985 455 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26 -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25 -26 25 -215 25 -189 0 -191 0 -215 -25z" />
      <path d="M1625 455 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26 -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25 -26 25 -215 25 -189 0 -191 0 -215 -25z" />
      <path d="M2265 455 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26 -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25 -26 25 -215 25 -189 0 -191 0 -215 -25z" />
      <path d="M2905 455 c-16 -15 -25 -36 -25 -55 0 -19 9 -40 25 -55 24 -25 26 -25 215 -25 189 0 191 0 215 25 16 15 25 36 25 55 0 19 -9 40 -25 55 -24 25 -26 25 -215 25 -189 0 -191 0 -215 -25z" />
    </g>
  </svg>)
};

export default TrackerIcon;
