import React, { useState, useMemo } from 'react';
import DynamicArrow from '../SVG/DynamicArrow';
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Paper from '@mui/material/Paper';

function EnergyGenerated(props) {
    const { customWidth, dropdownWidth, isValueData } = props;

    const [isGenType, setIsGenType] = useState("Current Output");

    const generationTypeOptions = isValueData?.energy_comparison ? isValueData.energy_comparison.map(item => item.type) : [];

    const handleChangeGen = (option) => {
        setIsGenType(option.value);
    };

    const selectedData = isValueData?.energy_comparison ? isValueData.energy_comparison.find(item => item.type === isGenType) : {};

    const ACPercent = selectedData?.["AC Energy"]?.energy_diff?.value !== undefined && selectedData?.["AC Energy"]?.energy_diff?.value !== null 
        ? parseFloat(selectedData["AC Energy"].energy_diff.value.toFixed(2)) 
        : "--";

    const ghiPercent = selectedData?.["GHI"]?.ghi_diff?.value !== undefined && selectedData?.["GHI"]?.ghi_diff?.value !== null 
        ? parseFloat(selectedData["GHI"].ghi_diff.value.toFixed(2)) 
        : "--";

    const upwardPath = "M5.20759 0.529365C5.6079 0.00935769 6.3921 0.00935763 6.79241 0.529365L11.6888 6.89001C12.195 7.54757 11.7263 8.5 10.8964 8.5H1.10358C0.273737 8.5 -0.195026 7.54757 0.311171 6.89001L5.20759 0.529365Z";
    const downwardPath = "M6.79241 8.47063C6.3921 8.99064 5.6079 8.99064 5.20759 8.47063L0.311171 2.10999C-0.195026 1.45243 0.273737 0.5 1.10358 0.5H10.8964C11.7263 0.5 12.195 1.45243 11.6888 2.10999L6.79241 8.47063Z";
    const greenFill = "#30B900";
    const redFill = "#FF0000"; 

    const arrowComponentAC = useMemo(
        () => ACPercent < 0 ? (
            <DynamicArrow pathData={downwardPath} fillColor={redFill} width={12} height={12} />
        ) : (
            <DynamicArrow pathData={upwardPath} fillColor={greenFill} width={12} height={12} />
        ),
        [ACPercent]
    );

    const arrowComponentGHI = useMemo(
        () => ghiPercent < 0 ? (
            <DynamicArrow pathData={downwardPath} fillColor={redFill} width={12} height={12} />
        ) : (
            <DynamicArrow pathData={upwardPath} fillColor={greenFill} width={12} height={12} />
        ),
        [ghiPercent]
    );

    return (
        <Paper className="energy-paper" sx={{height:"100%", justifyContent:"space-between"}}>
            <div className="space-betwn_div">
                <p className="Poppins_18px_rem">Energy Generated</p>
                <div className='width_50' style={{ width: dropdownWidth || "13vw" }}>
                    <Dropdown
                        className="recommendations-text"
                        id="navy_blue_text"
                        options={generationTypeOptions}
                        onChange={handleChangeGen}
                        value={isGenType}
                        placeholder="Select Generation Type"
                        placeholderClassName="custom-placeholder"
                    />
                </div>
            </div>
            <div className="space-betwn_div">
                <p className="Poppins_16px_rem" style={{ fontWeight: "500" }}>AC Energy</p>
                <div className="val_arrow" style={{ width: customWidth || "12.5vw" }}>
                    <p className="Poppins_18px_rem">
                        {selectedData?.["AC Energy"]?.energy?.value !== undefined && selectedData["AC Energy"].energy.value !== null 
                            ? selectedData["AC Energy"].energy.value.toFixed(2) 
                            : "--"}{" "}
                        {selectedData?.["AC Energy"]?.energy?.unit || ""}
                    </p>
                    <div className="green-tab">
                        {arrowComponentAC}
                        <p className="Poppins_18px_rem" style={{ color: ACPercent < 0 ? redFill : greenFill }}>
                            {ACPercent || "--"}%
                        </p>
                    </div>
                </div>
            </div>
            <div className="space-betwn_div">
                <p className="Poppins_16px_rem" style={{ fontWeight: "500" }}>GHI</p>
                <div className="val_arrow" style={{ width: customWidth || "12.5vw" }}>
                    <p className="Poppins_18px_rem">
                        {selectedData?.["GHI"]?.ghi?.value !== undefined && selectedData["GHI"].ghi.value !== null 
                            ? selectedData["GHI"].ghi.value.toFixed(2) 
                            : "--"}{" "}
                        {selectedData?.["GHI"]?.ghi?.unit || ""}
                    </p>
                    <div className="green-tab">
                        {arrowComponentGHI}
                        <p className="Poppins_18px_rem" style={{ color: ghiPercent < 0 ? redFill : greenFill }}>
                            {ghiPercent || "--"}%
                        </p>
                    </div>
                </div>
            </div>
            {selectedData?.description&&(<p className="Poppins_14px_rem" style={{ color: "#5E6064", fontWeight: "500", fontStyle: "italic", textAlign: "center" }}>
                {selectedData?.description || "--"}
            </p>)}
        </Paper>
    );
}

export default EnergyGenerated;
