import React, { useEffect } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Divider from "@mui/material/Divider";

function DigitalTwin() {
  const clientControls = useAnimation();
  const challengeControls = useAnimation();
  const solControls = useAnimation();
  const resControls = useAnimation();
  const conControls = useAnimation();

  const [clientRef, inViewClient] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [challengeRef, inViewChallenge] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [conRef, inViewCon] = useInView({ triggerOnce: false, threshold: 0.1 });
  const isMobileOne = window.innerWidth <= 900;

  useEffect(() => {
    clientControls.start({
      opacity: inViewClient ? 1 : 0,
      y: inViewClient ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [clientControls, inViewClient]);

  useEffect(() => {
    challengeControls.start({
      opacity: inViewChallenge ? 1 : 0,
      y: inViewChallenge ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [challengeControls, inViewChallenge]);
  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);

  useEffect(() => {
    conControls.start({
      opacity: inViewCon ? 1 : 0,
      y: inViewCon ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [conControls, inViewCon]);

  const solutionM = [
    {
      title: "Real-Time Process",
      extraTitle: "Monitoring",

      desc: "Monitor nutrient levels, environmental conditions, and cell growth dynamics in real time through virtual replicas of biomanufacturing processes",
    },
    {
      title: "Predictive Analytics for",
      extraTitle: "Batch Success",
      desc: "Leverage AI-driven models to optimize cell growth, predict failures, and maximize batch yields through data-driven insights",
    },
    {
      title: "Dynamic Process",
      extraTitle: "Control",

      desc: "Automate process adjustments, minimize manual interventions, and maintain consistent product quality with Smarttrak AI Digital Twin technology",
    },

    {
      title: "Regulatory",
      extraTitle: "Compliance Simplified",
      desc: "Streamline documentation an automate reporting, and ensure traceability to meet regulatory standards effortlessly",
    },
  ];
  const results = [
    {
      title: "Enhanced Operational ",
      extraT: "Efficiency",
      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/digital_kone.png",
      desc: "Automate workflows, reduce cycle times, and increase throughput without compromising quality",
    },
    {
      title: "Improved Profit",
      extraT: "Margins",

      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/digital_ktwo.png",

      desc: "Cut costs by minimizing failures , and optimizing resources accelerating time-to-market for therapies",
    },
    {
      title: "Minimized",
      extraT: "Losse",

      imgUrl:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/digital_kthree.png",

      desc: "Use predictive analytics to mitigate risks, ensure consistency, and deliver safe, high-quality products",
    },
  ];

  const keyPoints = [
    {
      keyImage: "https://s3.amazonaws.com/smarttrak.co/newHomePage/dc_one.svg",
      title: "Complex Processes",
      description:
        "Intricate workflows, such as cell expansion and protein synthesis, are prone to variability.",
    },
    {
      keyImage: "https://s3.amazonaws.com/smarttrak.co/newHomePage/dc_two.svg",
      title: "High Failure Rates",
      description:
        "Batch failures due to unforeseen variables lead to wasted resources.",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/dc_three.svg",
      title: "Regulatory Scrutiny",
      description:
        "Compliance with stringent standards demands meticulous monitoring and documentation.",
    },
    {
      keyImage: "https://s3.amazonaws.com/smarttrak.co/newHomePage/dc_four.svg",
      title: "Operational Inefficiencies",
      description:
        "Manual interventions increase costs and slow down production cycles.",
    },
  ];
  const WhyChoose = [
    {
      keyImage: "https://s3.amazonaws.com/smarttrak.co/newHomePage/why_one.svg",
      title: "Proven Expertise",
      description:
        "With years of experience delivering Digital Twin solutions for complex industries like renewable energy, we bring unparalleled precision and scalability to life sciences.",
    },
    {
      keyImage: "https://s3.amazonaws.com/smarttrak.co/newHomePage/why_two.svg",
      title: "Advanced AI Integration",
      description:
        "Our technology combines predictive analytics, machine learning, and real-time data integration to deliver actionable insights that drive results.",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/why_three.svg",
      title: "Scalable and Secure",
      description:
        "Smarttrak AI's solutions are built to scale with your business, ensuring seamless integration and compliance with industry standards such as FDA, HIPAA, and GMP.",
    },
  ];
  const useCase = [
    {
      title: "Cell and Gene Therapy",
      description: [
        "Optimal cell expansion conditions",
        "Precise control over manufacturing workflows",
        "Faster, more reliable delivery of personalized therapies",
      ],
    },
    {
      title: "Biologics Manufacturing",
      description: [
        "Real-time tracking of protein synthesis",
        "Automated adjustments for improved yield",
        "Reduced risk of contamination",
      ],
    },
    {
      title: "Vaccine Production",
      description: [
        "Optimize fermentation and purification processes",
        "Maintain sterility with dynamic monitoring",
        "Scale production to meet global demands",
      ],
    },
  ];

  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>
      <div
        className="expert_div gap_1vw flex_col our_solution_main"
        id="our_sol"
      >
        <div className="expert_div flex_crct gap_1vw justify_space">
          <div className="expert_div width_60 ">
            <p className="Raleway_61px font_500 c_secBlack text_c">
              Digital Twin Solutions for Bio-Process, Life Sciences, and
              Biomanufacturing
            </p>
          </div>

          <div className="expert_div width_casestudy">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/digital_twin_one.png"
              alt="solar deep learning"
            />
          </div>
        </div>

        <motion.div
          className="expert_div flex_reverse gap_3vw justify_space"
          id="our_sol"
          ref={clientRef}
          initial={{ opacity: 0, y: 50 }}
          animate={clientControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_40">
            <img
              className="div_width"
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/digital_twin_new.png"

              alt="solar deep learning"
            />
          </div>
          <div className="expert_div width_60 flex_col gap_1vw align_item">
            <p className="Raleway_48px_600 c_thirdBlack text_c">
              Revolutionizing Bioprocesses with Digital Twin Technology
            </p>
            <p className="Raleway_31px_dark opacity_8" id="text_just">
              Smarttrak AI introduces cutting-edge Digital Twin solutions
              tailored for the biopharmaceutical and life sciences industries.
              By combining advanced AI and real-time data integration, our
              technology transforms complex bioprocesses into highly efficient,
              predictable scalable operations. Experience a new era of
              precision, efficiency, and innovation with Smarttrak AI.
            </p>
          </div>
        </motion.div>
        <motion.div
          className="expert_div flex_col"
          id="our_sol"
          ref={challengeRef}
          initial={{ opacity: 0, y: 50 }}
          animate={challengeControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div flex_col gap_1vw" id="our_sol">
            <p className="Raleway_48px_600 c_thirdBlack text_cen">
              The Challenges in Biomanufacturing
            </p>
            <p className="Raleway_31px_dark opacity_8 text_cen text_j">
              Biomanufacturing is a cornerstone of modern medicine
              <br />
              but it faces significant hurdles
            </p>
          </div>

          <Grid container className="key_point_grid">
            {keyPoints.map((item, index) => (
              <Grid
                item
                key={index}
                lg={2.9}
                xs={12}
                sm={5.9}
                md={5.9}
                className="expert_div gap_1vw"
              >
                <Paper className="key_cards">
                  <div className="expert_div digital_keys">
                    <img src={item.keyImage} alt="keyImage" />
                  </div>
                  <div
                    className="expert_div gap_1vw flex_col text_cen 
                "
                  >
                    <p className="Raleway_28px  c_lightdrak">
                      {item.title}
                    </p>
                    <p
                      className="Raleway_26px_500 opacity_8 "
                      style={{ height: isMobileOne ? "auto" : "6vw" }}
                    >
                      {item.description}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <div className="expert_div flex_col gap_1vw"  id="our_sol">
            <p className="Raleway_48px_600 c_dark text_cen" id="our_sol">
              How Smarttrak AI’s Digital Twin Solves These Problems
            </p>
          </div>
          <motion.div
            className="expert_div flex_col text_cen"
            id="our_sol"
            ref={solRef}
            initial={{ opacity: 0, y: 50 }}
            animate={solControls}
            transition={{ duration: 0.5 }}
          >
            <Grid container className="grid-container gap_1vw">
              {solutionM.map((ele, index) => (
                <Grid
                  key={index}
                  item
                  lg={2.8}
                  xs={12}
                  sm={5.8}
                  md={5.8}
                  className="abour_mission_grid"
                >
                  <Paper
                    className="about_mission cursor_p"
                    id="valData_d"
                    style={{ flex: 1 }}
                  >
                    <div className="expert_div flex_col gap_1vw" id="text_just">
                      <p className="Raleway_45px align_self">0{index + 1}</p>
                      <div>
                        <p className="Raleway_28px  c_lightdrak text_cen ">
                          {ele.title}
                        </p>
                        <p className="Raleway_28px  c_lightdrak text_cen  ">
                          {ele.extraTitle}
                        </p>
                      </div>

                      <p className="Raleway_26px_500 opacity_8 height_12vw">
                        {ele.desc}
                      </p>
                    </div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </motion.div>
          <motion.div
            className="expert_div flex_col text_cen"
            id="our_sol"
            ref={resRef}
            initial={{ opacity: 0, y: 50 }}
            animate={resControls}
            transition={{ duration: 0.5 }}
          >
            <p className="Raleway_48px_600 c_dark" id="our_sol">
              Key Benefits of Digital Twin Technology
            </p>
            <Grid
              container
              className="home_grid_container gap_2vw"
              id="our_sol"
            >
              {results.map((ele, index) => (
                <Grid
                  key={index}
                  item
                  lg={3.5}
                  xs={12}
                  sm={12}
                  md={3.4}
                  className="home_grid_product"
                  id="border_none"
                >
                  <Paper className="home_paper_product">
                    <div className="solar_divs result_down">
                      <img
                        src={ele.imgUrl}
                        alt="ourproduct"
                        className="height_w"
                      />
                    </div>

                    <div className="expert_div result_up">
                      <div className="text_cen gap_1vw flex_col expert_div padd_1vw">
                        <div>
                          <p className="Raleway_31px_600 c_black">
                            {ele.title}
                          </p>
                          <p className="Raleway_31px_600 c_black">
                            {ele.extraT}
                          </p>
                        </div>
                        <p
                          className="Raleway_26px_500 opacity_8"
                          style={{ height: isMobileOne ? "auto" : "8vw" }}
                          id="text_just"
                        >
                          {ele.desc}
                        </p>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </motion.div>
        </motion.div>

        <motion.div
          className="expert_div flex_col text_cen"
          id="our_sol"
         
        >
          <p className="Raleway_48px_600 c_dark" id="our_sol">
            Use Cases
          </p>
          <Grid container className="home_grid_container" id="our_sol">
            {useCase.map((ele, index) => (
              <>
                <Grid
                  key={index}
                  item
                  lg={11}
                  xs={12}
                  sm={12}
                  md={11}
                  className="home_grid_product"
                  id="border_none"
                >
                  <Paper className="home_paper_product" id="margin_b">
                    <Divider
                      className="div_width"
                      style={{
                        border: "0.1px solid #FDA737", // Ensure this is correctly formatted
                      }}
                    />
                  </Paper>
                </Grid>
                
                  <Grid
                    item
                    lg={5.5}
                    xs={12}
                    sm={12}
                    md={12}
                    className="home_grid_product cursor_none"
                    id="border_none"
                  >
                    <Paper className="home_paper_product ">
                      <p className="Raleway_31px_600 c_black align_self">{ele.title}</p>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    lg={5.5}
                    xs={12}
                    sm={12}
                    md={12}
                    className="home_grid_product cursor_none"
                    id="border_none"
                  >
                    <Paper className="first-exc-paper " id="#border_none">
                      {ele.description &&
                        ele.description.map((desc, descIndex) => (
                          <div
                            className="flex-start_div  star_usecase div_width"
                            key={`desc-${descIndex}`}
                          >
                            <div className="expertr_div">
                            <img className="width_60"
                        src="https://s3.amazonaws.com/smarttrak.co/newHomePage/usecase_star.svg"
                        alt="ourproduct"
                      />
                            </div>
                            <div className="flex-start_div">
                              <p className="Raleway_26px_500 c_black" id="text_left">{desc}</p>
                            </div>
                          </div>
                        ))}
                    </Paper>
                  </Grid>
              </>
            ))}
          </Grid>
        </motion.div>

        <motion.div
          className="expert_div flex_col gap_1vw margin_bottom"
          id="our_sol"
          ref={conRef}
          initial={{ opacity: 0, y: 20 }}
          animate={conControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div flex_col gap_1vw">
            <p className="Raleway_48px_600 c_dark">Why Choose Smarttrak AI?</p>
          </div>

          <Grid container className="grid-container gap_1vw" id="our_sol">
            {WhyChoose.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.8}
                xs={12}
                sm={12}
                md={3.8}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p gap_1vw"
                  id="valData_d"
                  style={{ flex: 1 }}
                >
                  <div className="expert_div align_self_center">
                    <img
                      src={ele.keyImage}
                      alt="why choose"
                      className="width_60"
                    />
                  </div>
                  <div className="expert_div flex_col gap_1vw" id="text_just">
                    <p className="Raleway_28px  c_lightdrak text_cen ">
                      {ele.title}
                    </p>

                    <p className="Raleway_26px_500 opacity_8"
                      style={{ height: isMobileOne ? "auto" : "10vw" }}
                    >
                      {ele.description}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>

        <div className="expert_div flex_col gap_1vw margin_bottom">
          <p className="Raleway_48px_600 c_dark text_cen">
            Transform Your Biomanufacturing with Smarttrak AI
          </p>
          <p className="Raleway_31px_dark opacity_8 text_cen text_j">
            Smarttrak AI’s Digital Twin technology is the key to unlocking new
            levels of efficiency, profitability, and innovation in life
            sciences. Don’t let complex processes or inefficiencies hold your
            business back. Join the leaders in biomanufacturing who trust
            Smarttrak AI to revolutionize their operations.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DigitalTwin;
