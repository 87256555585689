import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { CosmicInverterService } from "../../../Services/CosmicInverterService";
import "./MFM.scss";
import "../../../Common/FontSizes.scss";
import SimpleBackdrop from "../../../../LifeScience/components/common/SimpleBackdrop";
import TableNoCheckBox from "../../../Common/TableNoCheckBox";
import useErrorHandler from "../../../../utils/errorHandler";
import ButtonSlider from "../../../Common/ButtonSlider";
import MFMDetails from "./MFMDetails";
const invService = new CosmicInverterService();
function MFM() {
  const [tableData, setTableData] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [mfmDataArray, setMfmDataArray] = useState([]);
  const [loadingState, setLoadingState] = useState({
    mfmList: false,
  });
  const [mfmIndex, setMfmIndex] = useState(0);
  const [activeMfm, setActiveMfm] = useState();

  const handleFetchError = useErrorHandler();
  const columns = [
    { field: "name", title: "MFM" },
    { field: "export", title: "Export Energy (kWh)" },
    { field: "import", title: "Import Energy (kWh)" },
    { field: "status", title: "Status" },
    { field: "raised_at", title: "Last Updated Timestamp" },
  ];

  useEffect(() => {
    handleFieldsList();
  }, []);

  const [id, setId] = useState(localStorage.getItem("plantId"));
  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);
  const fetchApiDuration = 2 * 60 * 1000;
  useEffect(() => {
    if (id) {
      handleMfmParameters(id);
      const intervalId = setInterval(() => {
        handleMfmParameters(id);
      }, fetchApiDuration);
      return () => clearInterval(intervalId);
    }
  }, [id]);

  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [loading, setLoading] = useState(false);


  const visibleMfms = [
    {
      device_details: { name: "MFMs Dashboard" },
    },
    ...mfmDataArray,
  ];

  const handleMfmClick = (mfmDevice, id, index) => {
    setMfmIndex(index);
    setActiveMfm(mfmDevice);
  };
  const handleFieldsList = async () => {
    try {
      setLoading(true);

      const res = await invService.getListDeviceFields("mfm");
      if (res.status === 200) {
        setDeviceFieldValue(res.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      handleFetchError(err, "Inverter field List");
    }
  };

  const handleMfmParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, mfmList: true }));
      const res = await invService.getDeviceLevelData(id, "mfm");
      if (res.status === 200) {
        setMfmDataArray(res.data);
        const tableDatas = res.data.map((item) => ({
          name: item?.device_details?.name,
          export: item?.device_data?.negative_active_energy_kWh.toFixed(2),
          import: item?.device_data?.positive_active_energy_kWh.toFixed(2),
          status: item?.device_details?.edge_status === true ? "ON" : "OFF",
          raised_at: (
            item?.device_details?.last_active.match(
              /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/
            ) || []
          )
            .slice(1)
            .join(" "),
          device_data: item?.device_data?.device_id,
          id: item?.device_details?.id,
        }));
        setTableData(tableDatas);
        setLoadingState((prevState) => ({ ...prevState, mfmList: false }));
      }
    } catch (err) {
      setLoadingState((prevState) => ({ ...prevState, mfmList: false }));
      handleFetchError(err, "mfm List");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const lastActive = mfmDataArray[0]?.device_details?.last_active;
  const formattedDate = lastActive
    ? lastActive.replace(
        /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d+(\+.*)?$/,
        (_, year, month, day, hour, minute, second) => {
          const monthNames = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];
          let hour12 = parseInt(hour, 10);
          const ampm = hour12 >= 12 ? "PM" : "AM";
          if (hour12 > 12) hour12 -= 12;
          else if (hour12 === 0) hour12 = 12;
          return `${day} ${
            monthNames[parseInt(month, 10) - 1]
          }, ${year} ${hour12.toString().padStart(2, "0")}:${minute} ${ampm}`;
        }
      )
    : "--";
  return (
    <div className="home-container padding_top_0vh">
      {loading && <SimpleBackdrop open={loading} />}
      <Grid container className="grid-container">
      <div className="sticky_class">
      <div className="btnSlides" style={{ position: "relative" }}>
       
              <ButtonSlider
                isValueData={visibleMfms}
                handleInvDeatil={handleMfmClick}
                activeIndex={mfmIndex}
                itemwidth={140}
              />
            {loadingState.mfmList && (
              <SimpleBackdrop open={loadingState.mfmList} />
            )}
          </div>
          </div>
        {mfmIndex === 0 ? (
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={11.9}
            className="center_center_column"
          >
            <div className="space-btn-center">
              <div className="flex-start-column">
                <p
                  className="Poppins_14px_rem"
                  style={{ fontWeight: "400", display: "flex", gap: "0.5rem" }}
                >
                  Last Updated on{" "}
                  <p
                    className="Poppins_14px_rem"
                    style={{
                      color: "#5E6064",
                      fontWeight: "500",
                      fontStyle: "italic",
                      textAlign: "center",
                    }}
                  >
                    (Data refreshed every 2 mins)
                  </p>
                </p>

                <p className="Poppins_18px_rem">{formattedDate}</p>
              </div>

              <div></div>
            </div>
            <TableNoCheckBox
              data={tableData}
              columns={columns}
              page={page}
              rowsPerPage={rowsPerPage}
              order={order}
              orderBy={orderBy}
              handlePageChange={handleChangePage}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}
              serchText="Search MFM"
              titleText="MFM List"
            />
          </Grid>
        ) : (
          <MFMDetails
            mfmDeviceData={activeMfm}
            firstField={deviceFieldValue[0]}
          />
        )}
      </Grid>
    </div>
  );
}

export default MFM;
