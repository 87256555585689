import React, { useState, useEffect, useRef } from "react";
import "./LandingPage.scss";
import "react-slideshow-image/dist/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Navbar from "./Navbar";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import { useSnackbar } from "notistack";
import LinearProgress from "@mui/joy/LinearProgress";
import Stack from "@mui/joy/Stack";
import { CssVarsProvider } from "@mui/joy/styles";
import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";
import "../LandingPageFont.scss";
import { defineCustomElements } from "vidstack/elements";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowRight from "../Common/ArrowRight";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
defineCustomElements();

function Home() {
  const ourExpertise = [
    {
      title: "Digital Twin",
      description:
        "Immersive technology enhances user experience and training with improved visualization for quick issue identification.",
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/expert1.png",
    },
    {
      title: "Generative AI",
      description:
        "Pioneering business innovation with Generative AI, crafting bespoke solutions for creativity and efficiency.",
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/expert2.png",
    },
    {
      title: "Custom AI Models",
      description:
        "Tailoring AI for business automation, informed decision-making, and predictive analytics, ensuring you stay ahead.",
      image: "https://s3.amazonaws.com/smarttrak.co/newHomePage/expert3.png",
    },
  ];

  const firstFolders = [
    {
      title: "Advanced Physics-Based AI Models",
      description:
        "Utilize cutting-edge, physics-informed AI models that deeply understand and accurately predict the behavior of solar power plant equipment, ensuring early detection of faults.",
    },
    {
      title: "Real-Time Data Integration and Analysis",
      description:
        "Seamlessly integrated with IoT sensors for continuous real-time data monitoring, enabling the system to quickly identify deviations and anomalies in equipment performance.",
    },
    {
      title: "Proactive Fault Prediction and Automated Response",
      description:
        "Beyond immediate fault detection, our system anticipates potential issues before they occur and automatically initiates maintenance protocols, minimizing downtime and enhancing operational efficiency.",
    },
  ];
  const secondFolders = [
    {
      title: "High-Accuracy Forecasting with Advanced Deep Learning",
      description:
        "Leverage state-of-the-art deep learning models to deliver energy generation forecasts with over 98% accuracy, ensuring highly reliable and precise predictions for solar power plant output.",
    },
    {
      title: "Customized Solar Power Analytics",
      description:
        "Tailored specifically for solar energy, our system integrates unique environmental variables and historical data, providing forecasts that are finely attuned to the specific dynamics of solar power plants.",
    },
    {
      title: "Data-Driven Operational Optimization",
      description:
        "Empower solar power plant operators with actionable insights, enabling optimized energy distribution, enhanced maintenance scheduling, and strategic operational decision-making based on comprehensive and accurate energy forecasts.",
    },
  ];
  const thirdFolders = [
    {
      title: "Bespoke Model Fine-Tuning",
      description:
        "Our Large Language Models are meticulously fine-tuned to align with your specific industry and organizational requirements, ensuring highly relevant and targeted AI-driven insights and solutions.",
    },
    {
      title: "Seamless Integration with Existing Systems",
      description:
        "Designed for easy integration, our LLMs can be seamlessly incorporated into your existing workflows and systems, enhancing data processing and decision-making without disrupting current operations.",
    },
    {
      title: "Adaptive Learning for Continuous Improvement",
      description:
        "Our models continuously learn and adapt from new data, ensuring they remain up-to-date and increasingly effective in delivering accurate and actionable insights, tailored to the evolving dynamics of your business.",
    },
  ];
  const ourProducts = [
    {
      title: "POWEROPTIX.AI",
      laptop: "https://s3.amazonaws.com/smarttrak.co/newHomePage/product1.png",
      description:
        "Unlock unparalleled efficiency with our AI-driven solution, empowering data-driven decisions.",
    },
    {
      title: "Tracker Controller",
      laptop: "https://s3.amazonaws.com/smarttrak.co/newHomePage/product3.png",
      description:
        "Boosts solar energy capture with cutting-edge precision and adaptive tracking, offering flexible control for peak performance in every scenario",
    },
    {
      title: "SmartIQ Edge",
      laptop: "https://s3.amazonaws.com/smarttrak.co/newHomePage/product2.png",
      description:
        "Elevate Your Solar Efficiency with SmartIQ Edge: The Future of Solar Energy Management",
    },
  ];
  const caseStudy = [
    {
      title: "Solar Industry",
      subTitle:
        "Solar Power Plant - Predictive Energy Generation Using Deep Learning",
      laptop:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/casestudy1.png",
      description:
        "AI enables energy forecasting by leveraging advanced data analytics, machine learning algorithms, and real-time information.",
    },
    {
      title: "Life Science",
      subTitle:
        "Rapid Genomic Sequencing Model Training Through GPU Infrastructure",
      laptop:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/casestudy2.png",
      description:
        "Transform data into strategic assets with data engineering solutions, centralized repositories, big data solutions.",
    },
    {
      title: "Manufacturing",
      subTitle:
        "Predicting Heavy Equipment Failure Using Real-Time Data And Deep Learning",
      laptop:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/casestudy3.png",
      description:
        "Deep Learning plays a pivotal role in predicting machine failure by harnessing real-time sensor data from industrial equipment.",
    },
  ];
  const slides = [
    {
      imgSrc: "https://s3.amazonaws.com/smarttrak.co/newHomePage/slideOne.png",
      title: "Enhance Productivity with Digital Twin",
      subTitle: "",
      progressText: "Digital Twin",
      description:
        "Create a virtual replica of your plant, enabling real-time monitoring and analysis, thereby boosting energy output",
    },
    {
      imgSrc: "https://s3.amazonaws.com/smarttrak.co/newHomePage/slideTwo.png",
      title: "Unlock the Power of AI-Driven Energy Forecasting",
      subTitle: "",

      progressText: "AI-Enhanced Energy Forecasting",
      description:
        "Leverages historical data, weather patterns and real-time conditions to deliver precise energy production forecasts",
    },
    {
      imgSrc:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/slideThree.png",
      title: "Prevent Downtime with Proactive Fault Prediction",
      subTitle: "",
      progressText: "Proactive Fault Prediction",
      description:
        "Predict equipment faults proactively, thereby minimizing downtime and ensuring continuous energy production",
    },
    {
      imgSrc: "https://s3.amazonaws.com/smarttrak.co/newHomePage/slideFour.png",
      title: "Optimize Asset Management with",
      subTitle: "Remaining Useful Life Predictions",
      progressText: "Asset Lifecycle Predictions",
      description:
        "Predict asset lifespans, allowing strategic maintenance and replacements to avoid disruptions",
    },
    {
      imgSrc: "https://s3.amazonaws.com/smarttrak.co/newHomePage/slideFive.png",
      title: "Explore Data in Depth with Interactive",
      subTitle: "3D Digital Twin Visualization",
      progressText: "3D Digital Twin Visualization",
      description:
        "Experience your entire plant's operations in a state-of-the-art 3D  environment for better decision-making",
    },
  ];
  const [partners, setPartners] = useState([
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/Nvidia_logo.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/google-cloud.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/aws-logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/microsoft.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/Nvidia_logo.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/google-cloud.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/aws-logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/microsoft.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/Nvidia_logo.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/google-cloud.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/aws-logo.png",
    "https://s3.amazonaws.com/smarttrak.co/landing-images/microsoft.png",
    "https://s3.amazonaws.com/smarttrak.co/newHomePage/Nvidia_logo.png",
  ]);
  const marqueeRef = useRef(null);
  const history = useHistory();
  const expertControls = useAnimation();
  const [expertRef, inViewExpert] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const solControls = useAnimation();
  const [solRef, inViewSol] = useInView({ triggerOnce: false, threshold: 0.1 });

  const soloneControls = useAnimation();
  const [soloneRef, inViewSolone] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const soltwoControls = useAnimation();
  const [soltwoRef, inViewSoltwo] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const voneControls = useAnimation();
  const [voneRef, inViewVone] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const vtwoControls = useAnimation();
  const [vtwoRef, inViewVtwo] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const proControls = useAnimation();
  const [proRef, inViewPro] = useInView({ triggerOnce: false, threshold: 0.1 });

  useEffect(() => {
    expertControls.start({
      opacity: inViewExpert ? 1 : 0,
      y: inViewExpert ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [expertControls, inViewExpert]);
  useEffect(() => {
    voneControls.start({
      opacity: inViewVone ? 1 : 0,
      y: inViewVone ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [voneControls, inViewVone]);
  useEffect(() => {
    vtwoControls.start({
      opacity: inViewVtwo ? 1 : 0,
      y: inViewVtwo ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [vtwoControls, inViewVtwo]);

  useEffect(() => {
    solControls.start({
      opacity: inViewSol ? 1 : 0,
      y: inViewSol ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [solControls, inViewSol]);
  useEffect(() => {
    soloneControls.start({
      opacity: inViewSolone ? 1 : 0,
      y: inViewSolone ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [soloneControls, inViewSolone]);
  useEffect(() => {
    soltwoControls.start({
      opacity: inViewSoltwo ? 1 : 0,
      y: inViewSoltwo ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [soltwoControls, inViewSoltwo]);
  useEffect(() => {
    proControls.start({
      opacity: inViewPro ? 1 : 0,
      y: inViewPro ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [proControls, inViewPro]);

  useEffect(() => {
    const marqueeContainer = marqueeRef.current;

    const handleAnimationEnd = () => {
      setPartners((prevPartners) => {
        const updatedPartners = [...prevPartners];
        for (let i = 0; i < updatedPartners.length; i++) {
          const partner = updatedPartners.shift();
          updatedPartners.push(partner);
        }
        return updatedPartners;
      });
    };
    marqueeContainer.addEventListener("animationiteration", handleAnimationEnd);

    return () => {
      marqueeContainer.removeEventListener(
        "animationiteration",
        handleAnimationEnd
      );
    };
  }, []);

  const [email, setEmail] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [expertSlide, setExpertSlide] = useState(0);
  const [solOne, setSolOne] = useState(0);
  const [solTwo, setSolTwo] = useState(0);
  const [solThree, setSolThree] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [caseIndex, setCaseIndex] = useState(1);
  const [progress, setProgress] = useState(Array(slides.length).fill(0));
  const [autoSlide, setAutoSlide] = useState({
    first: true,
    second: true,
    third: true,
  });
  const sliderRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        if (newProgress[currentSlide] >= 100) {
          newProgress[currentSlide] = 0;
          const nextSlide = (currentSlide + 1) % slides.length;
          setCurrentSlide(nextSlide);
          sliderRef.current.slickGoTo(nextSlide); // Change slide programmatically
        } else {
          newProgress[currentSlide] += 0.5;
        }
        return newProgress;
      });
    }, 50);

    return () => clearInterval(timer);
  }, [currentSlide]);

  const handleLeftArrowClick = () => {
    const previousSlide = (currentSlide - 1 + slides.length) % slides.length;
    setCurrentSlide(previousSlide);
    sliderRef.current.slickGoTo(previousSlide); // Navigate to the previous slide
    setProgress((prevProgress) => {
      const newProgress = [...prevProgress];
      newProgress[previousSlide] = 0; // Reset the progress for the previous slide
      newProgress[currentSlide] = 0;
      return newProgress;
    });
  };

  const handleRightArrowClick = () => {
    const nextSlide = (currentSlide + 1) % slides.length;
    setCurrentSlide(nextSlide);
    sliderRef.current.slickGoTo(nextSlide); // Navigate to the next slide
    setProgress((prevProgress) => {
      const newProgress = [...prevProgress];
      newProgress[nextSlide] = 0; // Reset the progress for the next slide
      newProgress[currentSlide] = 0;
      return newProgress;
    });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    beforeChange: (_, next) => setCurrentSlide(next),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (autoSlide.first) {
        handleSolOneRight(solOne + 1);
      }
      if (autoSlide.second) {
        handleSolTwoRight(solTwo + 1);
      }
      if (autoSlide.third) {
        handleSolThreeRight(solThree + 1);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [solOne, solTwo, solThree, autoSlide]);

  useEffect(() => {
    const interval = setInterval(() => {
      setExpertSlide((prevSlide) =>
        prevSlide >= ourExpertise.length - 1 ? 0 : prevSlide + 1
      );
    }, 7000);

    return () => clearInterval(interval);
  }, [ourExpertise.length]);

  const handleHover = (folder, isHovering) => {
    if (isHovering) {
      setAutoSlide((prev) => ({ ...prev, [folder]: false }));
    } else {
      setTimeout(() => {
        setAutoSlide((prev) => ({ ...prev, [folder]: true }));

        if (folder === "first") {
          handleSolOneRight((solOne + 1) % firstFolders.length); // Loop back to the start
        }
        if (folder === "second") {
          handleSolTwoRight((solTwo + 1) % secondFolders.length); // Loop back to the start
        }
        if (folder === "third") {
          handleSolThreeRight((solThree + 1) % thirdFolders.length); // Loop back to the start
        }
      }, 1000);
    }
  };

  const handleSolOneRight = (ind) => {
    setSolOne(ind >= firstFolders.length ? 0 : ind);
  };

  const handleSolTwoRight = (ind) => {
    setSolTwo(ind >= secondFolders.length ? 0 : ind);
  };

  const handleSolThreeRight = (ind) => {
    setSolThree(ind >= thirdFolders.length ? 0 : ind);
  };

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };
  const handleCaseMouseEnter = (index) => {
    setCaseIndex(index);
  };

  const handleCaseMouseLeave = (index) => {
    setCaseIndex(index);
  };

  const handleProductSoln = (index) => {
    if (index === 0) {
      history.push({
        pathname: `/products/poweroptix`,
        state: { index: index },
      });
    } else if (index === 1) {
      history.push({
        pathname: `/products/tracker_controller`,
        state: { index: index },
      });
    } else if (index === 2) {
      history.push({
        pathname: `/products/smartiq_edge`,
        state: { index: index },
      });
    }
  };
  const handleCaseStudy = (index) => {
    if (index === 0) {
      history.push(`/case-study/Solar-industry/solar-forecast`);
    } else if (index === 1) {
      history.push(`/case-study/life-science`);
    } else if (index === 2) {
      history.push(`/case-study/manufacturing`);
    }
  };
  const handleViewAll = () => {
    history.push("/case-studies");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = () => {
    if (!email) {
      enqueueSnackbar("Please enter your email address", {
        variant: "warning",
      });
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      enqueueSnackbar("Please enter a valid email address", {
        variant: "error",
      });
    } else {
      enqueueSnackbar("Email submitted successfully", {
        variant: "success",
      });
    }
    setEmail("");
  };

  const handleContact = () => {
    history.push("/about-us#contact-us");
  };
  const handleExpertDiv = (ind) => {
    setExpertSlide(ind);
  };

  const handleSlideArrowClick = (index) => {
    setCurrentSlide(index);
    sliderRef.current.slickGoTo(index);
  };
  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>

      <div className="slider-container">
        <Slider {...settings} ref={sliderRef}>
          {slides.map((slide, index) => (
            <div className="slide" key={index}>
              <img
                src={slide.imgSrc}
                alt={`Slide ${index + 1}`}
                className="slide_img_gradient"
              />
              <div className="overlay">
                <p className="Raleway_61px">{slide.title}</p>
                <p className="Raleway_61px">{slide.subTitle}</p>

                <p
                  style={{ padding: "1rem 0rem" }}
                  className="Raleway_31px_dark c_white"
                >
                  {slide.description}
                </p>
              </div>
            </div>
          ))}
        </Slider>
        <CssVarsProvider>
          <div className="left-arrow">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/new_landing_images/left_arrow.svg"
              className="expert_slide"
              alt="left"
              onClick={handleLeftArrowClick}
            />
          </div>
          <div className="right-arrow">
            <img
              src="https://s3.amazonaws.com/smarttrak.co/new_landing_images/right_arrow.svg"
              className="expert_slide"
              alt="right"
              onClick={handleRightArrowClick}
            />
          </div>
          <Stack
            spacing={3}
            direction="row"
            className="stack_progess"
            sx={{
              flex: 1,
              width: "55%",
              bottom: "8%",
              left: "10%",
              position: "absolute",
            }}
          >
            {progress.map((value, index) => (
              <Stack
                key={index}
                spacing={1}
                alignItems="center"
                onClick={() => handleSlideArrowClick(index)}
              >
                <div style={{ width: "100%", cursor: "pointer" }}>
                  <LinearProgress
                    sx={{
                      width: "100%",
                      color: "#0043CE",
                      backgroundColor: "#C7C7C7",
                    }}
                    determinate
                    value={index === currentSlide ? value : 0}
                  />
                </div>
                <div className="progress-linewidth">
                  <p className="Raleway_21px_600 c_white">
                    {slides[index].progressText}
                  </p>
                </div>
              </Stack>
            ))}
          </Stack>
        </CssVarsProvider>
      </div>
      <div className="connect-all" onClick={handleContact}>
        <Button
          type="submit"
          variant="contained"
          id="common-btn"
          className="connect_all connect_one"
        >
          Connect with Us
        </Button>
      </div>
      <div className="expert_div flex_col">
        <div className="video-container" id="our_sol">
          <media-player
            title="Smarttrak AI"
            src="https://s3.amazonaws.com/smarttrak.co/videos/smarttrak_ai.mp4"
            poster="https://s3.amazonaws.com/smarttrak.co/newHomePage/Thumbnail.png"
            thumbnails="https://s3.amazonaws.com/smarttrak.co/newHomePage/Thumbnail.png"
            aspect-ratio="16/9"
            crossorigin
          >
            <media-outlet>
              <media-poster alt="video not found"></media-poster>
            </media-outlet>
            <media-community-skin></media-community-skin>
          </media-player>
        </div>
      </div>
      <div className="expertise-box expert_div flex_col" ref={expertRef}>
        <motion.div
          className="our_experts"
          initial={{ opacity: 0, y: 50 }}
          animate={expertControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px">Our Expertise</p>

          <p id="expertise-description" className="Raleway_31px_dark opacity_7">
            At Smarttrak, we lead with AI, unlocking creativity and innovation
            across sectors. Empower your business with us, where AI meets
            creativity.
          </p>
        </motion.div>

        <div className="expertise_wrapper expert_div gap_3vw flex_crct">
          <div className="expert_div gap_3vw">
            {ourExpertise[expertSlide] && (
              <motion.div className="expert_div" style={{ width: "86%" }}>
                <motion.img
                  className="expertise_image"
                  src={ourExpertise[expertSlide].image}
                  alt="expertise"
                  initial={{ opacity: 0, y: 50 }}
                  animate={expertControls}
                  transition={{ duration: 0.5 }}
                  style={{ objectFit: "contain" }}
                />
              </motion.div>
            )}
          </div>
          <div className="expert_div_main gap_1vw expert_desc">
            {ourExpertise.map((ele, index) => (
              <motion.div
                className="expert_title"
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={expertControls}
                transition={{ duration: 0.5 }}
              >
                <motion.div
                  onClick={() => handleExpertDiv(index)}
                  className="active_ind"
                  style={{
                    backgroundColor: "#CAC9DB",
                  }}
                >
                  <motion.div
                    key={index}
                    initial={{ height: 0 }}
                    animate={
                      expertSlide === index ? { height: "100%" } : { height: 0 }
                    }
                    transition={{
                      duration: expertSlide === index ? 7 : 0,
                      ease: "linear",
                    }}
                    className="linear_expert"
                  />

                  <p
                    className="expert_div Raleway_21px_600"
                    style={{
                      color: expertSlide === index ? "#000" : "#473BF0",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    {index + 1}
                  </p>
                </motion.div>
                <div className="expert_content">
                  <p className="Raleway_31px_dark">{ele.title}</p>
                  <p className="Raleway_25px opacity_8">{ele.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="expertise-box expert_div flex_col gap_1vw our_solution_main "
        id="our_sol"
      >
        <div className="our_solution expert_div gap_1vw">
          <p className="Raleway_48px">Our Solutions</p>

          <p id="expertise-description" className="Raleway_31px_dark opacity_7">
            Discover the range of solutions we offer to meet your needs and
            exceed your expectations.
          </p>
        </div>

        <motion.div
          className="expert_div  gap_3vw justify_space flex_reverse"
          id="margin_tb"
          ref={voneRef}
          initial={{ opacity: 0, y: 50 }}
          animate={voneControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div width_flex_45 flex_col gap_1vw">
            <p className="Raleway_31px_600 c_black">
              Digital Twin “What-If” Scenario Analysis
            </p>
            <p className="Raleway_25px font_500 opacity_7" id="text_just">
              Experience Smarttrak AI's 3D Digital Twin platform, simulating
              effects of natural disasters on a solar farm. Utilizing Oculus VR,
              explore the impact of different wind speeds on solar panel
              integrity and experiment with "What-If" scenarios to optimize
              positioning and reduce risks, highlighting cutting-edge,
              physics-based solutions for renewable energy management.
            </p>
          </div>
          <div className="video-container_digi">
            <media-player
              title="Smarttrak AI"
              src="https://s3.us-east-1.amazonaws.com/smarttrak.co/videos/Whatf_scenario_Analysis.mp4"
              poster="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/whatIfFour.png"
              thumbnails="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/whatIfFour.png"
              aspect-ratio="16/9"
              crossorigin
            >
              <media-outlet>
                <media-poster alt="video not found"></media-poster>
              </media-outlet>
              <media-community-skin></media-community-skin>
            </media-player>
          </div>
        </motion.div>

        <motion.div
          className="expert_div gap_3vw justify_space flex_crct"
          id="margin_tb"
          ref={vtwoRef}
          initial={{ opacity: 0, y: 50 }}
          animate={vtwoControls}
          transition={{ duration: 0.5 }}
        >
          <div className="video-container_digi">
            <media-player
              title="Smarttrak AI"
              src="https://s3.us-east-1.amazonaws.com/smarttrak.co/videos/Inverter_Management.mp4"
              poster="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/whatIfTwo.png"
              thumbnails="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/whatIfTwo.png"
              aspect-ratio="16/9"
              crossorigin
            >
              <media-outlet>
                <media-poster alt="video not found"></media-poster>
              </media-outlet>
              <media-community-skin></media-community-skin>
            </media-player>
          </div>
          <div className="expert_div width_flex_45 flex_col gap_1vw">
            <p className="Raleway_31px_600 c_black">
              Digital Twin Asset Management
            </p>
            <p className="Raleway_25px font_500 opacity_7" id="text_just">
              Experience Smarttrak AI's interactive digital twin platform for
              managing renewable energy assets. The video showcases user
              interaction with a virtual panel, guided by an AI avatar, to
              isolate the asset for data logging and error analysis, enabling
              effective real-time asset management across various renewable
              energy systems.{" "}
            </p>
          </div>
        </motion.div>
        <div className="expert_div gap_1vw flex_col" id="our_sol" ref={solRef}>
          <p className="Raleway_31px_600 c_blue">
            Generative AI for Solar Power Plants
          </p>
          <div className="expert_div gap_3vw flex_crct justify_space">
            <motion.div className="sol_sec_c">
              <motion.img
                className="expertise_image"
                src="https://s3.amazonaws.com/smarttrak.co/newHomePage/sol_one.png"
                alt="expertise"
                initial={{ opacity: 0, y: 50 }}
                animate={solControls}
                transition={{ duration: 0.5 }}
                style={{ objectFit: "contain" }}
              />
            </motion.div>

            <motion.div
              className="expert_div flex_col gap_1vw"
              initial={{ opacity: 0, y: 50 }}
              animate={solControls}
              transition={{ duration: 0.5 }}
            >
              <p className="Raleway_28px text_c">
                Fault Detection & Predictive Maintenance
              </p>
              <p className="Raleway_25px font_500 opacity_7" id="text_just">
                Solar power plants play a crucial role in building a sustainable
                future. Ensuring their optimal performance is paramount. Our
                sophisticated time series AI solutions are tailored to detect
                and predict faults, ensuring your solar installations function
                efficiently and sustainably.
              </p>
              <p className="Raleway_28px">Key Features</p>
              {firstFolders[solOne] && (
                <motion.div
                  className="expert_div flex_col cursor_p"
                  key={solOne}
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -50 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  onMouseEnter={() => handleHover("first", true)}
                  onMouseLeave={() => handleHover("first", false)}
                >
                  <p className="Raleway_28px text_c">
                    {firstFolders[solOne].title}
                  </p>
                  <p
                    className="Raleway_25px font_500 opacity_7 height_6vw"
                    id="text_just"
                  >
                    {firstFolders[solOne].description}
                  </p>
                </motion.div>
              )}
              <div className="dots_container expert_div">
                {firstFolders.map((folder, index) => (
                  <span
                    key={index}
                    className={`dot ${solOne === index ? "active" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSolOneRight(index);
                    }}
                  ></span>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
        <div className="expert_div gap_1vw" ref={soloneRef}>
          <div className="expert_div gap_3vw flex_reverse justify_space">
            <motion.div
              className="expert_div flex_col gap_1vw"
              style={{ width: "95%" }}
              initial={{ opacity: 0, y: 50 }}
              animate={soloneControls}
              transition={{ duration: 0.5 }}
            >
              <p className="Raleway_28px">Energy Generation Forecasting</p>
              <p className="Raleway_25px font_500 opacity_7" id="text_just">
                Harness the sun's potential to its fullest. Our forecasting
                models provide accurate predictions on energy generation,
                helping you plan and optimize your power output.
              </p>
              <p className="Raleway_28px">Key Features</p>
              {secondFolders[solTwo] && (
                <motion.div
                  className="expert_div flex_col text_cen cursor_p"
                  key={solTwo}
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 50 }}
                  transition={{ duration: 0.6, ease: "easeInOut" }}
                  onMouseEnter={() => handleHover("second", true)}
                  onMouseLeave={() => handleHover("second", false)}
                >
                  <p className="Raleway_28px text_c">
                    {secondFolders[solTwo].title}
                  </p>
                  <p
                    className="Raleway_25px font_500 opacity_7 height_6vw"
                    id="text_just"
                  >
                    {secondFolders[solTwo].description}
                  </p>
                </motion.div>
              )}
              <div className="dots_container expert_div">
                {secondFolders.map((folder, index) => (
                  <span
                    key={index}
                    className={`dot ${solTwo === index ? "active" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSolTwoRight(index);
                    }}
                  ></span>
                ))}
              </div>
            </motion.div>
            <motion.div className="sol_sec">
              <motion.img
                className="expertise_image"
                src="https://s3.amazonaws.com/smarttrak.co/newHomePage/sol_twoo.png"
                alt="expertise"
                initial={{ opacity: 0, y: 50 }}
                animate={soloneControls}
                transition={{ duration: 0.5 }}
                style={{ objectFit: "contain" }}
              />
            </motion.div>
          </div>
        </div>
        <div className="flex_col expert_div gap_1vw margin_1rem">
          <div className="our_solution expert_div gap_1vw">
            <p className="Raleway_31px_600 c_blue">
              Generative AI for Corporations & Businesses
            </p>
          </div>
          <div className="expert_div" ref={soltwoRef}>
            <div className="expert_div gap_3vw flex_crct justify_space">
              <motion.div className="sol_sec_c ">
                <motion.img
                  className="expertise_image"
                  src="https://s3.amazonaws.com/smarttrak.co/newHomePage/sol_three.png"
                  alt="expertise"
                  initial={{ opacity: 0, y: 0 }}
                  animate={soltwoControls}
                  transition={{ duration: 0.5 }}
                  style={{ objectFit: "contain" }}
                />
              </motion.div>

              <motion.div
                className="expert_div flex_col gap_1vw"
                initial={{ opacity: 0, y: 0 }}
                animate={soltwoControls}
                transition={{ duration: 0.5 }}
              >
                <p className="Raleway_28px text_c">
                  Tailored Large Language Models (LLMs) for specific needs
                </p>
                <p className="Raleway_25px font_500 opacity_7" id="text_just">
                  By customizing these advanced models, we provide precise,
                  context-aware insights, streamline processes, and enable
                  intelligent automation. Whether it's improving operational
                  efficiency, enhancing decision-making, or delivering
                  industry-specific knowledge, our solution adapts to your
                  unique requirements, driving innovation and delivering
                  real-world impact.
                </p>
                <p className="Raleway_28px">Key Features</p>
                {thirdFolders[solThree] && (
                  <div
                    className="expert_div flex_col text_cen cursor_p"
                    key={solThree}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ duration: 0.6, ease: "easeInOut" }}
                    onMouseEnter={() => handleHover("third", true)}
                    onMouseLeave={() => handleHover("third", false)}
                  >
                    <p className="Raleway_28px text_c">
                      {thirdFolders[solThree].title}
                    </p>
                    <p
                      className="Raleway_25px font_500 opacity_7"
                      id="text_just"
                    >
                      {thirdFolders[solThree].description}
                    </p>
                  </div>
                )}
                <div className="dots_container expert_div">
                  {thirdFolders.map((folder, index) => (
                    <span
                      key={index}
                      className={`dot ${solThree === index ? "active" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSolThreeRight(index);
                      }}
                    ></span>
                  ))}
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="expert_div flex_col margin_1rem margin_side expertise-box"
        ref={proRef}
      >
        <motion.div
          className="our_experts"
          initial={{ opacity: 0, y: 20 }}
          animate={proControls}
          transition={{ duration: 0.5 }}
        >
          <p className="Raleway_48px">Our Products</p>

          <p id="expertise-description" className="Raleway_31px_dark opacity_8">
            Explore our comprehensive range of products and solutions designed
            to address your specific needs and challenges.
          </p>
        </motion.div>
        <Grid container className="home_grid_container" id="our_sol">
          {ourProducts.map((ele, index) => (
            <Grid
              key={index}
              item
              lg={3.4}
              xs={12}
              sm={12}
              md={3.4}
              className="home_grid_product" id="pro_hover"
              onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
            >
              <Paper className="home_paper_product">
                <div className="div_product expert_div">
                  <motion.div className="div_img_height">
                    <motion.img
                      initial={{ opacity: 0, y: 50 }}
                      animate={proControls}
                      transition={{ duration: 0.5 }}
                      src={ele.laptop}
                      alt="ourproduct"
                      className="div_width"
                      style={{ height: "100%", objectFit: "contain" }}
                    />
                  </motion.div>
                </div>

                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={proControls}
                  transition={{ duration: 0.5 }}
                  className="gap_1vw flex_col expert_div product_content"
                  
                >
                  <div className="text_cen gap_1vw flex_col expert_div">
                    <p className="Raleway_26px">{ele.title}</p>
                    <p
                      className="Raleway_21px_500"
                      style={{
                        color: hoveredIndex === index ? "#000" : "#5F6368",
                      }}
                    >
                      {ele.description}
                    </p>
                  </div>

                  <div
                    onClick={() => handleProductSoln(index)}
                    className="product_arrow expert_div"
                    id="secdot"
                  >
                    <ArrowRight
                      className="expert_slide"
                      fill={hoveredIndex === index ? "#FDA737" : "black"}
                    />
                  </div>
                </motion.div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="expertise-box expert_div flex_col margin_side">
        <div className="our_experts">
          <p className="Raleway_48px">Case Studies</p>

          <p id="expertise-description" className="Raleway_31px_dark opacity_8">
            Explore our case studies to see real-world examples of how our
            solutions drive success.
          </p>
        </div>
        <Grid container className="home_grid_container gap_2vw" id="our_sol">
          {caseStudy.map((ele, index) => (
            <Grid
              key={index}
              item
              lg={3.4}
              xs={12}
              sm={12}
              md={3.4}
              onMouseEnter={() => handleCaseMouseEnter(index)}
              onMouseLeave={() => handleCaseMouseLeave(index)}
              className={`home_grid_product ${
                caseIndex === index ? "active" : "inactive"
              }`}
            >
              <Paper className="home_paper_casestudy ">
                <div className="div_casestudy expert_div" id="case_imges">
                  <img
                    src={ele.laptop}
                    alt="ourcasestudy"
                    style={{ width: "100%" }}
                  />
                </div>

                <div className="expert_div_main">
                  <div className="expert_div_main">
                    <p className="Raleway_25px_600">{ele.title}</p>
                    <p className="Raleway_21px_600">{ele.subTitle}</p>

                    <p className="Raleway_15px c_lightgray">
                      {ele.description}
                    </p>
                  </div>
                  {caseIndex === index && (
                    <div
                      className="arrow_case expert_div"
                      id="secdot"
                      onClick={() => handleCaseStudy(index)}
                    >
                      <ArrowForwardIcon sx={{ marginRight: "1vw" }} />
                    </div>
                  )}
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <div className="connect-all" onClick={handleViewAll}>
          <Button
            type="submit"
            variant="contained"
            id="common-btn"
            className="connect_all connect_two"
          >
            View All
          </Button>
        </div>
      </div>
      <div className="expert_div flex_col">
        <div className="our_experts">
          <p className="Raleway_48px">Our Partners</p>
        </div>
        <div className="marque-main">
          <div className="marquee-container" ref={marqueeRef}>
            {partners.map((item, index) => {
              return (
                <div className="marquee-item" key={index}>
                  <img
                    className="partners-img"
                    src={item}
                    alt="partner"
                    style={{ width: "5.2vw" }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
