import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import "./BarPlotForIOT.scss";
import "./graphCSS.css";
import { Tooltip } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Divider from "@mui/material/Divider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./../Graphs/PlantDetails.scss";
import ModalCalender from "../Common/ModalCalender";

const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};
const CustomizedXaxis = (props) => {
  const {
    selectedDateRangeProp,
    isValueData,
    isMobileAndTab,
    isFullScreen,
    toggleFullScreen,
    isDesktopView,
    isOnlyMobile,
  } = props;
  const chartRef = useRef(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dayCount, setDayCount] = useState(6);
  const formatToDayMonthRef = useRef(() => (value) => value);
  useEffect(() => {
    if (!isEmptyObject(isValueData)) {

      const formatToDayMonth = (label) => {
        
        const labelString = String(label);
         
        if (dayCount === 0) {
          const [, timePart] = labelString.split(" ");
          if (!timePart) return "";
          const [hour, minute] = timePart.split(":");
          if (!minute) return "";
          const [time] = minute.split(" ");
          return `${hour}:${time}`;
        } else {
          if (labelString.includes("13:00")) {
            const [datePart] = labelString.split(" ");
            const date = new Date(datePart);
            const day = date.getDate();
            const month = date.toLocaleString("default", { month: "short" });
            return `${day} ${month}`;
          }
        }
        return "";
      };
  
      formatToDayMonthRef.current = formatToDayMonth;

      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          categories: isValueData?.xAxis?.categories,
          labels: {
            formatter: function (value) {
              return formatToDayMonthRef.current(value); 
            },
          },
        },
        chart: {
          type: isValueData?.chart?.type,
          toolbar: {
            autoSelected: "pan",
            show: true,
          },
          zoom: {
            enabled: true,
          },
          // events: {
          //   zoomed: function (chartContext, { xaxis }) {
          //     console.log("Zoom Event:", xaxis);
          
          //     // Retrieve the categories from the chart options
          //     const categories = isValueData?.xAxis?.categories || [];
          
          //     // Convert xaxis.min and xaxis.max to indices
          //     const startIndex = Math.max(0, Math.floor(xaxis.min));
          //     const endIndex = Math.min(categories.length - 1, Math.ceil(xaxis.max));
          
          //     // Retrieve the corresponding category values
          //     const startTime = categories[startIndex];
          //     const endTime = categories[endIndex];
          
          //     console.log("Start Time:", startTime);
          //     console.log("End Time:", endTime);
          
          //     // Check if the zoom range is within a single day
          //     const startDay = startTime?.split(" ")[0]; // Extract the date part
          //     const endDay = endTime?.split(" ")[0]; // Extract the date part
          //     const startZoomDate = new Date(startDay);
          //     const endZoomDate = new Date(endDay);
              
          //     // Calculate the difference in time (milliseconds)
          //     const timeDifference = endZoomDate - startZoomDate;
              
          //     // Convert the time difference to days
          //     const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
          //     setCount(dayDifference);
          //     setDayCount(dayDifference); 
          //     // Update the x-axis labels dynamically based on the zoom range
          //     // setOptions((prevOptions) => ({
          //     //   ...prevOptions,
          //     //   xaxis: {
          //     //     ...prevOptions.xaxis,
          //     //     labels: {
          //     //       formatter: function (value) {
          //     //         return formatToDayMonthRef.current(value); 
          //     //       },
          //     //     },
          //     //   },
          //     // }));
          //   },
          // },
        },
        tooltip: {
          shared: true,
          intersect: false,
          x: {
            formatter: function (value) {
              const dateString = isValueData?.xAxis?.categories[value-1] || '';
          
          if (dateString) {
            const [datePart, timePart] = dateString.split(' '); 
            const [month, day, year] = datePart.split('/'); 
            const monthName = new Date(`${month}/01/2000`).toLocaleString('default', { month: 'short' });
            return `${day} ${monthName} ${year} ${timePart}`;
          }
          return '';
        
            },
          },
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              const forecasted = w.config.series[0]?.data[dataPointIndex] || 0; 
              const actual = w.config.series[1]?.data[dataPointIndex] || 0; 
              if (seriesIndex === 0) {
                if(value !== null && value !== undefined){
                return `${value.toFixed(2)}`;}
              } else if (seriesIndex === 1) {
                if(value !== null && value !== undefined){
                  return `${value.toFixed(2)}`;}
              } else {
                if(value === 0){
                  return "No Performance"
                } else{
                  if(value !== null && value !== undefined){
                return `${actual < forecasted ? '-' : ''}${Math.abs(value).toFixed(2)}`;
                  }
              }
              }
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
        yaxis:
          isValueData?.yAxis?.map((axis) => ({
            ...axis,
            labels: {
              formatter: (value) => value.toFixed(2),
            },
          })) || [],
      }));
    }
  }, [isValueData]);
 
  const [options, setOptions] = useState({
    chart: {
      type: isValueData?.chart?.type,
      height: 350,
    },
    xaxis: {
      categories: isValueData?.xAxis?.categories,
      labels: {
        formatter: function (value) {
          return formatToDayMonthRef.current(value); // Show only the date for 12:00 PM
        },
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    yaxis: {
      title: {
        text: isValueData?.yaxis?.title.text,
      },
      labels: {
        formatter: function (value) {
          return value.toFixed(2);
        },
      },
      tickAmount: 5,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
      },
    },
    grid: {
      show: false,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: function (value) {
          const dateString = isValueData?.xAxis?.categories[value-1] || '';
      
      if (dateString) {
        const [datePart, timePart] = dateString.split(' '); // Split into date and time
        const [month, day, year] = datePart.split('/'); // Split date into day, month, and year
        
        // Convert month to short name (e.g., 01 => Jan)
        const monthName = new Date(`${month}/01/2000`).toLocaleString('default', { month: 'short' });
        
        // Return formatted date in the desired format
        return `${day} ${monthName} ${year} ${timePart}`;
      }
      return '';
    
        },
      },
      y: {
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          const forecasted = w.config.series[0]?.data[dataPointIndex] || 0; 
          const actual = w.config.series[1]?.data[dataPointIndex] || 0; 
          if (seriesIndex === 0) {
            if(value !== null && value !== undefined){
            return `${value.toFixed(2)}`;}
          } else if (seriesIndex === 1) {
            if(value !== null && value !== undefined){
              return `${value.toFixed(2)}`;}
          } else {
            if(value === 0){
              return "No Performance"
            } else{
              if(value !== null && value !== undefined){
            return `${actual < forecasted ? '-' : ''}${Math.abs(value).toFixed(2)}`;
              }
          }
          }
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
  });

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen(); // Exit full-screen when user exits
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);

  const handleDayCountChange = (count) => {
    setDayCount(count);
  };

  const attachListener = () => {
    const container = chartContainerRef.current;
    if (container) {
      container.addEventListener("click", handleClick);
    }
  };

  const detachListener = () => {
    const container = chartContainerRef.current;
    if (container) {
      container.removeEventListener("click", handleClick);
    }
  };

  const handleClick = (event) => {
    if (event.target.classList.contains("apexcharts-reset-icon")) {
      setDayCount(6);
      handleStartDateTimeChange(null);
      handleStopDateTimeChange(null);
    }
  };

  const chartContainerRef = useRef(null);
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;

    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);

    const img = new Image();
    img.src = "data:image/svg+xml," + encodeURIComponent(xml);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };
  const handleTimings = () => {
    setOpenDrawer(true);
  };
  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
    props.openDrawerValue(openDrawer);
  };

  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
    props.openDrawerValue(openDrawer);
  };
 
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
      ref={(el) => {
        chartContainerRef.current = el;
        if (el) attachListener();
        else detachListener();
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p className="Poppins_18px_rem" style={{ color: "#212121" }}>
            {isValueData?.title?.text !== undefined
              ? isValueData.title.text
              : ""}
          </p>{" "}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",

            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",

              alignItems: "baseline",
              justifyContent: "center",
            }}
          >
            <Tooltip title="Download" arrow>
              <DownloadIcon className="icon-css" onClick={downloadChart} />
            </Tooltip>

            <Tooltip title="Calendar" arrow>
              <CalendarMonthRoundedIcon
                className="icon-css"
                onClick={handleTimings}
              />
            </Tooltip>
            <Tooltip title="FullScreen" arrow>
              <button
                onClick={toggleFullScreen}
                style={{
                  width: "1.6vw !important",
                  height: "fit-content !important",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                {isFullScreen ? (
                  <FullscreenExitIcon className="icon-css" />
                ) : (
                  <FullscreenIcon className="icon-css" />
                )}
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
          onDayCountChange={handleDayCountChange}
        />
      )}
      <div
        style={{
          height:
            isOnlyMobile && isFullScreen
              ? "100vw"
              : isEmptyObject(isValueData)
              ? "350px"
              : "90%",
          width: isOnlyMobile && isFullScreen ? "84vh" : "100%",
          padding: "1vw",
          transform:
            isOnlyMobile && isFullScreen ? "rotate(90deg)" : "rotate(0deg)",
          marginTop: isOnlyMobile && isFullScreen ? "6rem" : "0rem",
          marginLeft: isOnlyMobile && isFullScreen ? "-7rem" : "0rem",
        }}
        ref={chartContainerRef}
        id="prediction-chart"
      >
        {isEmptyObject(isValueData) ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "100%",
              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <Chart
            id="unixChart"
            options={options}
            series={isValueData?.series}
            type="area"
            width={"100%"}
            ref={chartRef}
            height={
              isFullScreen && isMobileAndTab
                ? "300"
                : isFullScreen && isDesktopView
                ? "500"
                : "320"
            }
          />
        )}
      </div>
    </div>
  );
};

export default CustomizedXaxis;
