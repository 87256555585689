
import { enqueueSnackbar } from 'notistack';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useErrorHandler = () => {
  const history = useHistory();
  const [id, setId] = useState(localStorage.getItem("plantId"));
  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);

  const handleFetchError = (error, dataType) => {
    if (id === "2" ||id === "7") {
      return;
    }
    if (error.response) {
      switch (error.response.status) {
        case 400:
          enqueueSnackbar(error.response?.data?.detail || "An error occurred", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
        case 404:
          enqueueSnackbar(error.response?.data?.detail || "An error occurred", {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
        case 403:
          history.push("/signin");
          break;
        case 500:
          enqueueSnackbar(`Internal Server Error: ${dataType}`, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
        default:
          console.log(`Error while fetching the ${dataType}`, error);
          enqueueSnackbar(`Error while fetching the ${dataType}`, {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
          });
          break;
      }
    } else {
      console.log(`Error while fetching the ${dataType}`, error);
      enqueueSnackbar(`Error while fetching the ${dataType}`, {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "left" },
      });
    }
  };

  return handleFetchError;
};

export default useErrorHandler;
