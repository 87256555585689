import React, { useEffect } from "react";
import { Backdrop, Modal, Fade } from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";
import Button from "@mui/material/Button";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./../Graphs/PlantDetails.scss";
import Dropdown from "react-dropdown";
import CloseIcon from '@mui/icons-material/Close';

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  padding: "8vh 30px 20px 30px",
  backgroundColor: "white",
  color: "white",
};

function ModalCalender(props) {
  const {
    openDrawer,
    setOpenDrawer,
    startDateTime,
    singledaySelect,
    selectedDateRangeVal,
    isDurationType, durationType, isFilter, onDayCountChange,
  } = props;
  const [tempStartDateTime, setTempStartDateTime] = useState("");
  const [tempStopDateTime, setTempStopDateTime] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleStopDateTimeChange = (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    if (selectedDate > currentDate) {
      date = currentDate.toISOString();
    }

    const dateObject = new Date(date);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    let hours, minutes;

    if (selectedDate.toDateString() === currentDate.toDateString()) {
      hours = currentDate.getHours().toString().padStart(2, "0");
      minutes = currentDate.getMinutes().toString().padStart(2, "0");
    } else {
      hours = "23";
      minutes = "59";
    }

    const formattedTimestampStop = `${year}-${month}-${day}T${hours}:${minutes}:00%2B05:30`;
    setTempStopDateTime(formattedTimestampStop);
  };

  const handleStartDateTimeChange = (date) => {
    const dateObject = new Date(date);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    const formattedTimestampStart = `${year}-${month}-${day}T00:00:00%2B05:30`;

    setTempStartDateTime(formattedTimestampStart);
  };
  const handleChangeDeviceDuration = (device) => {
    setSelectedFilter(device.value)
  }
  const handleClear = (event) => {
    event.preventDefault();
    setSelectedDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  const calculateDateRangeLength = (startDate, endDate) => {
    const timeDifference = endDate.getTime() - startDate.getTime();
    const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    return dayDifference;
  };
  const funDate = (date) => {
    if (date instanceof Date) {
      date.setHours(0, 0, 0, 0);
      return date;
    }

    const parts = date.split(/[T:+-]/);
    const date1 = new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0);
    return date1;
  };

  const initialDateRange = selectedDateRangeVal.map((range) => {
    return {
      ...range,
      startDate: funDate(range.startDate),
      endDate: funDate(range.endDate),
    };
  });

  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange);
  const handleApply = () => {
    const startDate = new Date(tempStartDateTime.split("T")[0]);
    const endDate = new Date(tempStopDateTime.split("T")[0]);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    isFilter && props.handleChangeDuration(diffDays === 0 ? "Hourly" : (diffDays > 0 && diffDays <= 5) ? "Daily" : selectedFilter)
    props.handleStartDateChange(tempStartDateTime);
    props.handleStopDateChange(tempStopDateTime);
    setSelectedDateRange((prevSelectedDateRange) => [
      {
        ...prevSelectedDateRange[0],
        startDate: funDate(tempStartDateTime),
        endDate: funDate(tempStopDateTime),
      },
      ...prevSelectedDateRange.slice(1),
    ]);
    setOpenDrawer(false);
  };
  const disableFutureDates = (date) => {
    const today = new Date();
    return date > today;
  };
  const handleClose = () => {
    setOpenDrawer(false)
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDrawer}>
          <Box sx={boxStyle}>
            <div onClick={handleClose} className="handleclose-subdiv" style={{ position: "absolute" }}>
              <CloseIcon sx={{color:"#2F67F8"}} />
            </div>

            <div className="calender-container">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="range_title"
                  style={{
                    width: "fit-content",
                    position: "relative",
                    top: "2rem",
                  }}
                >
                  <p
                    className="raleway_20px_rem"
                    style={{ color: "black", fontWeight: "700" }}
                  >
                    Range Filters
                  </p>
                </div>
                {singledaySelect ? (
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: selectedDateRange[0].startDate,
                        endDate: selectedDateRange[0].startDate,
                        key: "selection",
                      },
                    ]}
                    editableDateInputs={false}
                    // maxDate={new Date()}
                    disabledDay={disableFutureDates}
                    onChange={(ranges) => {
                      const startDate = ranges.selection.startDate;
                      setSelectedDateRange([
                        { startDate, endDate: startDate, key: "selection" },
                      ]);
                      handleStartDateTimeChange(startDate);
                      handleStopDateTimeChange(startDate);
                    }}
                  />
                ) : (
                  <DateRangePicker
                    months={isMobile ? 1 : 2}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    direction="horizontal"
                    ranges={selectedDateRange}
                    // maxDate={new Date()}
                    disabledDay={disableFutureDates}
                    onChange={(ranges) => {
                      const startDate = ranges.selection.startDate;
                      const endDate = ranges.selection.endDate;
                      const lengthOfRange = calculateDateRangeLength(startDate, endDate); 
                      onDayCountChange && onDayCountChange(lengthOfRange);
                      if (startDate === endDate) {
                        handleStartDateTimeChange(startDate);
                        handleStopDateTimeChange(endDate);
                      } else {
                        handleStartDateTimeChange(startDate);
                        handleStopDateTimeChange(endDate);
                      }

                      setSelectedDateRange([ranges.selection]);
                    }}
                  />
                )}
                {!isFilter && <div
                  style={{
                    display: "flex",
                    alignSelf: "end",
                    gap: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    className="btn_style"
                    style={{
                      backgroundColor: "#2F67F8",
                    }}
                    onClick={handleApply}
                  >
                    {" "}
                    <p className="raleway_20px_rem">Apply</p>
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    className="btn_style"
                    style={{
                      backgroundColor: "#FF472E",
                    }}
                    onClick={handleClear}
                  >
                    <p className="raleway_20px_rem">Clear</p>
                  </Button>
                </div>}

              </div>
              {isFilter && <div className="intervals_container">
                <p className="raleway_20px_rem" style={{ color: "black", fontWeight: "700" }}>Interval Filters</p>
                <div style={{ width: "auto", minWidth: "1.6vw", cursor: "pointer",height:"100%" }} id="exc-linegraph">
                  <Dropdown
                    style={{ border: "1px solid #00529A !important" }}
                    className="recommendations-text"
                    options={durationType.map((field) =>
                      field
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                    )}
                    onChange={handleChangeDeviceDuration}
                    value={isDurationType}
                  />
                </div>
                {/* <div className="filter_container">
                {durationType.map((field, index)=>{
                 return (<div key={index} className="filter_button" style={{
                  backgroundColor: isDurationType === field ? 'red' : 'transparent',
                }} onClick={() => handleChangeDeviceDuration(field)}>
                  <p className="rdrStaticRangeLabel">{field
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase())}</p>
                </div> )})}
                </div> */}
                <div
                  style={{
                    display: "flex",
                    alignSelf: "end",
                    gap: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    className="btn_style"
                    style={{
                      backgroundColor: "#2F67F8",
                    }}
                    onClick={handleApply}
                  >
                    {" "}
                    <p className="raleway_20px_rem">Apply</p>
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    className="btn_style"
                    style={{
                      backgroundColor: "#FF472E",
                    }}
                    onClick={handleClear}
                  >
                    <p className="raleway_20px_rem">Clear</p>
                  </Button>
                </div>
              </div>}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ModalCalender;