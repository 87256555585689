import React, { useState, useEffect, useCallback } from 'react';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useLocation } from "react-router-dom";
import SingleLineTempGraph from '../../Graphs/SingleLineTempGraph';
import { CosmicInverterService } from '../../Services/CosmicInverterService';
import useErrorHandler from '../../../utils/errorHandler';
import { useMobile } from '../../Context/MobileContext/MobileContext';
import SimpleBackdrop from '../../../LifeScience/components/common/SimpleBackdrop';
import InvBarPlot from '../../Graphs/InvBarPlot';
import Revenue from '../../Common/Revenue';
import { useHistory } from "react-router-dom";
import AnnotationChart from '../../Common/AnnotationChart';
import CustomCircularBar from '../../Common/CustomCircularBar';
import BarPlotForIOT from '../../Graphs/BarPlotForIOT';
import OperationalEfficiency from '../../Common/OperationalEfficiency';
import StatusGrid from '../../Common/StatusGrid';

const invService = new CosmicInverterService();
function DetailedDiagnostic() {
  const handleFetchError = useErrorHandler();
  const history = useHistory();
  const causes = {
    electrical_overload: [
      {
        title: "Grid Instability",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g1.svg",
        description: "Sudden surges or frequency deviations from the grid can feedback into the inverter, causing overloads."
      },
      {
        title: "MPPT Firmware Issues",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g2.svg",
        description: "Malfunctioning firmware may cause incorrect power point tracking, leading to inefficiencies or overloads."
      },
      {
        title: "External Wiring Damage",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g3.svg",
        description: "Damaged wiring can result in inconsistent voltage, contributing to inverter overload."
      },
    ],
    temp_sensor_failure: [
      {
        title: "Sensor Calibration Drift",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g1.svg",
        description: "Sensor losing accuracy over time due to misalignment or lack of recalibration."
      },
      {
        title: "Wiring Disruptions",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g2.svg",
        description: "Signal interruptions from damaged or improperly installed wiring."
      },
      {
        title: "Environmental Interference",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g3.svg",
        description: "External factors such as dirt, debris, or extreme weather conditions impacting sensor functionality."
      },
    ],
    firmware: [
      {
        title: "Configuration File Compatibility Issues",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g1.svg",
        description: "Older firmware versions may not align with current system requirements."
      },
      {
        title: "Environmental Stress",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g2.svg",
        description: "External conditions like high temperature or humidity stressing outdated firmware functionality."
      },
      {
        title: "Hardware Aging",
        icon_url: "https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/g3.svg",
        description: "Decline in performance due to wear and tear, exacerbated by inefficiencies in older firmware."
      },
    ]
  }

  const sensorStatus = Array.from({ length: 32 }, (_, index) => ({
    name: `Sensor ${index + 1}`,
    status: index === 4 || index===10 ? "Action Needed" : "Operational", 
    icon: "SensorIcon"
  }));
  const invStatus = Array.from({ length: 20 }, (_, index) => ({
    name: `Inverter ${index + 1}`,
    status: index === 4 || index===10 ? "Outdated" : index === 8 || index === 15 ? "Unsupported" : "Up-to-Date" , 
    icon:"InverterIcon"
  }));
const legendName = [
  {
    name:"Up-to-Date",
    fillColor:"#2FBE66",
    icon:"InverterIcon"
  },
  {
    name:"Unsupported",
     fillColor:"#FF4D50",
     icon:"InverterIcon"
  },
  {
    name:"Outdated",
    fillColor:"orange",
    icon:"InverterIcon"
  },
]
const sensorLegendName = [
  {
    name:"Operational",
    fillColor:"#2FBE66",
    icon:"SensorIcon"
  },
  {
    name:"Action Needed",
    fillColor:"#FF4D50",
    icon:"SensorIcon"
  }
]
  const plantEnergy = {
    energy_comparison: [{
      "AC Energy": {
        energy: { value: 10440, unit: 'kWh' },
        energy_diff: { value: 24.34, unit: '%' },
        description: "Vs forecasted values as of last updated timestamp"
      },
      "GHI": {
        ghi: { value: 12.205549999999999, unit: 'kWh/m²' },
        ghi_diff: { value: -59.34293188485291, unit: '%' },
        description: "Vs. metrics for the same period up to today for last month"
      }
    },
    {
      "AC Energy": {
        energy: { value: 10440, unit: 'kWh' },
        energy_diff: { value: 24.34, unit: '%' },
        description: "Vs forecasted values as of last updated timestamp"
      },
      "GHI": {
        ghi: { value: 12.205549999999999, unit: 'kWh/m²' },
        ghi_diff: { value: -59.34293188485291, unit: '%' },
        description: "Vs. metrics for the same period up to today for last month"
      }
    }
    ]
  }

  const [outputCurrent, setOutputCurrent] = useState([]);
  const [outputPower, setOutputPower] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [lastActiveData, setLastActiveData] = useState([]);
  const [lastActive, setLastActive] = useState([]);
  const [dailyGeneration, setDailyGeneration] = useState([]);
  const [timeRunToday, setTimeRunToday] = useState([]);
  const [averageCosphi, setAverageCosphi] = useState([]);
  const [netFrequency, setNetFrequency] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isStartTimeTemp, setIsStartTimeTemp] = useState("");
  const [isStopTimeTemp, setIsStopTimeTemp] = useState("");
  const [isStartTimeLastActive, setIsStartTimeLastActive] = useState("");
  const [isStopTimeLastActive, setIsStopTimeLastActive] = useState("");
  const [isStartTimeRun, setIsStartTimeRun] = useState("");
  const [isStopTimeRun, setIsStopTimeRun] = useState("");
  const [isStartTimeAvgCosphi, setIsStartTimeAvgCosphi] = useState("");
  const [isStopTimeAvgCosphi, setIsStopTimeAvgCosphi] = useState("");
  const [isStartTimeNetFreq, setIsStartTimeNetFreq] = useState("");
  const [isStopTimeNetFreq, setIsStopTimeNetFreq] = useState("");
  const [isStartTimeOutPower, setIsStartTimeOutPower] = useState("");
  const [isStopTimeOutPower, setIsStopTimeOutPower] = useState("");
  const [isStartTimeDailyGen, setIsStartTimeDailyGen] = useState("");
  const [isStopTimeDailyGen, setIsStopTimeDailyGen] = useState("");
  const [generationstringDuration, setGenerationStringDuration] = useState("D");
  const [moduleTemperature, setModuleTemperature] = useState([]);
  const [panelDeviceId, setPanelDeviceId] = useState(null);
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [totalPowerGen, setTotalPowerGen] = useState(0);
  const [newInvArray, setNewInvArray] = useState([]);
  const [performanceValue, setPerformanceValue] = useState({});
  const [fullScreenStates, setFullScreenStates] = useState({
    graph1: false,
    graph2: false,
    graph3: false,
    graph4: false,
    graph5: false,
    graph6: false,
    graph7: false,
    graph8: false,
    graph9: false,
    graph10: false,
    graph11: false,
  });
  const [loadingState, setLoadingState] = useState({
    outputCurrent: false,
    outputPower: false,
    temp: false,
    timeRun: false,
    dailyGen: false,
    lastActive: false,
    avgCosphi: false,
    freq: false,
    weatherData: false,
    moduleTemp: false,
    ambient: false
  });
  const [perfCompareVal, setPerfCompareVal] = useState([]);
  const [isStartTimeAmbient, setIsStartTimeAmbient] = useState("");
  const [isStopTimeAmbient, setIsStopTimeAmbient] = useState("");
  const [isStartTimeModule, setIsStartTimeModule] = useState("");
  const [isStopTimeModule, setIsStopTimeModule] = useState("");
  const [isStartTimeOutCurrent, setIsStartTimeOutCurrent] = useState("");
  const [isStopTimeOutCurrent, setIsStopTimeOutCurrent] = useState("");
  const [perfRatioStartTime, setPerfRatioStartTime] = useState("");
  const [perfRatioStopTime, setPerfRatioStopTime] = useState("");
  const { isOnlyMobile, isMobileAndTab, isDesktopView } = useMobile();
  const [id, setId] = useState(Number(localStorage.getItem("plantId")));
  const [averagePerformaceRatio, setAveragePerformaceRatio] = useState("");

  const jsonData = {
    title: "Operational Efficiency Metrics",
    energy: "25999",
    subtitle1: "CUF-AC Capacity",
    value1: "18.28 %",
    subtitle2: "CUF-DC Capacity",
    value2: "16.61 %",
    subtitle3: "Performance Ratio",
    value3: "65.15 %",
    PlantIcon:
      "https://s3.amazonaws.com/smarttrak.co/v2Images/plant_other_icon.svg",
    colorCode: "#04724D",
  };

  useEffect(() => {
    if (id) {
      const callApis = () => {
        handleWeatherParameters(id)
        handleInvertersList(id)
        handlePerfRatio(id)
      };
      callApis();
      const intervalId = setInterval(() => {
        callApis();
      }, fetchApiDuration);

      return () => clearInterval(intervalId);
    }
  }, [id]);
  useEffect(() => {
    const handlePlantIdChange = (event) => {
      const updatedPlantId = event.detail.plantId;
      setId(updatedPlantId);
    };
    window.addEventListener("plantIdChange", handlePlantIdChange);
    return () => {
      window.removeEventListener("plantIdChange", handlePlantIdChange);
    };
  }, []);
  const location = useLocation()
  const deviceId = location?.state?.deviceId
  const wmsDeviceId = 51

  const faultType = location?.state?.fault_type
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeTemp && isStopTimeTemp) {
        handleDeviceFieldTemp(
          deviceId,
          isStartTimeTemp,
          isStopTimeTemp
        );
      }
    }
  }, [isStopTimeTemp]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeLastActive && isStopTimeLastActive) {
        handleDeviceFieldLastActive(
          deviceId,
          isStartTimeLastActive,
          isStopTimeLastActive
        );
      }
    }
  }, [isStopTimeTemp]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeLastActive && isStopTimeLastActive) {
        handleLastActive(
          isStartTimeLastActive,
          isStartTimeTemp,
          isStopTimeLastActive
        );
      }
    }
  }, [isStopTimeLastActive]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeRun && isStopTimeRun) {
        handleTimeRunToday(
          deviceId,
          isStartTimeRun,
          isStopTimeRun
        );
      }
    }
  }, [isStopTimeRun]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeAvgCosphi && isStopTimeAvgCosphi) {
        handleAverageCosphi(
          deviceId,
          isStartTimeAvgCosphi,
          isStopTimeAvgCosphi
        );
      }
    }
  }, [isStopTimeAvgCosphi]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeNetFreq && isStopTimeNetFreq) {
        handleNetFrequency(
          deviceId,
          isStartTimeNetFreq,
          isStopTimeNetFreq
        );
      }
    }
  }, [isStopTimeNetFreq]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeOutPower && isStopTimeOutPower) {
        handleDeviceFieldOutPower(
          deviceId,
          isStartTimeOutPower,
          isStopTimeOutPower
        );
      }
    }
  }, [isStopTimeOutPower]);
  useEffect(() => {
    if (deviceId) {
      if (isStartTimeOutCurrent && isStopTimeOutCurrent) {
        handleDeviceOutputCurrent(
          deviceId,
          isStartTimeOutCurrent,
          isStopTimeOutCurrent
        );
      }
    }
  }, [isStopTimeOutCurrent]);
  useEffect(() => {
    if (panelDeviceId) {
      if (isStartTimeModule && isStopTimeModule) {
        handleModuleTemp(panelDeviceId, isStartTimeModule, isStopTimeModule);
      } else {
        const fetchData = () => {
          handleModuleTemp(panelDeviceId);
        };
        fetchData(); // Call fetchData only after the first render
        const intervalId = setInterval(fetchData, 900000);

        return () => {
          clearInterval(intervalId);
        };
      }
    }
  }, [isStopTimeModule]);
  useEffect(() => {
    if (deviceId) {
      if (
        (isStartTimeDailyGen && isStopTimeDailyGen) ||
        generationstringDuration
      ) {
        handleDailyEnergyGen(
          deviceId,
          generationstringDuration,
          isStartTimeDailyGen,
          isStopTimeDailyGen
        );
      }
    }
  }, [isStopTimeDailyGen, generationstringDuration]);
  useEffect(() => {
    if (wmsDeviceId) {
      if (isStartTimeAmbient && isStopTimeAmbient) {
        handleAmbientTemp(
          wmsDeviceId,
          isStartTimeAmbient,
          isStopTimeAmbient
        );
      }
    }
  }, [isStopTimeAmbient]);
  const fetchIntervalData = (
    fetchDataFn,
    id,
    startTime,
    endTime,
    intervalSetter
  ) => {
    const fetchData = async () => {
      await fetchDataFn(id, startTime, endTime);
    };
    fetchData();
    const intervalId = setInterval(fetchData, fetchApiDuration);

    intervalSetter(intervalId);
  };

  useEffect(() => {
    let intervalId;
    if (id) {
      fetchIntervalData(
        handlePerfRatio,
        id,
        perfRatioStartTime,
        perfRatioStopTime,
        (id) => (intervalId = id)
      );
    }
    return () => clearInterval(intervalId);
  }, [id, perfRatioStartTime, perfRatioStopTime]);
  useEffect(() => {
    const fetchComparisonData = () => {
      if (wmsDeviceId) {
        handleAmbientTemp(wmsDeviceId);
      }
    };
    fetchComparisonData();
    const comparisonIntervalId = setInterval(
      fetchComparisonData,
      5 * 60 * 1000
    );
    return () => clearInterval(comparisonIntervalId);
  }, [wmsDeviceId]);
  const fetchApiDuration = 2 * 60 * 1000;
  const fetchData = useCallback(async () => {
    if (deviceId) {
      await Promise.all([
        handleDeviceOutputCurrent(deviceId),
        handleDeviceFieldOutPower(deviceId),
        handleDeviceFieldTemp(deviceId),
        handleDeviceFieldLastActive(deviceId),
        handleLastActive(deviceId),
        handleTimeRunToday(deviceId),
        handleAverageCosphi(deviceId),
        handleNetFrequency(deviceId),
        handleDailyEnergyGen(deviceId),
      ]);
    }
  }, [deviceId]);
  const toggleFullScreen = (graphKey) => {
    setFullScreenStates((prevStates) => ({
      ...prevStates,
      [graphKey]: !prevStates[graphKey],
    }));
  };
  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, fetchApiDuration);
    return () => clearInterval(intervalId);
  }, [fetchData]);

  const handleDeviceFieldOutPower = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, outputPower: true }));

      let res;
      res = await invService.getDeviceFieldoutPower(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setOutputPower(formattedData);
        setLoadingState((prevState) => ({ ...prevState, outputPower: false }));
      }
    } catch (error) {
      setOutputPower([])

      setLoadingState((prevState) => ({ ...prevState, outputPower: false }));
      handleFetchError(error, "Output Power");
    }
  };
  const handleWeatherParameters = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, weatherData: true }));

      const resPlant = await invService.getDeviceLevelData(id, "panel");
      if (resPlant.status === 200) {
        setPanelDeviceId(resPlant.data[0].device_data.device_id);
        handleModuleTemp(resPlant.data[0].device_data.device_id);
      }
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, weatherData: false }));
      handleFetchError(error, "weather data not found");
    }
  };
  const handleModuleTemp = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: true }));

      const res = await invService.getModuleTemp(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data
          // .filter((item) => item.module_temperature_degree_C !== 0)
          .map((item) => ({
            timestamp_edge: item.timestamp_edge,
            "Module Temperature": item.module_temperature_degree_C,
          }));
        setModuleTemperature(formattedData);
      }
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, moduleTemp: false }));
      handleFetchError(error, "module temperature data not found");
    }
  };
  const handleInvertersList = async (id) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, invList: true }));
      const res = await invService.getDeviceLevelData(id, "inverter");
      if (res.status === 200) {
        setNewInvArray(res.data);
        const totalOutputPower = res.data.reduce((total, device) => {
          if (
            device.device_data &&
            device.device_data.output_power_kW !== null
          ) {
            return total + device.device_data.output_power_kW;
          } else {
            return total;
          }
        }, 0);

        const sumCapacity = res.data.reduce((total, inverter) => {
          const capacityKW =
            inverter.device_details?.device_specific_details?.capacity_kW || 0;
          return total + capacityKW;
        }, 0);

        setTotalCapacity(sumCapacity);
        setTotalPowerGen(totalOutputPower);
      }
      setLoadingState((prevState) => ({ ...prevState, invList: false }));
    } catch (error) {
      console.log(error);

      setLoadingState((prevState) => ({ ...prevState, invList: false }));
      handleFetchError(error, "Inverter List");
    }
  };
  const handleDeviceFieldTemp = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, temp: true }));

      let res;
      res = await invService.getDeviceCompareFields(
        "inverter",
        device_id,
        "temperature_C",
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setTempData(formattedData);
        setLoadingState((prevState) => ({ ...prevState, temp: false }));
      }
    } catch (error) {
      setTempData([])

      setLoadingState((prevState) => ({ ...prevState, temp: false }));
      handleFetchError(error, "Temperature");
    }
  };
  const handleDeviceFieldLastActive = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, lastActive: true }));

      let res;
      res = await invService.getDeviceCompareFields(
        "inverter",
        device_id,
        "last_active_fault",
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setLastActiveData(formattedData);
        setLoadingState((prevState) => ({ ...prevState, lastActive: false }));
      }
    } catch (error) {
      setLastActiveData([])

      setLoadingState((prevState) => ({ ...prevState, lastActive: false }));
      handleFetchError(error, "Temperature");
    }
  };
  const handleAmbientTemp = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, ambient: true }));
      const res = await invService.getDeviceCompareWMS(
        "wms",
        id,
        "ambient_temperature",
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              let fieldValue = item[field];
              if (typeof fieldValue === "number") {
                fieldValue = fieldValue.toFixed(2);
              }
              formattedItem[formattedField] = fieldValue;
            }
          });
          return formattedItem;
        });
        setPerfCompareVal(formattedData);
        setLoadingState((prevState) => ({
          ...prevState,
          ambient: false,
        }));
      }

    } catch (error) {
      setPerfCompareVal([]);

      setLoadingState((prevState) => ({ ...prevState, ambient: false }));
      handleFetchError(error, "Device Fields Comparison");
    }
  };
  const handleLastActive = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, lastActive: true }));

      let res;
      res = await invService.getDeviceCompareFields(
        "inverter",
        device_id,
        "last_active_fault",
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setLastActive(formattedData);
        setLoadingState((prevState) => ({ ...prevState, lastActive: false }));
      }
    } catch (error) {
      setLastActive([])

      setLoadingState((prevState) => ({ ...prevState, lastActive: false }));
      handleFetchError(error, "Last active fault");
    }
  };
  const handleTimeRunToday = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, timeRun: true }));

      let res;
      res = await invService.getDeviceCompareFields(
        "inverter",
        device_id,
        "time_run_today_h",
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setTimeRunToday(formattedData);
        setLoadingState((prevState) => ({ ...prevState, timeRun: false }));
      }
    } catch (error) {
      setTimeRunToday([])
      setLoadingState((prevState) => ({ ...prevState, timeRun: false }));
      handleFetchError(error, "time run today");
    }
  };
  const handleAverageCosphi = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, avgCosphi: true }));

      let res;
      res = await invService.getDeviceCompareFields(
        "inverter",
        device_id,
        "average_cosphii_percent",
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setAverageCosphi(formattedData);
        setLoadingState((prevState) => ({ ...prevState, avgCosphi: false }));
      }
    } catch (error) {
      setAverageCosphi([])
      setLoadingState((prevState) => ({ ...prevState, avgCosphi: false }));
      handleFetchError(error, "average cosphii percent");
    }
  };
  const handleNetFrequency = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, freq: true }));

      let res;
      res = await invService.getDeviceCompareFields(
        "inverter",
        device_id,
        "net_frequency_Hz",
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setNetFrequency(formattedData);
        setLoadingState((prevState) => ({ ...prevState, freq: false }));
      }
    } catch (error) {
      setNetFrequency([])
      setLoadingState((prevState) => ({ ...prevState, freq: false }));
      handleFetchError(error, "net frequency");
    }
  };
  const handleDeviceOutputCurrent = async (device_id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, outputCurrent: true }));
      let res;
      res = await invService.getDeviceOutputCurrent(
        device_id,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.map((item) => {
          const formattedItem = { timestamp_edge: item.timestamp_edge };
          Object.keys(item).forEach((field) => {
            if (field !== "timestamp_edge" && field !== "device_name") {
              const formattedField = field
                .replace("_", " ")
                .replace(/\b\w/g, (c) => c.toUpperCase());
              formattedItem[formattedField] = item[field];
            }
          });
          return formattedItem;
        });
        setOutputCurrent(formattedData);
        setLoadingState((prevState) => ({ ...prevState, outputCurrent: false }));
      }
    } catch (error) {
      setOutputCurrent([]);
      setLoadingState((prevState) => ({ ...prevState, outputCurrent: false }));
      handleFetchError(error, "Output Current");
    }
  };
  const handleDailyEnergyGen = async (
    id,
    generationstringDuration,
    start_time,
    end_time
  ) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, dailyGen: true }));
      const res = await invService.getDeviceEnergyGeneration(
        id,
        generationstringDuration === undefined ? "D" : generationstringDuration,
        start_time,
        end_time
      );
      if (res.status === 200) {
        const formattedData = res.data.data
          .filter((item) => item["daily_energy_generation_MWh"] !== 0)
          .map((item) => {
            const {
              timestamp_edge: timestamp_edge,
              daily_energy_generation_MWh: value,
            } = item;
            return { timestamp_edge, value };
          });
        setDailyGeneration(formattedData);
        setLoadingState((prevState) => ({ ...prevState, dailyGen: false }));
      }
    } catch (error) {
      setDailyGeneration([])

      setLoadingState((prevState) => ({ ...prevState, dailyGen: false }));
      handleFetchError(error, "daily energy generation");
    }
  };
  const handlePerfRatio = async (id, start_time, end_time) => {
    try {
      setLoadingState((prevState) => ({ ...prevState, perfRatio: true }));
      const reqData = {};

      if (start_time && end_time) {
        reqData.start_time = decodeURIComponent(start_time);
        reqData.end_time = decodeURIComponent(end_time);
        reqData.graph_type = "performance_ratio"
      }
      const res = await invService.getMultiBarData(
        id,
        reqData
      );
      if (res.status === 200) {
        setPerformanceValue(res.data?.charts?.performance_ratio);
        setAveragePerformaceRatio(res.data?.current_values?.performance_ratio?.actual)
      }
      setLoadingState((prevState) => ({ ...prevState, perfRatio: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, perfRatio: false }));
      handleFetchError(error, "performance ratio");
    }
  };
  const inverterNames = newInvArray
    .filter(
      (item) =>
        item.device_details !== null && item.device_details.name !== null
    )
    .map((item) => item.device_details.name);
  const handleBackClick = () => {
    history.push("/iot-home/fault_analysis")
  }
  return (
    <div className="home-container">
      <Grid container className="grid-container" sx={{ backgroundColor: "#ffff", padding: "1rem", border: "0.0625rem solid #d9d9d9", borderRadius: "0.5rem" }}>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ justifyContent: "flex-start !important", gap: "1rem" }}
          >
            <img src='https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/arrow_forward.svg' style={{ width: "1.3rem", cursor: "pointer" }} alt='arrow' onClick={handleBackClick} />
            <p className='Poppins_24px_rem' style={{ fontWeight: "500", color: "#212121" }}>Primary KPIs</p>
          </Paper>
        </Grid>
        {faultType === "Electrical Overload" && (<><Grid item lg={fullScreenStates.graph3 ? 12 : 3.9} xs={12} md={fullScreenStates.graph3 ? 12 : 5.9}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph3 ? "fixed" : "relative",
            top: fullScreenStates.graph3 ? "9vh" : "auto",
            width: fullScreenStates.graph3 ? "-webkit-fill-available !important" : "auto",
            height: fullScreenStates.graph3 ? "92vh" : "auto",
            margin: fullScreenStates.graph3 ? "-1vh -0vh !important" : "0vh",
            zIndex: fullScreenStates.graph3 ? 999 : "auto",
            background: "#fff",
          }}>
          <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph3 ? "100%" : "auto" }}>
            <SingleLineTempGraph
              isValueData={tempData}
              StartTimer={setIsStartTimeTemp}
              StopTimer={setIsStopTimeTemp}
              openDrawerValue={setDrawerOpen}
              yAxisText={"Temperature (°C)"}
              titleText={"Temperature"}
              legendName={"Temperature"}
              indicateUnit={"°C"}
              calenderClick="on"
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeTemp
                    ? isStartTimeTemp
                    : new Date(),
                  endDate: isStopTimeTemp
                    ? isStopTimeTemp
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isFullScreen={fullScreenStates.graph3}
              toggleFullScreen={() => toggleFullScreen('graph3')}
              isDesktopView={isDesktopView} />
            {loadingState.temp && (
              <SimpleBackdrop open={loadingState.temp} />
            )}
          </Paper>
        </Grid><Grid item lg={fullScreenStates.graph2 ? 12 : 3.9} xs={12} md={fullScreenStates.graph2 ? 12 : 5.9}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph2 ? "fixed" : "relative",
            top: fullScreenStates.graph2 ? "9vh" : "auto",
            width: fullScreenStates.graph2 ? "-webkit-fill-available !important" : "auto",
            height: fullScreenStates.graph2 ? "92vh" : "auto",
            margin: fullScreenStates.graph2 ? "-1vh -0vh !important" : "0vh",
            zIndex: fullScreenStates.graph2 ? 999 : "auto",
            background: "#fff",
          }}>
            <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph2 ? "100%" : "auto" }}>
              <SingleLineTempGraph
                isValueData={outputPower}
                StartTimer={setIsStartTimeOutPower}
                StopTimer={setIsStopTimeOutPower}
                openDrawerValue={setDrawerOpen}
                yAxisText={"Output Power(kW)"}
                titleText={"Output Power"}
                legendName={"Output Power"}
                indicateUnit={"kW"}
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeOutPower
                      ? isStartTimeOutPower
                      : new Date(),
                    endDate: isStopTimeOutPower
                      ? isStopTimeOutPower
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isFullScreen={fullScreenStates.graph2}
                toggleFullScreen={() => toggleFullScreen('graph2')}
                isDesktopView={isDesktopView} />
              {loadingState.outputPower && (
                <SimpleBackdrop open={loadingState.outputPower} />
              )}
            </Paper>
          </Grid><Grid item lg={fullScreenStates.graph1 ? 12 : 3.9} xs={12} md={fullScreenStates.graph1 ? 12 : 5.9}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph1 ? "fixed" : "relative",
              top: fullScreenStates.graph1 ? "9vh" : "auto",
              width: fullScreenStates.graph1 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph1 ? "92vh" : "auto",
              margin: fullScreenStates.graph1 ? "-1vh -0vh !important" : "0vh",
              zIndex: fullScreenStates.graph1 ? 999 : "auto",
              background: "#fff",
            }}>
            <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph1 ? "100%" : "auto" }}>
              <SingleLineTempGraph
                isValueData={outputCurrent}
                StartTimer={setIsStartTimeOutCurrent}
                StopTimer={setIsStopTimeOutCurrent}
                openDrawerValue={setDrawerOpen}
                yAxisText={"Output Current"}
                titleText={"Output Current"}
                legendName="Output Current"
                indicateUnit="A"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeOutCurrent
                      ? isStartTimeOutCurrent
                      : new Date(),
                    endDate: isStopTimeOutCurrent
                      ? isStopTimeOutCurrent
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isFullScreen={fullScreenStates.graph1}
                toggleFullScreen={() => toggleFullScreen('graph1')}
                isDesktopView={isDesktopView} />
            </Paper>
            {loadingState.outputCurrent && (
              <SimpleBackdrop open={loadingState.outputCurrent} />
            )}
          </Grid><Grid
            item
            lg={fullScreenStates.graph4 ? 11.9 : 5.9}
            xs={12}
            md={5.9}
            sm={12}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph4 ? "fixed" : "relative",
              top: fullScreenStates.graph4 ? "9vh" : "auto",
              width: fullScreenStates.graph4 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph4 ? "92vh" : "auto",
              margin: fullScreenStates.graph4 ? "-1vh -0vh !important" : "0vh",
              zIndex: fullScreenStates.graph4 ? 999 : "auto",
              background: "#fff",
            }}
          >
            <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph4 ? "100%" : "auto" }}
            >
              <InvBarPlot
                isValueData={dailyGeneration}
                StartTimer={setIsStartTimeDailyGen}
                StopTimer={setIsStopTimeDailyGen}
                openDrawerValue={setDrawerOpen}
                titleText="Daily Energy Generation"
                indicateUnit="kWh"
                tooltipName="Energy Generation"
                stringDurationFun={setGenerationStringDuration}
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeDailyGen
                      ? isStartTimeDailyGen
                      : new Date(
                        new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                      ),
                    endDate: isStopTimeDailyGen
                      ? isStopTimeDailyGen
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isFilter={true}
                isFullScreen={fullScreenStates.graph4}
                toggleFullScreen={() => toggleFullScreen('graph4')}
                isMobileAndTab={isMobileAndTab}
                isDesktopView={isDesktopView} />

              {loadingState.dailyGen && (
                <SimpleBackdrop open={loadingState.dailyGen} />
              )}
            </Paper>
          </Grid><Grid item lg={fullScreenStates.graph5 ? 12 : 5.9} xs={12} md={fullScreenStates.graph5 ? 12 : 12}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph5 ? "fixed" : "relative",
              top: fullScreenStates.graph5 ? "9vh" : "auto",
              width: fullScreenStates.graph5 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph5 ? "92vh" : "auto",
              margin: fullScreenStates.graph5 ? "-1vh -0vh !important" : "0vh",
              zIndex: fullScreenStates.graph5 ? 999 : "auto",
              background: "#fff",
            }}>
            <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph5 ? "100%" : "auto" }}>
              <SingleLineTempGraph
                isValueData={averageCosphi}
                StartTimer={setIsStartTimeAvgCosphi}
                StopTimer={setIsStopTimeAvgCosphi}
                openDrawerValue={setDrawerOpen}
                yAxisText={"Average Cosphi (%)"}
                titleText={"Average Cosphi (%)"}
                legendName={"Average Cosphi (%)"}
                indicateUnit={"h"}
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeAvgCosphi
                      ? isStartTimeAvgCosphi
                      : new Date(),
                    endDate: isStopTimeAvgCosphi
                      ? isStopTimeAvgCosphi
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isFullScreen={fullScreenStates.graph3}
                toggleFullScreen={() => toggleFullScreen('graph5')}
                isDesktopView={isDesktopView} />
              {loadingState.avgCosphi && (
                <SimpleBackdrop open={loadingState.avgCosphi} />
              )}
            </Paper>
          </Grid></>)}
        {faultType === "Temperature Sensor Failure" && (
          <>
            <Grid
              item
              lg={5.9}
              xs={12}
              md={11.9}
              sm={12}
              className="exc-graph-grid"
              sx={{
                position: fullScreenStates.graph9 ? "fixed" : "relative",
                top: fullScreenStates.graph9 ? "9vh" : "auto",
                width: fullScreenStates.graph9 ? "-webkit-fill-available !important" : "auto",
                height: fullScreenStates.graph9 ? "92vh" : "auto",
                margin: fullScreenStates.graph9 ? "-1vh !important" : "0vh",
                zIndex: fullScreenStates.graph9 ? 999 : "auto",
                background: "#fff",
              }}
            >
              <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph9 ? "100%" : "auto" }}>
                <SingleLineTempGraph
                  isValueData={moduleTemperature}
                  titleText="Module Temperature"
                  openDrawerValue={setDrawerOpen}
                  StartTimer={setIsStartTimeModule}
                  StopTimer={setIsStopTimeModule}
                  yAxisText="Module Temperature (°C)"
                  legendName="Module Temperature "
                  indicateUnit="(°C)"
                  calenderClick="on"
                  selectedDateRangeProp={[
                    {
                      startDate: isStartTimeModule
                        ? isStartTimeModule
                        : new Date(),
                      endDate: isStopTimeModule ? isStopTimeModule : new Date(),
                      key: "selection",
                    },
                  ]}
                  isMobileAndTab={isMobileAndTab}
                  isDesktopView={isDesktopView}
                  isFullScreen={fullScreenStates.graph9}
                  toggleFullScreen={() => toggleFullScreen('graph9')}
                />
              </Paper>
              {loadingState.moduleTemp && (
                <SimpleBackdrop open={loadingState.moduleTemp} />
              )}
            </Grid>
            <Grid
              item
              lg={5.9}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <StatusGrid statuses={sensorStatus} title="Sensor Status" legendName={sensorLegendName} />

            </Grid>
          </>
        )}
        {faultType === "Firmware Update Required" && (
          <>
            <Grid
              item
              lg={5.9}
              xs={12}
              md={12}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <StatusGrid statuses={invStatus} title="Firmware Version Status" legendName={legendName} />

            </Grid>
            <Grid
              item
              lg={3.9}
              xs={12}
              md={7.9}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <OperationalEfficiency data={jsonData} />

              {loadingState.perfRatio && (
                <SimpleBackdrop open={loadingState.perfRatio} />
              )}
            </Grid>
            <Grid
              item
              lg={2}
              xs={12}
              md={4}
              sm={12}
              className="donut_grid_exc"
              id="power_analysis_id"
            >
              <div className="flex-start_div">
                <div className="icon-exc-div">
                  <img
                    src="https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/avg_pace.svg"
                    alt="plant"
                    style={{ width: "2rem" }}
                  />
                </div>
                <div className="flex-start-column">
                  <p className="Poppins_18px_rem">Total Run Time</p>
                </div>

              </div>
              <div className='expected_div' style={{ width: "auto" }}>
                <p className="Poppins_14px_rem" style={{ fontWeight: "400", textAlign: "center" }}>Inverter 1 - Total Runtime</p>
                <img src="https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/hourglass_pause.svg" alt='stand' style={{ width: "1rem" }} />
                <p className="Poppins_18px_rem">29477 hours</p>
              </div>
            </Grid>

          </>
        )}

      </Grid>
      <Grid container className="grid-container" sx={{ backgroundColor: "#ffff", padding: "1rem", border: "0.0625rem solid #d9d9d9", borderRadius: "0.5rem", marginTop: "0.5rem !important" }}>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ justifyContent: "flex-start !important" }}
          >
            <p className='Poppins_24px_rem' style={{ fontWeight: "500", color: "#212121" }}>Supporting KPIs</p>
          </Paper>
        </Grid>
        {faultType === "Electrical Overload" && (<><Grid item lg={fullScreenStates.graph6 ? 12 : 3.9} xs={12} md={fullScreenStates.graph6 ? 12 : 5.9}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph6 ? "fixed" : "relative",
            top: fullScreenStates.graph6 ? "9vh" : "auto",
            width: fullScreenStates.graph6 ? "-webkit-fill-available !important" : "auto",
            height: fullScreenStates.graph6 ? "92vh" : "auto",
            margin: fullScreenStates.graph6 ? "-1vh -0vh !important" : "0vh",
            zIndex: fullScreenStates.graph6 ? 999 : "auto",
            background: "#fff",
          }}>
          <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph6 ? "100%" : "auto" }}>
            <SingleLineTempGraph
              isValueData={lastActive}
              StartTimer={setIsStartTimeLastActive}
              StopTimer={setIsStopTimeLastActive}
              openDrawerValue={setDrawerOpen}
              yAxisText={"Last Active Fault"}
              titleText={"Last Active Fault"}
              legendName={"Last Active Fault"}
              indicateUnit={""}
              calenderClick="on"
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeTemp
                    ? isStartTimeTemp
                    : new Date(),
                  endDate: isStopTimeTemp
                    ? isStopTimeTemp
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isFullScreen={fullScreenStates.graph6}
              toggleFullScreen={() => toggleFullScreen('graph6')}
              isDesktopView={isDesktopView} />
            {loadingState.lastActive && (
              <SimpleBackdrop open={loadingState.lastActive} />
            )}
          </Paper>
        </Grid><Grid item lg={fullScreenStates.graph7 ? 12 : 3.9} xs={12} md={fullScreenStates.graph7 ? 12 : 5.9}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph7 ? "fixed" : "relative",
            top: fullScreenStates.graph7 ? "9vh" : "auto",
            width: fullScreenStates.graph7 ? "-webkit-fill-available !important" : "auto",
            height: fullScreenStates.graph7 ? "92vh" : "auto",
            margin: fullScreenStates.graph7 ? "-1vh -0vh !important" : "0vh",
            zIndex: fullScreenStates.graph7 ? 999 : "auto",
            background: "#fff",
          }}>
            <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph7 ? "100%" : "auto" }}>
              <SingleLineTempGraph
                isValueData={timeRunToday}
                StartTimer={setIsStartTimeRun}
                StopTimer={setIsStopTimeRun}
                openDrawerValue={setDrawerOpen}
                yAxisText={"Time Run Today (hrs)"}
                titleText={"Time Run Today"}
                legendName={"Time Run Today"}
                indicateUnit={"hrs"}
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeOutPower
                      ? isStartTimeOutPower
                      : new Date(),
                    endDate: isStopTimeOutPower
                      ? isStopTimeOutPower
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isFullScreen={fullScreenStates.graph7}
                toggleFullScreen={() => toggleFullScreen('graph7')}
                isDesktopView={isDesktopView} />
              {loadingState.outputPower && (
                <SimpleBackdrop open={loadingState.outputPower} />
              )}
            </Paper>
          </Grid><Grid item lg={fullScreenStates.graph8 ? 12 : 3.9} xs={12} md={fullScreenStates.graph8 ? 12 : 12}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph8 ? "fixed" : "relative",
              top: fullScreenStates.graph8 ? "9vh" : "auto",
              width: fullScreenStates.graph8 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph8 ? "92vh" : "auto",
              margin: fullScreenStates.graph8 ? "-1vh -0vh !important" : "0vh",
              zIndex: fullScreenStates.graph8 ? 999 : "auto",
              background: "#fff",
            }}>
            <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph8 ? "100%" : "auto" }}>
              <SingleLineTempGraph
                isValueData={netFrequency}
                StartTimer={setIsStartTimeNetFreq}
                StopTimer={setIsStopTimeNetFreq}
                openDrawerValue={setDrawerOpen}
                yAxisText={"Net Frequency"}
                titleText={"Net Frequency (Hz)"}
                legendName="Net Frequency"
                indicateUnit="Hz"
                calenderClick="on"
                selectedDateRangeProp={[
                  {
                    startDate: isStartTimeNetFreq
                      ? isStartTimeNetFreq
                      : new Date(),
                    endDate: isStopTimeNetFreq
                      ? isStopTimeNetFreq
                      : new Date(),
                    key: "selection",
                  },
                ]}
                isMobileAndTab={isMobileAndTab}
                isFullScreen={fullScreenStates.graph1}
                toggleFullScreen={() => toggleFullScreen('graph8')}
                isDesktopView={isDesktopView} />
            </Paper>
            {loadingState.freq && (
              <SimpleBackdrop open={loadingState.freq} />
            )}
          </Grid>
          {/* <Grid item lg={fullScreenStates.graph8 ? 12 : 12} xs={12} md={fullScreenStates.graph8 ? 12 : 12}
            className="exc-graph-grid"
            sx={{
              position: fullScreenStates.graph8 ? "fixed" : "relative",
              top: fullScreenStates.graph8 ? "9vh" : "auto",
              width: fullScreenStates.graph8 ? "-webkit-fill-available !important" : "auto",
              height: fullScreenStates.graph8 ? "92vh" : "auto",
              margin: fullScreenStates.graph8 ? "-1vh -0vh !important" : "0vh",
              zIndex: fullScreenStates.graph8 ? 999 : "auto",
              background: "#fff",
            }}>
            <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph8 ? "100%" : "auto" }}>
              <AnnotationChart />
            </Paper>
            {loadingState.freq && (
              <SimpleBackdrop open={loadingState.freq} />
            )}
          </Grid> */}
          </>)}
        {
          faultType === "Temperature Sensor Failure" && (
            <>
              <Grid item lg={11.9} xs={12} md={11.9} className="exc-graph-grid"
                sx={{
                  position: fullScreenStates.graph10 ? "fixed" : "relative",
                  top: fullScreenStates.graph10 ? "9vh" : "auto",
                  width: fullScreenStates.graph10 ? "-webkit-fill-available !important" : "auto",
                  height: fullScreenStates.graph10 ? "92vh" : "auto",
                  margin: fullScreenStates.graph10 ? "-1vh !important" : "0vh",
                  zIndex: fullScreenStates.graph10 ? 999 : "auto",
                  background: "#fff",
                }}>
                <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph10 ? "100%" : "auto" }}>
                  <SingleLineTempGraph
                    isValueData={perfCompareVal}
                    titleText="Ambient Temperature"
                    openDrawerValue={setDrawerOpen}
                    StartTimer={setIsStartTimeAmbient}
                    StopTimer={setIsStopTimeAmbient}
                    yAxisText="Ambient Temperature (°C)"
                    legendName="Ambient Temperature"
                    indicateUnit="(°C)"
                    calenderClick="on"
                    selectedDateRangeProp={[
                      {
                        startDate: isStartTimeAmbient
                          ? isStartTimeAmbient
                          : new Date(),
                        endDate: isStopTimeAmbient ? isStopTimeAmbient : new Date(),
                        key: "selection",
                      },
                    ]}
                    isMobileAndTab={isMobileAndTab}
                    isDesktopView={isDesktopView}
                    isFullScreen={fullScreenStates.graph10}
                    toggleFullScreen={() => toggleFullScreen('graph10')}
                  />

                  {loadingState.ambient && (
                    <SimpleBackdrop open={loadingState.ambient} />
                  )}
                </Paper>
              </Grid>
              <Grid
                item
                lg={4}
                xs={12}
                md={12}
                sm={12}
                className="donut_grid_exc"
                id="power_analysis_id"
              >
                <Paper className="energy-paper" id="donut_paper">
                  <p className="Poppins_18px_rem">Generated Power</p>
                </Paper>
                <Paper className="energy-paper center_div_nogap">
                  <CustomCircularBar
                    centerText={totalPowerGen ? totalPowerGen : 0}
                    values={newInvArray
                      .filter((item) => item.device_details !== null)
                      .map((device, index) => {
                        const outputPower =
                          device.device_data !== null
                            ? device.device_data?.output_power_kW
                            : 0;
                        return outputPower || 0;
                      })}
                    nameData={inverterNames}
                    unit="kW"
                  />
                </Paper>

                {loadingState.invList && (
                  <SimpleBackdrop open={loadingState.invList} />
                )}
              </Grid>
              <Grid
                item
                lg={7.9}
                xs={12}
                sm={12}
                md={12}
                className="exc-graph-grid"
                sx={{
                  position: fullScreenStates.graph2 ? "fixed" : "relative",
                  top: fullScreenStates.graph2 ? "9vh" : "auto",
                  width: fullScreenStates.graph2 ? "-webkit-fill-available !important" : "auto",
                  height: fullScreenStates.graph2 ? "92vh" : "auto",
                  margin: fullScreenStates.graph2 ? "-1vh !important" : "0vh",
                  zIndex: fullScreenStates.graph2 ? 999 : "auto",
                  background: "#fff",
                }}
              >
                <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph2 ? "100%" : "auto" }}>
                  <BarPlotForIOT
                    isValueData={performanceValue}
                    StartTimer={setPerfRatioStartTime}
                    StopTimer={setPerfRatioStopTime}
                    openDrawerValue={setDrawerOpen}
                    titleText="Performance Ratio (%)"
                    indicateUnit="%"
                    selectedDateRangeProp={[
                      {
                        startDate: perfRatioStartTime
                          ? perfRatioStartTime
                          : new Date(
                            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                          ),
                        endDate: perfRatioStopTime ? perfRatioStopTime : new Date(),
                        key: "selection",
                      },
                    ]}
                    isOnlyMobile={isOnlyMobile}
                    isFullScreen={fullScreenStates.graph2}
                    toggleFullScreen={() => toggleFullScreen("graph2")}
                    isMobileAndTab={isMobileAndTab}
                    isDesktopView={isDesktopView}
                  />
                </Paper>
                {loadingState.perfRatio && (
                  <SimpleBackdrop open={loadingState.perfRatio} />
                )}
              </Grid>
            </>
          )
        }
        {
          faultType === "Firmware Update Required" && (
            <>
              <Grid
                item
                lg={6}
                xs={12}
                sm={12}
                md={6}
                className="exc-graph-grid"
                sx={{
                  position: fullScreenStates.graph2 ? "fixed" : "relative",
                  top: fullScreenStates.graph2 ? "9vh" : "auto",
                  width: fullScreenStates.graph2 ? "-webkit-fill-available !important" : "auto",
                  height: fullScreenStates.graph2 ? "92vh" : "auto",
                  margin: fullScreenStates.graph2 ? "-1vh !important" : "0vh",
                  zIndex: fullScreenStates.graph2 ? 999 : "auto",
                  background: "#fff",
                }}
              >
                <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph2 ? "100%" : "auto" }}>
                  <BarPlotForIOT
                    isValueData={performanceValue}
                    StartTimer={setPerfRatioStartTime}
                    StopTimer={setPerfRatioStopTime}
                    openDrawerValue={setDrawerOpen}
                    titleText="Performance Ratio (%)"
                    indicateUnit="%"
                    selectedDateRangeProp={[
                      {
                        startDate: perfRatioStartTime
                          ? perfRatioStartTime
                          : new Date(
                            new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                          ),
                        endDate: perfRatioStopTime ? perfRatioStopTime : new Date(),
                        key: "selection",
                      },
                    ]}
                    isOnlyMobile={isOnlyMobile}
                    isFullScreen={fullScreenStates.graph2}
                    toggleFullScreen={() => toggleFullScreen("graph2")}
                    isMobileAndTab={isMobileAndTab}
                    isDesktopView={isDesktopView}
                  />
                </Paper>
                {loadingState.perfRatio && (
                  <SimpleBackdrop open={loadingState.perfRatio} />
                )}
              </Grid>
              <Grid item lg={fullScreenStates.graph11 ? 12 : 5.9} xs={12} md={fullScreenStates.graph11 ? 12 : 5.9}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph11 ? "fixed" : "relative",
            top: fullScreenStates.graph11 ? "9vh" : "auto",
            width: fullScreenStates.graph11 ? "-webkit-fill-available !important" : "auto",
            height: fullScreenStates.graph11 ? "92vh" : "auto",
            margin: fullScreenStates.graph11 ? "-1vh -0vh !important" : "0vh",
            zIndex: fullScreenStates.graph11 ? 999 : "auto",
            background: "#fff",
          }}>
          <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph11 ? "100%" : "auto" }}>
            <SingleLineTempGraph
              isValueData={tempData}
              StartTimer={setIsStartTimeLastActive}
              StopTimer={setIsStopTimeLastActive}
              openDrawerValue={setDrawerOpen}
              yAxisText={"Last Active Fault"}
              titleText={"Last Active Fault"}
              legendName={"Last Active Fault"}
              indicateUnit={""}
              calenderClick="on"
              customHeight="400px"
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeLastActive
                    ? isStartTimeLastActive
                    : new Date(),
                  endDate: isStopTimeLastActive
                    ? isStopTimeLastActive
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isFullScreen={fullScreenStates.graph11}
              toggleFullScreen={() => toggleFullScreen('graph11')}
              isDesktopView={isDesktopView} />
            {loadingState.lastActive && (
              <SimpleBackdrop open={loadingState.lastActive} />
            )}
          </Paper>
        </Grid>
            </>
          )
        }
      </Grid>
      <Grid container className="grid-container" sx={{ backgroundColor: "#ffff", padding: "1rem", border: "0.0625rem solid #d9d9d9", borderRadius: "0.5rem", marginTop: "0.5rem !important" }}>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ justifyContent: "flex-start !important" }}
          >
            <p className='Poppins_24px_rem' style={{ fontWeight: "500", color: "#212121" }}>Potential Alternate Causes</p>
          </Paper>
        </Grid>
        {faultType === "Electrical Overload" && <Grid container className="grid-container" sx={{ marginTop: "1rem !important" }}>
          {causes.electrical_overload.map((item, index) => {
            return (
              <Grid
                item
                key={index}
                lg={3.9}
                xs={12}
                sm={12}
                md={3.9}
                className="exc-graph-grid"
                sx={{ border: "none !important" }}
              >
                <Paper
                  className="exc-graph-paper"
                  sx={{ flexDirection: "column", gap: "0.5rem", height: "100%" }}
                >
                  <p className='Poppins_16px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>{item.title}</p>
                  <div className='cause_container'>
                    <img src={item.icon_url} style={{ width: "2rem" }} alt='cause' />
                    <p className="Poppins_16px_rem" style={{ fontWeight: "500", textAlign: "center" }}>{item.description}</p>
                  </div>
                </Paper>
              </Grid>
            )
          })}
        </Grid>}

        {faultType === "Temperature Sensor Failure" && <Grid container className="grid-container" sx={{ marginTop: "1rem !important" }}>
          {causes.temp_sensor_failure.map((item, index) => {
            return (
              <Grid
                item
                key={index}
                lg={3.9}
                xs={12}
                sm={12}
                md={3.9}
                className="exc-graph-grid"
                sx={{ border: "none !important" }}
              >
                <Paper
                  className="exc-graph-paper"
                  sx={{ flexDirection: "column", gap: "0.5rem", height: "100%" }}
                >
                  <p className='Poppins_16px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>{item.title}</p>
                  <div className='cause_container'>
                    <img src={item.icon_url} style={{ width: "2rem" }} alt='cause' />
                    <p className="Poppins_16px_rem" style={{ fontWeight: "500", textAlign: "center" }}>{item.description}</p>
                  </div>
                </Paper>
              </Grid>
            )
          })}
        </Grid>}
        {faultType === "Firmware Update Required" && <Grid container className="grid-container" sx={{ marginTop: "1rem !important" }}>
          {causes.firmware.map((item, index) => {
            return (
              <Grid
                item
                key={index}
                lg={3.9}
                xs={12}
                sm={12}
                md={3.9}
                className="exc-graph-grid"
                sx={{ border: "none !important" }}
              >
                <Paper
                  className="exc-graph-paper"
                  sx={{ flexDirection: "column", gap: "0.5rem", height: "100%" }}
                >
                  <p className='Poppins_16px_rem' style={{ fontWeight: "600", color: "#2F67F8" }}>{item.title}</p>
                  <div className='cause_container'>
                    <img src={item.icon_url} style={{ width: "2rem" }} alt='cause' />
                    <p className="Poppins_16px_rem" style={{ fontWeight: "500", textAlign: "center" }}>{item.description}</p>
                  </div>
                </Paper>
              </Grid>
            )
          })}
        </Grid>}
      </Grid>
      <Grid container className="grid-container" sx={{ backgroundColor: "#ffff", padding: "1rem", border: "0.0625rem solid #d9d9d9", borderRadius: "0.5rem", marginTop: "0.5rem !important" }}>
        <Grid
          item
          lg={12}
          xs={12}
          sm={12}
          md={12}
          className="exc-graph-grid"
          sx={{ border: "none !important" }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ justifyContent: "flex-start !important" }}
          >
            <p className='Poppins_24px_rem' style={{ fontWeight: "500", color: "#212121" }}>Energy Loss Assessment</p>
          </Paper>
        </Grid>
        <Grid item lg={5.9} xs={12} md={5.9}
          className="exc-graph-grid"
          sx={{
            position: fullScreenStates.graph2 ? "fixed" : "relative",
            top: fullScreenStates.graph2 ? "9vh" : "auto",
            width: fullScreenStates.graph2 ? "-webkit-fill-available !important" : "auto",
            height: fullScreenStates.graph2 ? "92vh" : "auto",
            margin: fullScreenStates.graph2 ? "-1vh -0vh !important" : "0vh",
            zIndex: fullScreenStates.graph2 ? 999 : "auto",
            background: "#fff",
          }}>
          <Paper className="exc-graph-paper" sx={{ height: fullScreenStates.graph2 ? "100%" : "auto" }}>
            <SingleLineTempGraph
              isValueData={outputPower}
              StartTimer={setIsStartTimeOutPower}
              StopTimer={setIsStopTimeOutPower}
              openDrawerValue={setDrawerOpen}
              yAxisText={"Daily Energy Loss"}
              titleText={"Daily Energy Loss"}
              legendName={"Daily Energy Loss"}
              indicateUnit={""}
              calenderClick="on"
              selectedDateRangeProp={[
                {
                  startDate: isStartTimeOutPower
                    ? isStartTimeOutPower
                    : new Date(),
                  endDate: isStopTimeOutPower
                    ? isStopTimeOutPower
                    : new Date(),
                  key: "selection",
                },
              ]}
              isMobileAndTab={isMobileAndTab}
              isFullScreen={fullScreenStates.graph2}
              toggleFullScreen={() => toggleFullScreen('graph2')}
              isDesktopView={isDesktopView}
            />
            {loadingState.outputPower && (
              <SimpleBackdrop open={loadingState.outputPower} />
            )}
          </Paper>
        </Grid>
        <Grid
          item
          lg={5.9}
          xs={12}
          sm={12}
          md={5.9}
          className="exc-graph-grid"
          sx={{ padding: "1rem !important" }}
        >
          <Paper
            className="exc-graph-paper"
            sx={{ height: "100%", flexDirection: "column", gap: "1rem", justifyContent: "space-between" }}
          >
            <div className='center_center_flex' style={{ width: "100%", justifyContent: "space-around", height: "auto" }}>
              <div className="align_self_center" style={{ padding: "0rem 2rem", height: "100%", justifyContent: faultType === "flex-start" }}>
                <p className='Poppins_18px_rem' style={{
                  position: "relative",
                  top: "0rem", left: "0rem"
                }}>Energy Loss Duration</p>
                <img src="https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/lightning_stand.svg" alt='stand' style={{ width: "2rem" }} />
                <div className='expected_div' style={{ width: "100%" }}>
                  <img src="https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/avg_pace.svg" alt='stand' style={{ width: "1rem" }} />
                  <p className="Poppins_14px_rem" style={{ fontWeight: "400", textAlign: "center" }}>During Peak Generation Time </p>
                  <img src="https://s3.us-east-1.amazonaws.com/smarttrak.co/v2Images/hourglass_pause.svg" alt='stand' style={{ width: "1rem" }} />
                  <p className="Poppins_18px_rem">2.5 hours</p>
                </div>
              </div>
              <div className="align_self_center" style={{ height: "100%" }}>
                <p className='Poppins_18px_rem'>Estimated Revenue Impact</p>
                <Revenue isValueData={plantEnergy}
                  expectedRevenue={10000}
                  actualRevenue={9430}
                  peakGenerationTime="2.5"
                />
              </div>
            </div>
            <p className="Poppins_14px_rem" style={{ color: "#5E6064", fontWeight: "500", fontStyle: "italic", textAlign: "center" }}>
              {`$570.00 loss (5.70% loss over a 2.5 hour peak generation period).`}
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default DetailedDiagnostic