import React, { useEffect, useRef, useState } from "react";
import Navbar from "../MainLandingPage/Navbar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Footer from "../MainLandingPage/Footer";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router-dom";

function SolarTracker() {
  const proCards = [
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/sixthImgOne.svg",
      title: "Advanced Control and Precision",
      description:
        "Optimizes solar energy capture by continuously monitoring motor currents and utilizing accelerometer data for precise panel positioning. It integrates a Physics-Informed Neural Network (PINN) to enhance tracking accuracy and efficiency, adapting to various conditions to maximize energy production.",
    },
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/SixthImgTwo.svg",
      title: "Versatile Tracking Modes",
      description:
        "Offers multiple operational modes including Auto, where the tracker aligns panels to a target angle; Sun Track Start, which adjusts based on solar angles; Manual, for user-controlled angle adjustments; and Stop, halting panel movement when necessary, ensuring flexibility and control.",
    },
    {
      img: "https://s3.amazonaws.com/smarttrak.co/newProductPage/SixImgThree.svg",
      title: "PINN-Optimized Tracking & Fault Prediction",
      description:
        "Leverage advanced AI and Machine Learning with the PINN-enhanced Controller for exceptional panel alignment and real-time fault prediction. The system seamlessly integrates comprehensive load monitoring and also features adaptive tracking modes that adjust to dynamic conditions. This ensures optimal performance, precise energy capture, and proactive resolution of potential issues before they impact your system.",
    },
  ];

  const keyItems = [
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/high_one.svg",
      title: "Optimized Capture",
      description:
        "Cutting-edge AI fine-tunes panel positioning for maximum solar energy absorption",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/high_two.svg",
      title: "Adaptive Control",
      description:
        "Versatile tracking modes enable dynamic adjustments and precise manual management",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/high_three.svg",
      title: "Efficient Flexibility",
      description:
        "Intelligent tracking adapts to diverse environmental and site conditions",
    },
  ];
  const featureData = [
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/feature_one.svg",
      description:
        "Patented dual polymer-layer bearings for smooth load transfer and enhanced capacity",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/feature_two.svg",
      description:
        "Customizable design supporting 30–80 modules with adjustable array heights",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/feature_three.svg",
      description: "±60° tracking range from zenith with ±0.5° precision",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/feature_four.svg",
      description:
        "Constructed with 350 Heavy YST 310 grade galvanized steel for durability and a 20-year warranty",
    },
    {
      keyImage:
        "https://s3.amazonaws.com/smarttrak.co/newHomePage/feature_five.svg",
      description:
        "Integrated 3D Digital Twin visualization and AI fault prediction for advanced monitoring",
    },
  ];
  const needs = [
    {
      title: "Main Powered",
      description:
        "Dependable, high-performance tracking for grid-connected systems",
    },
    {
      title: "Self Powered",
      description:
        " Independent operation with integrated solar charging and backup battery",
    },
  ];
  const mainPower = [
    {
      title: "Power Supply",
      description: [
        "Input Voltage: 120 ~ 373VDC or 85 ~ 264VAC",
        "Daily energy consumption optimized for low power usage",
      ],
    },
    {
      title: "Tracking and Control",
      description: [
        "±60° tracking range from zenith with ±0.5° accuracy",
        "AI-based forecasting for power stowing",
        "Multiple tracking modes: Auto, Sun Track Start, Manual, Stop",
      ],
    },
    {
      title: "Materials and Compliance",
      description: [
        "Hot-dip galvanized steel construction (350 Heavy YST 310 grade)",
        "Weatherproof and corrosion-resistant materials tested from -20°C to 100°C ",
      ],
    },
    {
      title: "Safety and Reliability",
      description: [
        "Wind withstanding capacity up to 160 mph",
        "20-year warranty on the slewing drive",
      ],
    },
  ];
  const selfPower = [
    {
      title: "Power Supply",
      description: [
        "Solar charger with MPPT for optimized power utilizationInput Voltage: 120 ~ 373VDC or 85 ~ 264VAC",
        "Backup Battery: 24V, 8Ah (customizable)",
        "System Voltage: Solar panel 0-56V (customizable)",
      ],
    },
    {
      title: "Tracking and Control",
      description: [
        "±60° tracking range from zenith with ±0.5° accuracy",
        "AI-based forecasting for power stowing",
        "Multiple tracking modes: Auto, Sun Track Start, Manual, Stop",
      ],
    },
    {
      title: "Materials and Compliance",
      description: [
        "Same robust material design as the Main Powered Controller ",
      ],
    },
    {
      title: "Safety and Reliability",
      description: ["Wind withstanding capacity up to 160 mph"],
    },
  ];

  const overControls = useAnimation();
  const whatControls = useAnimation();
  const craftControls = useAnimation();
  const resControls = useAnimation();
  const resTwoControls = useAnimation();
  const history = useHistory();

  const [overRef, inViewOver] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [whatRef, inViewWhat] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  const [craftRef, inViewCraft] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [resRef, inViewRes] = useInView({ triggerOnce: false, threshold: 0.1 });
  const [resTwoRef, inViewTwoRes] = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  useEffect(() => {
    overControls.start({
      opacity: inViewOver ? 1 : 0,
      y: inViewOver ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [overControls, inViewOver]);
  useEffect(() => {
    whatControls.start({
      opacity: inViewWhat ? 1 : 0,
      y: inViewWhat ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [whatControls, inViewWhat]);
  useEffect(() => {
    craftControls.start({
      opacity: inViewCraft ? 1 : 0,
      y: inViewCraft ? 0 : 50,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [craftControls, inViewCraft]);
  useEffect(() => {
    resControls.start({
      opacity: inViewRes ? 1 : 0,
      y: inViewRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resControls, inViewRes]);
  useEffect(() => {
    resTwoControls.start({
      opacity: inViewTwoRes ? 1 : 0,
      y: inViewTwoRes ? 0 : 20,
      transition: { duration: 0.8, ease: "easeOut" },
    });
  }, [resTwoControls, inViewTwoRes]);

  const isMobileOne = window.innerWidth <= 900;
  const handleContact = () => {
    history.push("/about-us#contact-us");
  };
  return (
    <div className="landing-container bg_home">
      <div className="navbar">
        <Navbar />
      </div>

      <div
        className="expert_div gap_1vw flex_col our_solution_main text_cen"
        id="our_sol"
      >
        <div
          className="expert_div flex_crct gap_1vw justify_space"
          id="our_sol"
        >
          <div className="expert_div width_40 flex_col gap_1vw align_item">
            <p className="Raleway_48px_600 c_thirdBlack" id="text_left">
              Smarttrak AI Single-Axis Tracker
            </p>
            <p className="Raleway_26px_500" id="text_left">
              Boosting Solar Energy Capture with AI-Driven Precision and
              Adaptive Control
            </p>
            <Button
              type="submit"
              variant="contained"
              className="gap_1vw foo_one demo_btn"
              style={{
                padding: "1vh 1.5vw !important",
                fontSize: "1.1vw !important",
                borderRadius: "0.6vw !important",
              }}
              onClick={handleContact}
            >
              Request a Demo
              <img
                src="https://s3.amazonaws.com/smarttrak.co/newHomePage/arrowDemo.svg"
                alt="Tracker"
              />
            </Button>
            <Divider
              className="width_90 margin_top_1vw"
              style={{
                border: "0.1px solid #FDA737",
              }}
            />
          </div>

          <div className="expert_div gap_1vw  width_60" id="our_sol">
            <div className="expert_div">
              <img
                className="div_width border_five"
                src="https://s3.amazonaws.com/smarttrak.co/newHomePage/trackOne.jpg"
                alt="Tracker"
              />
            </div>
            <div className="expert_div">
              <img
                className="div_width border_five"
                src="https://s3.amazonaws.com/smarttrak.co/newHomePage/trackTwo.jpg"
                alt="Tracker"
              />
            </div>

           
          </div>
        </div>

        <motion.div
          className="expert_div flex_col gap_1vw"
          id="our_sol"
          ref={overRef}
          initial={{ opacity: 0, y: 50 }}
          animate={overControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Key Highlights</p>
          </div>

          <Grid
            container
            className="grid-container gap_1vw highlight_box"
            id="our_sol"
          >
            {keyItems.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={3.8}
                xs={12}
                sm={12}
                md={3.8}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p gap_1vw"
                  style={{ flex: 1 }}
                >
                  <div className="expert_div align_self_center margin_top_1vw">
                    <img
                      src={ele.keyImage}
                      alt="why choose"
                      className="width_60_m"
                    />
                  </div>
                  <div className="expert_div flex_col gap_1vw text_cen div_width">
                    <p className="Raleway_28px  c_lightdrak">{ele.title}</p>

                    <p
                      className="Raleway_26px_500 opacity_8 text_c"
                      style={{ height: isMobileOne ? "auto" : "8vw" }}
                    >
                      {ele.description}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>

        <motion.div
          className="expert_div flex_col gap_1vw"
          id="our_sol"
          ref={whatRef}
          initial={{ opacity: 0, y: 50 }}
          animate={whatControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">Features Overview</p>
          </div>

          <Grid container className="grid-container gap_1vw" id="our_sol">
            {featureData.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={index === 0 || index === 1 ? 5.9 : 3.8}
                xs={12}
                sm={12}
                md={3.8}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p gap_1vw"
                  id="valData_h"
                  style={{ flex: 1 }}
                >
                  <div className="expert_div align_self_center margin_top_1vw">
                    <img
                      src={ele.keyImage}
                      alt="why choose"
                      className="width_60_m"
                    />
                  </div>

                  <p
                    id="text_just"
                    className="Raleway_26px_500 opacity_8 text_c"
                    style={{ height: isMobileOne ? "auto" : "7vw" }}
                  >
                    {ele.description}
                  </p>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>

        <motion.div
          className="cards_container expert_div"
          ref={craftRef}
          initial={{ opacity: 0, y: 50 }}
          animate={craftControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">
              Two Tracker Controllers, Tailored for Your Needs
            </p>
            <p className="Raleway_26px_500 opacity_8 text_cen">
              Whether you need grid-powered reliability or standalone solar
              solutions, our Main Powered and Self Powered Tracker Controllers
              offer unmatched flexibility and performance.
            </p>
          </div>

          <Grid container className="home_grid_container gap_5vw" id="our_sol">
            {needs.map((ele, index) => (
              <Grid
                key={index}
                item
                lg={4}
                xs={12}
                sm={12}
                md={3.8}
                className="abour_mission_grid"
              >
                <Paper
                  className="about_mission cursor_p gap_1vw"
                  id="valData_d"
                  style={{ flex: 1 }}
                >
                  <div className="expert_div flex_col gap_1vw text_cen div_width">
                    <p className="Raleway_28px  c_lightdrak">{ele.title}</p>

                    <p
                      className="Raleway_26px_500 opacity_8"
                      style={{ height: isMobileOne ? "auto" : "7vw" }}
                    >
                      {ele.description}
                    </p>
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </motion.div>

        <motion.div
          className="expert_div flex_col gap_1vw text_cen div_width core_top"
          id="our_sol"
          ref={resRef}
          initial={{ opacity: 0, y: 20 }}
          animate={resControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">
              Main Powered Tracker Controller: Engineered for Precision
            </p>
          </div>
          <Grid container className="home_grid_container gap_1vw padd_1vw">
            <Grid
              item
              lg={7}
              xs={12}
              sm={12}
              md={7}
              className="abour_mission_grid"
            >
              <Paper
                className="about_mission cursor_p"
                id="shadow_blue"
                style={{ flex: 1 }}
              >
                <div className="expert_div align_self_center padd_1vw">
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/newHomePage/main_power.png"
                    alt="main power"
                    className="width_60 padd_1vw"
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
          <div className="expert_div flex_col gap_1vw">
            <p className="Raleway_26px_500 opacity_8 text_cen">
              Delivering unmatched precision and reliability for grid-connected
              solar systems, the Main Powered Tracker Controller ensures optimal
              energy capture with advanced AI-based control, robust materials,
              and efficient performance. Designed for durability, it withstands
              extreme conditions and offers a seamless tracking experience
            </p>
          </div>
          <Grid container className="grid-container" id="our_sol">
            <Grid
              item
              lg={8}
              xs={12}
              sm={12}
              md={8}
              className="abour_mission_grid"
            >
              <Grid container className="grid-container gap_1vw">
                {mainPower.map((ele, index) => (
                  <Grid
                    key={index}
                    item
                    lg={5.8}
                    xs={12}
                    sm={12}
                    md={5.8}
                    className="abour_mission_grid"
                  >
                    <Paper
                      className="notification-paper cursor_p gap_1vw"
                      id="valData_d"
                      style={{ flex: 1 }}
                    >
                      <div className="expert_div flex_col gap_1vw div_width">
                        <div className="expert_div bg_blues">
                          <p className="Poppins_24px_rem">0{index + 1}</p>
                        </div>

                        <div>
                          <p className="Raleway_28px c_lightdrak text_cen">
                            {ele.title}
                          </p>
                        </div>

                        <div className="expert_div align_item flex_col gap_1vw div_width">
                          {ele.description.map((descItem, descIndex) => (
                            <div
                              className="expert_div gap_1vw align_item"
                              key={descIndex}
                            >
                              <img
                                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/triangle_svg.svg"
                                alt="why choose"
                              />
                              <p
                                className="Raleway_26px_500 opacity_8"
                                id="text_left"
                              >
                                {descItem}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              lg={3.9}
              xs={12}
              sm={12}
              md={3.8}
              className="abour_mission_grid"
            >
              <Paper
                className="notification-paper flex_col  cursor_p gap_2vw"
                style={{ flex: 1 }}
                id="valData_h"
              >
                <div className="expert_div width_20" id="our_sol">
                  <Divider
                    className="div_width"
                    id="our_sol"
                    style={{
                      border: "0.1px solid #EE0000",
                    }}
                  />
                </div>
                <div className="expert_div flex_col gap_2vw">
                  <p className="Raleway_48px_600 c_thirdBlack font_700">
                    Additional Features
                  </p>
                </div>
                <div className="expert_div align_item flex_col gap_2vw">
                  <div id="border-b">
                    <img
                      src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/add_main_one.svg"
                      alt="why choose"
                      className="width_20"
                    />
                    <p className="Raleway_26px_500" id="text_left">
                      Integrated Wi-Fi mesh for remote monitoring
                    </p>
                  </div>
                  <div id="border-b">
                    <img
                      src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/add_main_two.svg"
                      alt="why choose"
                      className="width_20"
                    />
                    <p className="Raleway_26px_500" id="text_left">
                      Advanced accelerometer systems for precise panel
                      positioning
                    </p>
                  </div>
                </div>
                <div className="expert_div div_width" id="our_sol">
                  <Divider
                    className="margin_top_1vw div_width"
                    style={{
                      border: "0.1px solid #FFB936",
                    }}
                  />
                </div>
                <div className="expert_div" id="our_sol">
                  <Button
                    type="submit"
                    variant="contained"
                    className="gap_1vw foo_one demo_btn"
                    style={{
                      padding: "1vh 1.5vw !important",
                      fontSize: "1.1vw !important",
                      borderRadius: "0.6vw !important",
                    }}
                  >
                    <a
                      className="privacy_link_foo c_white"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/SmarttrakAI_Solar_Tracker_Controller.pdf" // Replace with the correct path to your PDF
                      download="Datasheet.pdf"
                    >
                      Download Datasheet
                    </a>
                  </Button>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </motion.div>
        <motion.div
          className="expert_div flex_col gap_1vw text_cen div_width core_top"
          id="our_sol"
          ref={resTwoRef}
          initial={{ opacity: 0, y: 20 }}
          animate={resTwoControls}
          transition={{ duration: 0.5 }}
        >
          <div className="expert_div flex_col gap_1vw">
            <p className="Raleway_48px_600 c_thirdBlack">
              Self Powered Tracker Controller: Independent and Efficient
            </p>
          </div>
          <Grid container className="home_grid_container gap_1vw padd_1vw">
            <Grid
              item
              lg={7}
              xs={12}
              sm={12}
              md={7}
              className="abour_mission_grid"
            >
              <Paper
                className="about_mission cursor_p"
                id="shadow_blue"
                style={{ flex: 1 }}
              >
                <div className="expert_div align_self_center padd_1vw">
                  <img
                    src="https://s3.amazonaws.com/smarttrak.co/newHomePage/self_power.png"
                    alt="main power"
                    className="width_60 padd_1vw"
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
          <div className="expert_div flex_col gap_1vw">
            <p className="Raleway_26px_500 opacity_8 text_cen">
              Perfect for off-grid or remote locations, the Self Powered Tracker
              Controller operates independently with solar charging and backup
              Featuring AI-driven battery integration. tracking and stowing, it
              adapts efficiently to diverse environmental conditions, ensuring
              sustainable and uninterrupted energy optimization
            </p>
          </div>
          <Grid container className="grid-container" id="our_sol">
            <Grid
              item
              lg={3.9}
              xs={12}
              sm={12}
              md={3.8}
              className="abour_mission_grid"
            >
              <Paper
                className="notification-paper flex_col  cursor_p gap_2vw"
                style={{ flex: 1 }}
                id="valData_h"
              >
                <div className="expert_div width_20" id="our_sol">
                  <Divider
                    className="div_width"
                    id="our_sol"
                    style={{
                      border: "0.1px solid #EE0000",
                    }}
                  />
                </div>
                <div className="expert_div flex_col gap_2vw">
                  <p className="Raleway_48px_600 c_thirdBlack font_700">
                    Additional Features
                  </p>
                </div>
                <div className="expert_div align_item flex_col gap_2vw">
                  <div id="border-b">
                    <img
                      src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/add_self_one.svg"
                      alt="why choose"
                      className="width_20"
                    />
                    <p className="Raleway_26px_500" id="text_left">
                      Integrated 3D Digital Twin visualization and AI fault
                      prediction
                    </p>
                  </div>
                </div>
                <div className="expert_div div_width" id="our_sol">
                  <Divider
                    className="margin_top_1vw div_width"
                    style={{
                      border: "0.1px solid #FFB936",
                    }}
                  />
                </div>
                <div className="expert_div" id="our_sol">
                  <Button
                    type="submit"
                    variant="contained"
                    className="gap_1vw foo_one demo_btn"
                    style={{
                      padding: "1vh 1.5vw !important",
                      fontSize: "1.1vw !important",
                      borderRadius: "0.6vw !important",
                    }}
                  >
                    <a
                      className="privacy_link_foo c_white"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/SmarttrakAI_Solar_Tracker_Controller.pdf" // Replace with the correct path to your PDF
                      download="Datasheet.pdf"
                    >
                      Download Datasheet
                    </a>
                  </Button>
                </div>
              </Paper>
            </Grid>
            <Grid
              item
              lg={8}
              xs={12}
              sm={12}
              md={8}
              className="abour_mission_grid"
            >
              <Grid container className="grid-container gap_1vw">
                {selfPower.map((ele, index) => (
                  <Grid
                    key={index}
                    item
                    lg={5.8}
                    xs={12}
                    sm={12}
                    md={5.8}
                    className="abour_mission_grid"
                  >
                    <Paper
                      className="notification-paper cursor_p gap_1vw"
                      id="valData_d"
                      style={{ flex: 1 }}
                    >
                      <div className="expert_div flex_col gap_1vw div_width">
                        <div className="expert_div bg_blues">
                          <p className="Poppins_24px_rem">0{index + 1}</p>
                        </div>

                        <div>
                          <p className="Raleway_28px c_lightdrak text_cen">
                            {ele.title}
                          </p>
                        </div>

                        <div className="expert_div align_item flex_col gap_1vw div_width">
                          {ele.description.map((descItem, descIndex) => (
                            <div
                              className="expert_div gap_1vw align_item"
                              key={descIndex}
                            >
                              <img
                                src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/triangle_svg.svg"
                                alt="why choose"
                              />
                              <p
                                className="Raleway_26px_500 opacity_8"
                                id="text_left"
                              >
                                {descItem}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </motion.div>

        <div className="expert_div flex_col gap_1vw" id="margin_tb">
          <p className="Raleway_48px_600 c_thirdBlack">
            Ready to Optimize Your Solar Energy Capture?
          </p>
          <Button
            type="submit"
            variant="contained"
            className="gap_1vw foo_one demo_btn"
            style={{
              padding: "1vh 1.5vw !important",
              fontSize: "1.1vw !important",
              borderRadius: "0.6vw !important",
            }}
            onClick={handleContact}
          >
            Request a Demo
            <img
              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/arrowDemo.svg"
              alt="Tracker"
            />
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SolarTracker;
