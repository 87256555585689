import React, { useState } from 'react';

const ProgressRect = () => {
  const [percentage, setPercentage] = useState(75); // Initial fill percentage
  const [hoverValue, setHoverValue] = useState(null); // Hovered value display

  const rectHeight = 117; // The total height of the rect
  const rectWidth = 350; // The total width of the rect
  const fillWidth = (percentage / 100) * rectWidth; // Calculate filled width based on percentage

  return (
    <div className='expert_div flex_col  gap_1vw' id="battery_width" >
      
      <div className='expert_div gap_1vw' style={{ position: 'relative', width: "100%",height: "100%" }}>
        <svg
        width={rectWidth}
        height={rectHeight}
        viewBox={`0 0 ${rectWidth} ${rectHeight}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Background rectangle */}
        <rect
          x="0"
          y="0"
          width={rectWidth}
          height={rectHeight}
          rx="20"
          stroke="#EDEDF2"
          fill="none"
        />
        {/* Filled rectangle */}
        <rect
          x="0"
          y="0"
          width={fillWidth}
          height={rectHeight}
            fill="#FDA737"
          style={{
            clipPath:
              percentage === 100
                ? "none" // No clipping for 100% (rounded corners on both sides)
                : `inset(0px -2px 0px 0px round 20px 0px 0px 20px)`, // Left rounded, right sharp
          }}
          stroke={percentage === 100 ? "#FDA737" : "none"} // Border only for 100%
          strokeWidth="2"
        />
      </svg>
      <div className='expert_div battery_front'>

      
      </div>
      <div className='progress_battey'
       style={{
        left: `${(fillWidth / rectWidth) * 100}%`, 
      }}
        >
          {percentage}%
        </div>
      </div>
      <div  className="div_width expert_div">
      <img
             className="div_width"
              src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newProductPage/scaleBatt.svg"
              alt="progres  barscale"
            />
            </div>
      {/* Percentage display on hover */}
  
       
      {/* <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHoverValue(percentage)}
        onMouseLeave={() => setHoverValue(null)}
      ></div> */}
    </div>
  );
};

export default ProgressRect;
