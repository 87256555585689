import React, { useState, useEffect ,useRef} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useHistory } from "react-router-dom";

import Select from "react-select";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/system";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import Divider from "@mui/material/Divider";
import TableSortLabel from "@mui/material/TableSortLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import useErrorHandler from "../../../utils/errorHandler";

const invService = new CosmicInverterService();
const handleFetchError = useErrorHandler;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: "#FAFAFA",
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1849D6",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));


function ImageUpload() {
  const [loadingState, setLoadingState] = useState({
    chatData: false,
    getList: false,
    uploadFile: false,
    pdfView: false,
  });
 

  const [folderList, setFolderList] = useState([]);
  const [cancelUpload, setCancelUpload] = useState(false);

  const [visibleItems, setVisibleItems] = useState(6);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [detailIn, setDetailIn] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorFol, setAnchorFol] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [severityType, setSeverityType] = useState("Critical");
  const [severityValue, setSeverityValue] = useState(["Critical","Moderate","Minor"]); //device type option
 
  const [isDefectType, setIsDefectType] = useState("Microcracks");
  const [defectTypeValue, setDefectTypeValue] = useState(["Microcracks","Edge Chipping","Surface Contamination","Delamination","Discoloration","Hot Spots"]); //device type option
  const [uploadProgress, setUploadProgress] = useState(0); // For progress bar
const [filesCount, setFilesCount] = useState({ uploaded: 0, total: 0 });

  const [folderContents, setFolderContents] = useState([
    {
      name: "image1",
      path: "/images/image1",
      size: "2kb",
      type: "png",
      count: 20,
      defect: "18%",
      pass: 12,
      fail: 10,
      commondefect: "Microcracks",
    },
    {
      name: "image2",
      path: "/images/image2",
      size: "2kb",
      type: "jpg",
      count: 22,
      defect: "19%",
      pass: 13,
      fail: 9,
      commondefect: "Microcracks",
    },
    {
      name: "image3",
      path: "/images/image2",
      size: "2kb",
      type: "jpg",
      count: 24,
      defect: "20%",
      pass: 15,
      fail: 10,
      commondefect: "Microcracks",
    },
    {
      name: "image4",
      path: "/images/image2",
      size: "2kb",
      type: "jpg",
      count: 26,
      defect: "21%",
      pass: 10,
      fail: 2,
      commondefect: "Microcracks",
    },
  ]);
  const [searchTerm, setSearchTerm] = useState("");

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const allowedExtensions = [".png", ".jpg", ".jpeg"];
  const openPopover = Boolean(anchorEl);
  const abortControllers = useRef([]);
  const { getInputProps: getFileInputProps } = useDropzone({
    multiple: true,
    accept: allowedExtensions.join(", "),
    onDropRejected: () => {
      setErrorMessage("Only PNG, JPG, and JPEG files are allowed.");
    },
    onDropAccepted: async (acceptedFiles) => {
      setErrorMessage(""); // Clear any error messages
      setUploadProgress(0);
      setCancelUpload(false);
      setFilesCount({ uploaded: 0, total: acceptedFiles.length });
      try {
        // Upload all files one by one
        await uploadAllFiles(acceptedFiles);
  
      } catch (error) {
        console.error("Error during the upload process:", error);
      }
    },
  });

  const uploadAllFiles = async (files) => {
    
    abortControllers.current = files.map(() => new AbortController()); // Create controllers for each file
  
    for (let i = 0; i < files.length; i++) {
      if (cancelUpload) {
        console.log("Upload canceled.");
        return; // Exit loop if canceled
      }
  
      await handleUploadFile(files[i], i, files.length, abortControllers.current[i]);
    }
  
    if (!cancelUpload) {
      await handleFolComplete(); // Only call if not canceled
    }
  };
  
  const allowedMimeTypes = ["image/png", "image/jpeg", "image/jpg"];

const { getInputProps: getFolderInputProps } = useDropzone({
  noClick: true, // Prevents the default file picker dialog
  noKeyboard: true,
  multiple: true, // Allow multiple files from a folder
  directory: true, // Enable folder selection
  onDropAccepted: async (acceptedFiles) => {
    // Filter files to include only allowed MIME types
    const validFiles = acceptedFiles.filter((file) =>
      allowedMimeTypes.includes(file.type)
    );
    setUploadProgress(0);
    setCancelUpload(false);
    setFilesCount({ uploaded: 0, total: validFiles.length });
    if (validFiles.length === 0) {
        enqueueSnackbar("No valid files to upload. Please upload valid PNG, JPG, or JPEG files.", {
          variant: "warning",
        });
        
      setFolderContents([]); // Clear folder contents on error
      return;
    }

    setErrorMessage(""); // Clear any previous error messages

    // Update folder contents with valid files
    setFolderContents(
      validFiles.map((file) => ({
        name: file.name,
        path: file.webkitRelativePath || file.path,
        size: file.size,
        type: file.type,
        commondefect:"microCrack"
      }))
    );

    try {
      // Upload all valid files
      await uploadAllFiles(validFiles);
    } catch (error) {
      console.error("Error during the upload process:", error);
    }
  },
  onDropRejected: () => {
    setFolderContents([]);
    setErrorMessage("Only valid folders containing PNG, JPG, or JPEG files are allowed.");
  },
});


  useEffect(() => {
    handleFolList();
  }, []);
  const readDirectory = (directory) => {
    return new Promise((resolve) => {
      const reader = directory.createReader();
      const files = [];

      const readEntries = () => {
        reader.readEntries((entries) => {
          if (entries.length === 0) {
            resolve(files);
            return;
          }
          entries.forEach((entry) => {
            if (entry.isFile) {
              entry.file((file) => files.push(file)); // Add file to list
            } else if (entry.isDirectory) {
              readDirectory(entry).then((nestedFiles) =>
                files.push(...nestedFiles)
              ); // Recurse for subdirectories
            }
          });
          readEntries();
        });
      };

      readEntries();
    });
  };
  const handleViewMore = () => {
    setVisibleItems((prev) => prev + 6);
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleCancel = () => {
    setUploadProgress(0);
    setFilesCount({ uploaded: 0, total: 0 });
    setCancelUpload(true); 

    abortControllers.current.forEach((controller) => controller.abort());
    abortControllers.current = []; 
  };
  const handleUploadFile = async (file,index, totalFiles,controller) => {
   
    const formData = new FormData();
    formData.append("file", file); // Append the file
    const today = new Date().toISOString().slice(0, 10);
    formData.append("report_name", today);
    try {
     
      setLoadingState((prevState) => ({ ...prevState, uploadFile: true }));
      const res = await invService.postImgFileUpload(formData, {
        signal: controller.signal, // Pass the AbortController signal
      });
    
      if (res.status === 200) {
        // setSelectedFile(res.data);
        setUploadProgress(((index + 1) / totalFiles) * 100); 
        setFilesCount((prev) => ({ ...prev, uploaded: index + 1 }));
        enqueueSnackbar(`File ${index + 1} of ${totalFiles} uploaded successfully`, {
          variant: "success",
        });
      } else {
        enqueueSnackbar(`File ${index + 1} upload failed. Please try again.`, {
          variant: "warning",
        });
      }
    } catch (error) {
    setFilesCount({ uploaded: 0, total: 0 });

      console.error("Upload error:", error);
    
      handleFetchError(error, "An error occurred during the upload.");

    } finally {
      setLoadingState((prevState) => ({ ...prevState, uploadFile: false }));
    }
  };
  const handleFolList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, getList: true }));

      const res = await invService.getListFol();
      if (res.status === 200) {
        setFolderList(res.data);
      }
      setLoadingState((prevState) => ({ ...prevState, getList: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, getList: false }));
      handleFetchError(error, "image list");
    }
  };
  const handleFolComplete = async () => {
    const formData = new FormData();

    const today = new Date().toISOString().slice(0, 10);
    formData.append("report_name", today);
    try {
      setLoadingState((prevState) => ({ ...prevState, getList: true })); // Indicate loading state

      const res = await invService.getAllUpload(formData);
      if (res.status === 200) {
        setFilesCount(0)

        // Process the fetched data here
        // setFolderList(res.data); // Uncomment and set state as needed
      }
    } catch (error) {
      handleFetchError(error, "image list"); // Ensure handleFetchError is implemented correctly
    } finally {
      setLoadingState((prevState) => ({ ...prevState, getList: false })); // Reset loading state
    }
  };
  const handleShow = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleHistoryDotsClick = (event, index) => {
    setAnchorFol(event.currentTarget);
    setSelectedIndex(index);
  };
  const handlePopoverCloseFol = () => {
    setAnchorFol(null);
    setSelectedIndex(null); // Reset the selected index
  };
  const handleImg = (row,index) => {
    setShowDetail(true);
    setDetailIn(index)
  };
  const handleDetails = (ele,index) => {
          history.push({
            pathname: `/iot-home/img-upload/detail-analysis`,
            state: {
              eleData: ele,
              index: index,
            },
          });
    
  };
  const columns = [
    { field: "name", title: "File Name" },
    { field: "size", title: "File Size" },

    { field: "date", title: "Date" },
    { field: "defect", title: "Defect Type" },
    { field: "severity", title: "Defect Severity" },

    { field: "download", title: "Download" },
  ];
  const columnList = [
    { field: "report_name", title: "File Name" },
    { field: "total_files", title: "Count" },

    { field: "percentage_defective", title: "Defect Rate in %" },
    { field: "Detailed_Analysis", title: "Detailed Analysis" },

    { field: "download", title: "                " },
  ];
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
      : (a, b) => ascendingComparator(a[orderBy], b[orderBy]);
  };

  const ascendingComparator = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const descendingComparator = (a, b) => {
    if (b < a) return -1;
    if (b > a) return 1;
    return 0;
  };
  const filteredPdfList = folderContents.filter((ele) =>
    ele.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredFolList = folderList.filter((ele) =>
    ele.report_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleChangeDevice = (event) => {
    setIsDefectType(event.value.toLowerCase());
  };
  const handleChangeSeveity = (event) => {
    setSeverityType(event.value.toLowerCase());
  };
  const handleBack = () => {
    setShowDetail(false);
  };

  return (
    <div className="home-container">
      <Grid
        container
        className="file-container gap_2vw"
        style={{ height: "100%" }}
      >
        {folderList.length === 0 ? (
          <Grid item lg={12} xs={12} sm={12} md={12} className="energy-grid">
            <Paper
              className="energy-grid flex_col gap_1vw"
              id="padding_4"
              style={{
                position: "relative",
              }}
            >
              {loadingState.uploadFile && (
                <SimpleBackdrop open={loadingState.uploadFile} />
              )}
              <div className="expert_div flex_col gap_1vw ">
                <p
                  className="Poppins_20px_rem font_500 c_black text_cen"
                >
                  Select a file or folder to be uploaded
                </p>
                <p className="search_Text c_mixGray text_cen">
                  Select relevant file or folder
                </p>
              </div>

              <div className="expert_div flex_col gap_1vw svg_upload">
                <img
                  src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/upload.svg"
                  alt="file upload"
                />
                <p className="Poppins_20px_rem c_black font_400 text_cen">
                  Drag & drop Images or <span className="c_blue">Browse</span>
                </p>
                <p className="search_Text c_mixGray text_cen">
                  {" "}
                  Select PNG, JPG/JPEG
                </p>

                <Popover
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "left",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    className: "customPopoverPaper-p",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handlePopoverClose();
                      document.getElementById("file-input").click();
                    }}
                    className="foo_one gap_1vw"
                  >
                    <p className="Poppins_14px_regular">Image Upload</p>
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/newHomePage/image_upload.svg"
                      alt="image_upload"
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handlePopoverClose();
                      document.getElementById("folder-input").click();
                    }}
                    className="foo_one gap_1vw"
                  >
                    <p className="Poppins_14px_regular">Folder Upload</p>
                    <img
                      src="https://s3.amazonaws.com/smarttrak.co/newHomePage/folder_upload.svg"
                      alt="folder_upload"
                    />
                  </MenuItem>
                </Popover>

                <input
                  id="file-input"
                  {...getFileInputProps({
                    accept: ".jpg, .jpeg, .png", // Restrict visible file types
                  })}
                  directory="true"
                />

                <input
                  id="folder-input"
                  {...getFolderInputProps()}
                  webkitdirectory="true"
                  directory="true"
                />
                <Button
                  type="submit"
                  variant="contained"
                  className="file-upload"
                  onClick={handleShow}
                >
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.83 1.82878C3.99591 1.49677 4.25093 1.21747 4.56652 1.02215C4.88212 0.826818 5.24585 0.723162 5.617 0.722778H14.381C14.7525 0.722791 15.1166 0.826272 15.4326 1.02162C15.7486 1.21697 16.0039 1.49646 16.17 1.82878L19.682 8.85378C19.8905 9.26974 19.9993 9.72849 20 10.1938V15.7228C20 16.2532 19.7893 16.7619 19.4142 17.137C19.0391 17.5121 18.5304 17.7228 18 17.7228H2C1.46957 17.7228 0.960859 17.5121 0.585786 17.137C0.210714 16.7619 0 16.2532 0 15.7228V10.1948C0.000186879 9.72924 0.108716 9.27013 0.317 8.85378L3.83 1.82878ZM14.381 2.72278H5.618L2.12 9.72278H5.5C5.89782 9.72278 6.27936 9.88081 6.56066 10.1621C6.84196 10.4434 7 10.825 7 11.2228V12.2228C7 12.3554 7.05268 12.4826 7.14645 12.5763C7.24021 12.6701 7.36739 12.7228 7.5 12.7228H12.5C12.6326 12.7228 12.7598 12.6701 12.8536 12.5763C12.9473 12.4826 13 12.3554 13 12.2228V11.2228C13 10.825 13.158 10.4434 13.4393 10.1621C13.7206 9.88081 14.1022 9.72278 14.5 9.72278H17.88L14.381 2.72278Z"
                      fill="#98A0B4"
                    />
                  </svg>
                </Button>
                {/* <p className="Poppins_20px_rem c_black font_400 text_cen" >
                  <span className="c_blue">Click here</span> to upload or drop
                  Images here
                </p> */}
              </div>
             
            </Paper>
          </Grid>
        ) : (
          <>
            {showDetail && (
              <Grid
                item
                lg={11}
                xs={12}
                sm={12}
                md={12}
                className="exc-graph-paper"
              >
                <Paper
                  className="powerplant-sec-paper gap_5vw expert_div padding_top"
                  style={{ justifyContent: "flex-Start" }}
                >
                  <div className="expert_div cursor_p ">
                    <KeyboardBackspaceIcon onClick={handleBack} />
                  </div>

                  <div className="search-box r_border" id="search_pad">
                    <input
                      className="semibold-smalltext"
                      id="search-input"
                      value={searchTerm}
                      onChange={handleSearch}
                      type="text"
                      placeholder="Search Files Here..."
                    />
                    <div className="expert_div search_case_pdf r_border">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                      >
                        <path
                          d="M19.1129 17.5178L17.4596 15.9617M10.4329 16.7398C11.4642 16.7398 12.4855 16.5486 13.4383 16.1771C14.3911 15.8057 15.2568 15.2612 15.9861 14.5749C16.7153 13.8885 17.2938 13.0737 17.6885 12.177C18.0831 11.2802 18.2863 10.3191 18.2863 9.3484C18.2863 8.37775 18.0831 7.41661 17.6885 6.51985C17.2938 5.62308 16.7153 4.80827 15.9861 4.12191C15.2568 3.43556 14.3911 2.89112 13.4383 2.51967C12.4855 2.14821 11.4642 1.95703 10.4329 1.95703C8.35009 1.95703 6.35256 2.73576 4.87978 4.12191C3.40699 5.50807 2.57959 7.38809 2.57959 9.3484C2.57959 11.3087 3.40699 13.1887 4.87978 14.5749C6.35256 15.961 8.35009 16.7398 10.4329 16.7398V16.7398Z"
                          stroke="black"
                          stroke-width="1.25967"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </Paper>
              </Grid>
            )}

            {!showDetail ? (
              <Grid
                item
                lg={11}
                xs={12}
                sm={12}
                md={12}
                className="powerplant-sec-paper align_item padding_top"
              >
                <Paper className="exc-graph-paper gap_1vw foo_one flex_col">
                  <div className="foo_one div_width padding_sides">
                    <div>
                      <p className="Poppins_24px_black  font_500 ">
                        Content Overview
                      </p>
                    </div>
                    <div>
                      <Popover
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "left",
                          horizontal: "right",
                        }}
                        PaperProps={{
                          className: "customPopoverPaper-p",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handlePopoverClose();
                            document.getElementById("file-input").click();
                          }}
                          className="foo_one gap_1vw"
                        >
                          <p className="Poppins_14px_regular">Image Upload</p>
                          <img
                            src="https://s3.amazonaws.com/smarttrak.co/newHomePage/image_upload.svg"
                            alt="image_upload"
                          />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handlePopoverClose();
                            document.getElementById("folder-input").click();
                          }}
                          className="foo_one gap_1vw"
                        >
                          <p className="Poppins_14px_regular">Folder Upload</p>
                          <img
                            src="https://s3.amazonaws.com/smarttrak.co/newHomePage/folder_upload.svg"
                            alt="folder_upload"
                          />
                        </MenuItem>
                      </Popover>

                      <input
                        id="file-input"
                        {...getFileInputProps({
                          accept: ".jpg, .jpeg, .png", // Restrict visible file types
                        })}
                        directory="true"
                      />

                      <input
                        id="folder-input"
                        {...getFolderInputProps()}
                        webkitdirectory="true"
                        directory="true"
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        className="image_btn"
                        onClick={handleShow}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.9418 19.5238V11.0582H0.476196V8.9418H8.9418V0.476196H11.0582V8.9418H19.5238V11.0582H11.0582V19.5238H8.9418Z"
                            fill="#1C1B1F"
                          />
                        </svg>
                      </Button>
                    </div>
                  </div>
                  <TableContainer className="scroll_pdf table-scrollbar-pdf">
                    {folderList.length > 0 ? (
                      <div className="abt_table_scrol">
                        {folderList.length === 0 ? (
                          <Table
                            stickyHeader
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderBottom: "0rem solid gray !important",
                                }}
                                colSpan={15}
                                align="center"
                                className="div-borderText"
                              >
                                No data available
                              </TableCell>
                            </TableRow>
                            <Divider style={{ border: "1px solid #D9D9D9" }} />
                          </Table>
                        ) : (
                          <Table
                            stickyHeader
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                {columnList.map((column) => (
                                  <TableCell
                                    key={column.field}
                                    align="left"
                                    sortDirection={
                                      orderBy === column.field ? order : false
                                    }
                                    className="img-check text_cen"
                                    sx={{
                                      backgroundColor: "#E6EEF5 !important",
                                    }}
                                  >
                                    {column.field === "download" ? (
                                      <p className="Poppins_20px_rem c_black font_500 ">
                                        {column.title}
                                      </p>
                                    ) : (
                                      <TableSortLabel
                                        active={orderBy === column.field}
                                        direction={
                                          orderBy === column.field
                                            ? order
                                            : "asc"
                                        }
                                        onClick={() => {
                                          setOrderBy(column.field);
                                          setOrder((prevOrder) =>
                                            prevOrder === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <p className="Poppins_20px_rem c_black font_500">
                                          {column.title}
                                        </p>
                                      </TableSortLabel>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {stableSort(
                                filteredFolList,
                                getComparator(order, orderBy)
                              )
                                .slice(0, visibleItems)
                                .map((row, index) => (
                                  <TableRow
                                    id="file-b"
                                    key={index}
                                    sx={{
                                      textTransform: "capitalize",
                                      backgroundColor:
                                        index % 2 === 0
                                          ? "#E6EEF5"
                                          : "transparent",
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      // onClick={() => handlePdfDoc(row)}
                                      id="border-b"
                                      className="text_cen"
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                      onClick={() => handleImg(row,index)}
                                    >
                                      <div className="table_icpon gap_1vw down_hor_is">
                                        {" "}
                                        <img
                                          src={
                                            "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/folViewPic.png"
                                          }
                                          alt={"imgs Icon"}
                                        />
                                        <p
                                          // id="scrollingHistory"
                                          className="Poppins_14px_rem font_500"
                                        >
                                          {row.report_name}
                                        </p>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      className="text_cen img-check"
                                      sx={{ cursor: "pointer" }}
                                      align="center"
                                    >
                                      <p className="Poppins_14px_rem font_500">
                                        {row.total_files}
                                      </p>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ cursor: "pointer" }}
                                      align="center"
                                      className="text_cen img-check"
                                    >
                                      <p className="Poppins_14px_rem font_500">
                                        {row.percentage_defective}
                                      </p>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ cursor: "pointer" }}
                                      align="center"
                                      className="text_cen "
                                      onClick={() => handleDetails(row,index)}
                                    >
                                      <div className="expert_div  text_cen ">
                                        {" "}
                                        <img
                                          src={
                                            "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/CaretCircleRight.png"
                                          }
                                          alt={"imgs Icon"}
                                        />
                                      </div>
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <div
                                        className="expert_div down_hor_i"
                                        onClick={(e) =>
                                          handleHistoryDotsClick(e, index)
                                        }
                                      >
                                        {" "}
                                        <img
                                          src={
                                            "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/DownIc.svg"
                                          }
                                          alt={"imgs Icon"}
                                        />
                                      </div>
                                    </TableCell>
                                    <Popover
                                      open={
                                        Boolean(anchorFol) &&
                                        selectedIndex === index
                                      }
                                      anchorEl={anchorFol}
                                      onClose={handlePopoverCloseFol}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      PaperProps={{
                                        className: "customPopoverPaper-p",
                                      }}
                                    >
                                      <MenuItem
                                        // onClick={() =>
                                        //   handleDownloadClick(
                                        //     ele.download_url
                                        //   )
                                        // }
                                        className="popover-menu"
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/smarttrak.co/newHomePage/download.svg"
                                          alt="share"
                                        />
                                        <p className="Poppins_14px_regular">
                                          Download
                                        </p>
                                      </MenuItem>
                                      <MenuItem
                                        onClick={handlePopoverClose}
                                        className="popover-menu"
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/smarttrak.co/newHomePage/delete.svg"
                                          alt="share"
                                        />
                                        <p className="Poppins_14px_regular">
                                          Delete
                                        </p>
                                      </MenuItem>
                                    </Popover>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        )}
                      </div>
                    ) : (
                      <div className="expert_div div_width">
                        <p
                          className="Poppins_24px_black  font_500"
                          style={{ color: "red" }}
                        >
                          No relevant search found !
                        </p>
                      </div>
                    )}
                  </TableContainer>
                  {visibleItems < filteredFolList.length && (
                    <div
                      className="table_icpon cursor_p div_width"
                      onClick={handleViewMore}
                    >
                      <p className="view_more Poppins_14px_rem font_500">
                        View More
                      </p>
                    </div>
                  )}
                  {/* 
                  <Grid
                    container
                    className="grid-container table_icpon gap_1vw scroll_pdf padd_1vw"
                  >
                     {folderContents.length === 0 ? (
                            <div className="expert_div div_width no-data">
                              <p
                                className="Poppins_24px_black font_500"
                                style={{ textAlign: "center" }}
                              >
                                No data available!
                              </p>
                            </div>
                          ) :
                          
                          filteredPdfList.length > 0 ? (
                      filteredPdfList
                        .slice(0, visibleItems)
                        .map((ele, index) => {
                          return (
                            <Grid
                              key={index}
                              item
                              lg={2.7}
                              xs={12}
                              sm={12}
                              md={3}
                              className="exc-graph-paper"
                            >
                              <Paper className="powerplant-sec-paper gap_1vw foo_one ">
                                <div className="flex_col  expert_div  div_width fol_div gap_1vw">
                                  <div
                                    className="div_width  expert_div shadow_img cursor_p"
                                    onClick={() => handleImg(ele)}
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      className="img_70"
                                      src="
                              https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/FolderView.png"
                                      alt="folder_upload"
                                    />
                                    <div className="pop_name">
                                      <Popover
                                        open={
                                          Boolean(anchorFol) &&
                                          selectedIndex === index
                                        }
                                        anchorEl={anchorFol}
                                        onClose={handlePopoverCloseFol}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "center",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "center",
                                        }}
                                        PaperProps={{
                                          className: "customPopoverPaper-p",
                                        }}
                                      >
                                        <MenuItem
                                          // onClick={() =>
                                          //   handleDownloadClick(
                                          //     ele.download_url
                                          //   )
                                          // }
                                          className="popover-menu"
                                        >
                                          <img
                                            src="https://s3.amazonaws.com/smarttrak.co/newHomePage/download.svg"
                                            alt="share"
                                          />
                                          <p className="Poppins_14px_regular">
                                            Download
                                          </p>
                                        </MenuItem>
                                        <MenuItem
                                          onClick={handlePopoverClose}
                                          className="popover-menu"
                                        >
                                          <img
                                            src="https://s3.amazonaws.com/smarttrak.co/newHomePage/delete.svg"
                                            alt="share"
                                          />
                                          <p className="Poppins_14px_regular">
                                            Delete
                                          </p>
                                        </MenuItem>
                                      </Popover>
                                      <div className="expert_div div_width   flex_col">
                                        <p className="Poppins_14px_rem font_400 c_white">
                                          {ele.name}
                                        </p>

                                        <MoreHorizIcon
                                          sx={{ color: "white" }}
                                          fontSize="small"
                                          className="cursor_p"
                                          onClick={(e) =>
                                            handleHistoryDotsClick(e, index)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="expert_div fol_c cursor_p"
                                    onClick={() => handleDetails(ele)}
                                  >
                                    <img
                                      src="
                        https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/arrow-up-right.svg"
                                      alt="folder_upload"
                                    />
                                  </div>
                                  <div className="flex_col  expert_div  div_width  gap_1vw">
                                    <div className="foo_one div_width">
                                      <p className="Poppins_14px_rem font_500">
                                        Total Image Count
                                      </p>
                                      <p className="Poppins_14px_rem font_700">
                                        {ele.count}
                                      </p>
                                    </div>
                                    <div className="foo_one div_width">
                                      <p className="Poppins_14px_rem font_500">
                                        Defect Rate
                                      </p>
                                      <p className="Poppins_14px_rem font_700 c_red">
                                        {ele.defect}
                                      </p>
                                    </div>
                                    <div className="foo_one div_width">
                                      <p className="Poppins_14px_rem font_500">
                                        Pass / Fail RateS
                                      </p>
                                      <p className="Poppins_14px_rem font_700">
                                        {ele.pass}/{ele.fail}
                                      </p>
                                    </div>
                                    <div className="foo_one div_width">
                                      <p className="Poppins_14px_rem font_500">
                                        Most Common Defect Type
                                      </p>
                                      <p className="Poppins_14px_rem font_700 c_red">
                                        {ele.commondefect}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Paper>
                            </Grid>
                          );
                        })
                    ) : (
                      <div className="expert_div div_width">
                        <p
                          className="Poppins_24px_black  font_500"
                          style={{ color: "red" }}
                        >
                          No relevant search found !
                        </p>
                      </div>
                    )
                    
                    }
                  </Grid> */}
                  {visibleItems < folderContents.length && (
                    <div
                      className="table_icpon cursor_p div_width"
                      onClick={handleViewMore}
                    >
                      <p className="view_more Poppins_14px_rem font_500">
                        View More
                      </p>
                    </div>
                  )}
                </Paper>
              </Grid>
            ) : (
              <Grid
                item
                lg={11}
                xs={12}
                sm={12}
                md={12}
                className="powerplant-sec-paper align_item"
              >
                <Paper className="exc-graph-paper gap_1vw foo_one flex_col">
                  <div className="foo_one div_width flex_crct gap_1vw">
                    <div>
                      <p className="Poppins_24px_black  font_500 ">
                    {`EL Test ${detailIn+1}`}
                      </p>
                    </div>
                    <div className="expert_div gap_1vw" id="main_drop_div">
                      <div className="table_icpon   cursor_p flex_col gap_1vw" id="drop_img">
                        <p className="Poppins_16px_rem font_500 align_self">
                          Defect Type
                        </p>
                        <div
                          className="media_width_auto"
                          id="file_drops"
                          style={{ width: "14rem", minWidth: 160, zIndex: "1" }}
                        >
                          <FormControl className="form-report" fullWidth>
                            <Select
                              options={defectTypeValue.map((field) => ({
                                value: field,
                                label: field
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  ),
                              }))}
                              isMulti={false}
                              onChange={handleChangeDevice}
                              value={{
                                value: isDefectType,
                                label: isDefectType
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  ),
                              }}
                              placeholder="Choose the Device Type"
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="table_icpon   cursor_p flex_col gap_1vw" id="drop_img">
                        <p className="Poppins_16px_rem font_500 align_self">
                          Defect Severity Distribution
                        </p>
                        <div
                          id="file_drops"
                          className="media_width_auto"
                          style={{ width: "14rem", minWidth: 160, zIndex: "1" }}
                        >
                          <FormControl className="form-report" fullWidth>
                            <Select
                              options={severityValue.map((field) => ({
                                value: field,
                                label: field
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  ),
                              }))}
                              isMulti={false}
                              onChange={handleChangeSeveity}
                              value={{
                                value: severityType,
                                label: severityType
                                  .replace(/_/g, " ")
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  ),
                              }}
                              placeholder="Choose the Device Type"
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TableContainer
                    className="scroll_pdf table-scrollbar-pdf"
                    style={{ zIndex: "0" }}
                  >
                    {filteredPdfList.length > 0 ? (
                      <div className="abt_table_scrol">
                        {folderContents.length === 0 ? (
                          <Table
                            stickyHeader
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderBottom: "0rem solid gray !important",
                                }}
                                colSpan={15}
                                align="center"
                                className="div-borderText"
                              >
                                No data available
                              </TableCell>
                            </TableRow>
                            <Divider style={{ border: "1px solid #D9D9D9" }} />
                          </Table>
                        ) : (
                          <Table
                            stickyHeader
                            sx={{ minWidth: 650 }}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableHead>
                              <TableRow>
                                {columns.map((column) => (
                                  <TableCell
                                    key={column.field}
                                    align="left"
                                    sortDirection={
                                      orderBy === column.field ? order : false
                                    }
                                    className="alaram-check"
                                    sx={{
                                      backgroundColor: "#E6EEF5 !important",
                                    }}
                                  >
                                    {column.field === "download" ? (
                                      <p className="Poppins_20px_rem c_black font_500">
                                        {column.title}
                                      </p>
                                    ) : (
                                      <TableSortLabel
                                        active={orderBy === column.field}
                                        direction={
                                          orderBy === column.field
                                            ? order
                                            : "asc"
                                        }
                                        onClick={() => {
                                          setOrderBy(column.field);
                                          setOrder((prevOrder) =>
                                            prevOrder === "asc" ? "desc" : "asc"
                                          );
                                        }}
                                      >
                                        <p className="Poppins_20px_rem c_black font_500">
                                          {column.title}
                                        </p>
                                      </TableSortLabel>
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {stableSort(
                                filteredPdfList,
                                getComparator(order, orderBy)
                              )
                                .slice(0, visibleItems)
                                .map((row, index) => (
                                  <TableRow
                                    id="file-b"
                                    key={index}
                                    sx={{
                                      textTransform: "capitalize",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <TableCell
                                      id="border-b"
                                      className="table_icpon gap_1vw"
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                      // onClick={() => handleSurge(row)}
                                    >
                                      <div className="table_icpon gap_1vw">
                                        {" "}
                                        <img
                                          src={
                                            "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/ImageIcon.png"
                                          }
                                          alt={"imgs Icon"}
                                        />
                                        <p
                                          // id="scrollingHistory"
                                          className="Poppins_14px_rem font_500"
                                        >
                                          {row.name}
                                        </p>
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                    >
                                      <p
                                        id="scrollingHistory"
                                        className="Poppins_14px_rem font_500"
                                      >
                                        {row.size}
                                      </p>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                    >
                                      <p
                                        id="scrollingHistory"
                                        className="Poppins_14px_rem font_500"
                                      >
                                        12-11-2024
                                      </p>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                    >
                                      <p
                                        id="scrollingHistory"
                                        className="Poppins_14px_rem font_500"
                                      >
                                        {row.commondefect}
                                      </p>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      sx={{ cursor: "pointer" }}
                                      align="left"
                                    >
                                      <p
                                        id="scrollingHistory"
                                        className="Poppins_14px_rem font_500"
                                      >
                                        Critical
                                      </p>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <p
                                        className="Poppins_14px_rem font_500 text_cen sol_sec"
                                        // onClick={() => {
                                        //   handleDownloadClick(
                                        //     row.download_url
                                        //   );
                                        // }}
                                      >
                                        <img
                                          src="https://s3.amazonaws.com/smarttrak.co/newHomePage/download.svg"
                                          alt="share"
                                        />
                                      </p>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        )}
                      </div>
                    ) : (
                      <div className="expert_div div_width">
                        <p
                          className="Poppins_24px_black  font_500"
                          style={{ color: "red" }}
                        >
                          No relevant search found !
                        </p>
                      </div>
                    )}
                  </TableContainer>

                  {visibleItems < filteredPdfList.length && (
                    <div
                      className="table_icpon cursor_p div_width"
                      onClick={handleViewMore}
                    >
                      <p className="view_more Poppins_14px_rem font_500">
                        View More
                      </p>
                    </div>
                  )}
                </Paper>
              </Grid>
            )}
          </>
        )}

   
        {filesCount.total > 0 && (
                <div  className="svg_upload_name  svg_upload padd_1vw gap_1vw expert_div flex_col div_width file_uploading">
                  <div className="foo_one gap_1vw div_width">
                    <p className="search_Text c_black font_600">
                    {filesCount.uploaded}/{filesCount.total} files uploading
                    </p>
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={handleCancel}
                      className="cursor_p"
                    >
                      <path
                        d="M13.09 6L10.5 8.59L7.91 6L6.5 7.41L9.09 10L6.5 12.59L7.91 14L10.5 11.41L13.09 14L14.5 12.59L11.91 10L14.5 7.41L13.09 6ZM10.5 0C4.97 0 0.5 4.47 0.5 10C0.5 15.53 4.97 20 10.5 20C16.03 20 20.5 15.53 20.5 10C20.5 4.47 16.03 0 10.5 0ZM10.5 18C6.09 18 2.5 14.41 2.5 10C2.5 5.59 6.09 2 10.5 2C14.91 2 18.5 5.59 18.5 10C18.5 14.41 14.91 18 10.5 18Z"
                        fill="#FF3636"
                      />
                    </svg>
                  </div>

                  <div className="div_width">
                    <BorderLinearProgress
                      variant="determinate"
                      value={uploadProgress}
                    />
                  </div>
                </div>
                )}
      </Grid>
     
    </div>
  );
}

export default ImageUpload;