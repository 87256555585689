import { ApiHelper } from "./helper/ApiHelper";

const BASE_URL = "https://smarttrakportal.in/cosmic";
const TEST_URL = "http://20.109.49.217:9000";
const ChatURL = "https://chat.smarttrakportal.in";
const apiHelper = new ApiHelper();

export class CosmicInverterService {
  // getInverterList(id) {
  //     const uri = `${BASE_URL}/solar/plant/${id}/inverter/latest_telemetry`;
  //     return apiHelper.get(uri);
  //   }
  getListDeviceFields(device_name, device_id) {
    const uri = device_id ? `${BASE_URL}/solar/${device_name}/fields?device_id=${device_id}` : `${BASE_URL}/solar/${device_name}/fields`;
    return apiHelper.get(uri);
  }
  getListDeviceFieldsWMS(device_name, device_id) {
    const uri =device_id ?`${BASE_URL}/solar/device/${device_name}/fields/all`: `${BASE_URL}/solar/device/${device_name}/fields/all`;
    return apiHelper.get(uri);
  }
  getDeviceLevelData(id, device_type) {
    const uri = `${BASE_URL}/solar/plant/${id}/${device_type}/latest_telemetry`;

    return apiHelper.get(uri);
  }
  getPlantLosses(plant_id) {
    const uri = `${BASE_URL}/solar/plant/${plant_id}/losses`;
    return apiHelper.get(uri);
  }
  getPlantEnergyGen(plant_id) {
    const uri = `${BASE_URL}/solar/plant/${plant_id}/energy_overview/v3`;
    return apiHelper.get(uri);
  }
  getInvDashEnergyGen(plant_id) {
    const uri = `${BASE_URL}/solar/plant/${plant_id}/inverter/energy_overview/v1`;
    return apiHelper.get(uri);
  }
  getInvLevelEnergyGen(device_id) {
    const uri = `${BASE_URL}/solar/inverter/${device_id}/energy_overview/v1`;
    return apiHelper.get(uri);
  }
  //for particular id
  getParticularIdData(id, device_type) {
    const uri = `${BASE_URL}/solar/${device_type}/${id}/latest_telemetry`;

    return apiHelper.get(uri);
  }
  getPowerGenGraph(id, start_time, end_time) {
    const uri = `${BASE_URL}/solar/device/${id}/power_generation?start_time=${start_time}&end_time=${end_time}`;
    return apiHelper.get(uri);
  }
  getPerfOverview(id, start_time, end_time) {
    const uri = `${BASE_URL}/solar/device/${id}/performance_metrics?start_time=${start_time}&end_time=${end_time}`;
    return apiHelper.get(uri);
  }
  getTempParameter(id, start_time, end_time) {
    const uri = `${BASE_URL}/solar/device/${id}/temperature_metrics?start_time=${start_time}&end_time=${end_time}`;
    return apiHelper.get(uri);
  }
  getDevicePerfRatio(id, start_time, end_time) {
    const uri = `${BASE_URL}/solar/device/${id}/performance_ratio?start_time=${start_time}&end_time=${end_time}`;
    return apiHelper.get(uri);
  }
  getPlantPerfRatio(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/performance_ratio/v2?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/performance_ratio/v2`;
    return apiHelper.get(uri);
  }
  getPastGenData(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/energy_overview?past_7day_gen=True&start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/energy_overview?past_7day_gen=True`;
    return apiHelper.get(uri);
  }
  getPastGenExportedData(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/energy_exported_overview?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/energy_exported_overview`;
    return apiHelper.get(uri);
  }
  getMultiBarData(id, params) {
    const queryParams = new URLSearchParams(params).toString();
    const uri =
    queryParams
        ? `${BASE_URL}/solar/plant/${id}/performance_matrices?${queryParams}`
        : `${BASE_URL}/solar/plant/${id}/performance_matrices`;
    return apiHelper.get(uri);
  }

  getCufAc(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/cuf_ac?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/cuf_ac`;
    return apiHelper.get(uri);
  }
  getCufDc(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/cuf_dc?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/cuf_dc`;
    return apiHelper.get(uri);
  }
  getActivePowerIrr(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/active_power_irradiance?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/active_power_irradiance`;
    return apiHelper.get(uri);
  }
  getInvParameter(id) {
    const uri = `${BASE_URL}/solar/inverter/${id}/latest_telemetry`;
    return apiHelper.get(uri);
  }
  getPlantTable(id, fieldVal, duration, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/generated_power_edge?field=${fieldVal}&filter=${duration}&start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/generated_power_edge?field=${fieldVal}&filter=${duration}`;
    return apiHelper.get(uri);
  }
  getPlantOverview(id) {
    const uri = `${BASE_URL}/solar/plant/${id}/energy_overview/v2`;
    return apiHelper.get(uri);
  }
  getInverterOverview(id, device_type) {
    const uri = `${BASE_URL}/solar/${device_type}/${id}/energy_overview`;
    return apiHelper.get(uri);
  }
  getWeatherInfo(id) {
    const uri = `${BASE_URL}/solar/plant/weather/v2?plant_id=${id}`;
    return apiHelper.get(uri);
  }
  getReportData() {
    const uri = `${BASE_URL}/solar/report/failure`;
    return apiHelper.get(uri);
  }
  getReportsFailureData() {
    const uri = `${BASE_URL}/solar/report/failure_v1`;
    return apiHelper.get(uri);
  }
  getGHIOverview(id) {
    const uri = `${BASE_URL}/solar/plant/${id}/ghi_overview`;
    return apiHelper.get(uri);
  }
  getInverterDashboard(id) {
    const uri = `${BASE_URL}/solar/plant/${id}/inverter/energy_overview`;
    return apiHelper.get(uri);
  }
  getDeviceSttaus(id) {
    const uri = `${BASE_URL}/solar/plant/${id}/devices/status`;
    return apiHelper.get(uri);
  }
  getEnergyMeterGraph(device_id, filter, start_time, end_time, device_type) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/${device_type}/${device_id}/energy_meter_status?start_time=${start_time}&end_time=${end_time}&agg_func=${filter}`
        : `${BASE_URL}/solar/${device_type}/${device_id}/energy_meter_status?agg_func=${filter}`;
    return apiHelper.get(uri);
  }

  getDeviceEnergyGeneration(id, filter, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/daily_energy_generation_MWh?start_time=${start_time}&end_time=${end_time}&agg_func=${filter}`
        : `${BASE_URL}/solar/inverter/${id}/daily_energy_generation_MWh?agg_func=${filter}`;
    return apiHelper.get(uri);
  }
  getDeviceFieldEnergy(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/energy_counter_MWh?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/inverter/${id}/energy_counter_MWh`;
    return apiHelper.get(uri);
  }
  getDeviceFieldAcVoltage(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/net_AC_voltage_V?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/inverter/${id}/net_AC_voltage_V`;
    return apiHelper.get(uri);
  }
  getDeviceFieldoutPower(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/output_power_kW?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/inverter/${id}/output_power_kW`;
    return apiHelper.get(uri);
  }
  getDeviceOutputCurrent(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/output_current_A?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/inverter/${id}/output_current_A`;
    return apiHelper.get(uri);
  }
  getDeviceFieldDcVoltage(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/DC_voltage_DCV?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/inverter/${id}/DC_voltage_DCV`;
    return apiHelper.get(uri);
  }
  getDeviceFieldAvgPercent(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/average_cosphii_percent?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/inverter/${id}/average_cosphii_percent`;
    return apiHelper.get(uri);
  }
  getDeviceCompareFields(device_name, id, field, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/${device_name}/${id}/${field}?start_time=${start_time}&end_time=${end_time}&filter=hour`
        : `${BASE_URL}/solar/${device_name}/${id}/${field}?filter=hour`;
    return apiHelper.get(uri);
  }

  getDeviceCompareWMS(device_name, id, field, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/device/${device_name}/${id}/${field}?start_time=${start_time}&end_time=${end_time}&filter=hour`
        : `${BASE_URL}/solar/device/${device_name}/${id}/${field}?filter=hour`;
    return apiHelper.get(uri);
  }
  getMfmCompareFields(
    id,
    field,
    start_time,
    end_time,
    filter,
    device_type,
    isCSV
  ) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/${device_type}/${id}/${field}?start_time=${start_time}&end_time=${end_time}&filter=${filter}&csv=${isCSV}&plant_tz=Asia/Kolkata`
        : `${BASE_URL}/solar/${device_type}/${id}/${field}?filter=${filter}&csv=${isCSV}&plant_tz=Asia/Kolkata`;
    return apiHelper.get(uri);
  }
  getInvertersCompare(id, filter, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/energy_generation/v2?start_time=${start_time}&end_time=${end_time}&agg_func=${filter}`
        : `${BASE_URL}/solar/inverter/${id}/energy_generation/v2?agg_func=${filter}`;
    return apiHelper.get(uri);
  }
  getEnergyMeterStatus(id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/energy_meter?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/energy_meter`;
    return apiHelper.get(uri);
  }
  getWeatherAmbientTemp(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/panel/${device_id}/ambient_temperature_degree_C?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/panel/${device_id}/ambient_temperature_degree_C`;
    return apiHelper.get(uri);
  }
  getModuleTemp(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/panel/${device_id}/module_temperature_degree_C?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/panel/${device_id}/module_temperature_degree_C`;
    return apiHelper.get(uri);
  }
  getWeatherAbsoluteHumidity(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/device/wms/${device_id}/absolute_humidity_act?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/device/wms/${device_id}/absolute_humidity_act`;
    return apiHelper.get(uri);
  }
  getWeatherRelativeHumidity(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/device/wms/${device_id}/relative_humidity_act?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/device/wms/${device_id}/relative_humidity_act`;
    return apiHelper.get(uri);
  }

  getWeatherWindSpeed(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/device/wms/${device_id}/wind_speed?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/device/wms/${device_id}/wind_speed`;
    return apiHelper.get(uri);
  }
  getWeatherIrradiance(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/device/wms/${device_id}/irradiance?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/device/wms/${device_id}/irradiance`;
    return apiHelper.get(uri);
  }
  getAmbientTemp(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/device/wms/${device_id}/ambient_temp?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/device/wms/${device_id}/ambient_temp`;
    return apiHelper.get(uri);
  }
  getWeatherMaxValues(device_id) {
    const uri = `${BASE_URL}/solar/device/wms/${device_id}/max/v2`;
    return apiHelper.get(uri);
  }
  getPlantDevices(id, device_type) {
    const uri = `${BASE_URL}/solar/plant/devices?device_type=${device_type}&plant_id=${id}`;
    return apiHelper.get(uri);
  }
  getMultiFieldCompare(ids, fields, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/compare/${ids}/${fields}?start_time=${start_time}&end_time=${end_time}&filter=hour`
        : `${BASE_URL}/solar/inverter/compare/${ids}/${fields}?filter=hour`;

    return apiHelper.get(uri);
  }

  //inv vs power vs irradiance api
  getActivePowerIrrPOA(id, filter, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/active_power_irradiance_POA/v4?start_time=${start_time}&end_time=${end_time}&agg_func=${filter}`
        : `${BASE_URL}/solar/plant/${id}/active_power_irradiance_POA/v4?agg_func=${filter}`;
    return apiHelper.get(uri);
  }

  //download CSV Report
  getDailyCSVReport(id, start_time, end_time, isCSV) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/plant_summary_csv?download_csv=${isCSV}&start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/plant/${id}/plant_summary_csv?download_csv=${isCSV}`;
    return apiHelper.get(uri);
  }
  getReportDeviceBox(device_type, id) {
    const uri = `${BASE_URL}/solar/plant/devices/v2?device_type=${device_type}&plant_id=${id}`;
    return apiHelper.get(uri);
  }

  getReportDeviceTypes() {
    const uri = `${BASE_URL}/solar/plant/device_types`;
    return apiHelper.get(uri);
  }
  //MFM Apis

  getEnergyMeterRmsCurrent(
    device_id,
    start_time,
    end_time,
    device_type,
    field_list
  ) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/${device_type}/${device_id}/${field_list}?start_time=${start_time}&end_time=${end_time}&filter=hour`
        : `${BASE_URL}/solar/${device_type}/${device_id}/${field_list}?filter=hour`;
    return apiHelper.get(uri);
  }
  getEnergyMeterRmsVoltage(
    device_id,
    start_time,
    end_time,
    device_type,
    field_list
  ) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/${device_type}/${device_id}/${field_list}?start_time=${start_time}&end_time=${end_time}&filter=hour`
        : `${BASE_URL}/solar/${device_type}/${device_id}/${field_list}?filter=hour`;
    return apiHelper.get(uri);
  }

  getMfmRmsACurrent(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/phase_A_RMS_current_A?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/phase_A_RMS_current_A`;
    return apiHelper.get(uri);
  }
  getMfmRmsBCurrent(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/phase_B_RMS_current_A?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/phase_B_RMS_current_A`;
    return apiHelper.get(uri);
  }
  getMfmRmsCCurrent(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/phase_C_RMS_current_A?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/phase_C_RMS_current_A`;
    return apiHelper.get(uri);
  }
  getMfmEarthRmsCurrent(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/earth_RMS_current_A?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/earth_RMS_current_A`;
    return apiHelper.get(uri);
  }
  getMfmPositiveActiveEnergy(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/positive_active_energy_kWh?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/positive_active_energy_kWh`;
    return apiHelper.get(uri);
  }
  getMfmNegativeActiveEnergyKWh(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/negative_active_energy_kWh?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/negative_active_energy_kWh`;
    return apiHelper.get(uri);
  }
  getMfmPosiveReactiveEnergy(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/positive_reactive_energy_kVARh?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/positive_reactive_energy_kVARh`;
    return apiHelper.get(uri);
  }
  getMfmNegativeActiveEnergyKVARh(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/negative_active_energy_kVARh?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/negative_active_energy_kVARh`;
    return apiHelper.get(uri);
  }
  getMfmPhaseARmsVolt(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/phase_A_RMS_voltage_V?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/phase_A_RMS_voltage_V`;
    return apiHelper.get(uri);
  }
  getMfmPhaseBRmsVolt(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/phase_B_RMS_voltage_V?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/phase_B_RMS_voltage_V`;
    return apiHelper.get(uri);
  }
  getMfmPhaseCRmsVolt(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/phase_C_RMS_voltage_V?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/phase_C_RMS_voltage_V`;
    return apiHelper.get(uri);
  }
  getMfmEarthRmsVolt(device_id, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/mfm/${device_id}/earth_RMS_voltage_V?start_time=${start_time}&end_time=${end_time}`
        : `${BASE_URL}/solar/mfm/${device_id}/earth_RMS_voltage_V`;
    return apiHelper.get(uri);
  }

  //forecast weather api
  getForecastWeather(id) {
    const uri = `${BASE_URL}/solar/plant/weather?plant_id=${id}`;
    return apiHelper.get(uri);
  }
  //report post api
  postReportsDownload(id, start_time, end_time, xlsx, data) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/report?plant_id=${id}&start_time=${start_time}&end_time=${end_time}&xlsx=${xlsx}`
        : `${BASE_URL}/solar/plant/report?plant_id=${id}&xlsx=${xlsx}`;
    return apiHelper.post(uri, data);
  }
  //report history api
  getReportHistory() {
    const uri = `${BASE_URL}/solar/plant/reports`;
    return apiHelper.get(uri);
  }
  //download each report
  getEachDownloadReport(id) {
    const uri = `${BASE_URL}/solar/plant/reports/${id}/download`;
    return apiHelper.get(uri);
  }
  getParameterForReport(device_type, id) {
    const uri = `${BASE_URL}/solar/plant/devices/fields?device_type=${device_type}&plant_id=${id}`;
    return apiHelper.get(uri);
  }
  //table api (inv, abt, mfm)
  getDeviceTable(device_id, device_type) {
    const uri = `${BASE_URL}/solar/${device_type}/${device_id}/paramters/table`;
    return apiHelper.get(uri);
  }
  reportType() {
    const uri = `${BASE_URL}/solar/plant/report/type`;
    return apiHelper.get(uri);
  }
  // https://smarttrakportal.in/cosmic/solar/chatbot

  //ChatBot API
  postChatbotNew(data) {
    const { conversation_id, message } = data;
    const uri = `${ChatURL}/messages?output_audio=false${
      conversation_id ? `&conversation_id=${conversation_id}` : ""
    }&question=${message}`;

    return apiHelper.post(uri);
  }
  postVoiceChatbot(formData) {
    const conversation_id = formData.get("conversation_id");

    const uri = `${ChatURL}/messages?output_audio=true${
      conversation_id ? `&conversation_id=${conversation_id}` : ""
    }`;
    return apiHelper.post(uri, formData);
  }

  postChatbot(data) {
    const uri = `${BASE_URL}/solar/chatbot/conversations/messages/v2`;
    return apiHelper.post(uri, data);
  }
  getPredictedData(id, filter, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/plant/${id}/energy_prediction_status?start_time=${start_time}&end_time=${end_time}&agg_func=${filter}&change_timestamp=true`
        : `${BASE_URL}/solar/plant/${id}/energy_prediction_status?agg_func=${filter}&change_timestamp=true`;

    return apiHelper.get(uri);
  }
  getInvPredictedData(id, filter, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${id}/energy/prediction?start_time=${start_time}&end_time=${end_time}&agg_func=${filter}&change_timestamp=true`
        : `${BASE_URL}/solar/inverter/${id}/energy/prediction?agg_func=${filter}&change_timestamp=true`;

    return apiHelper.get(uri);
  }
  getActualIdeal(device_id, filter, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${device_id}/energy/idealvsactual?start_time=${start_time}&end_time=${end_time}&agg_func=${filter}&change_timestamp=true`
        : `${BASE_URL}/solar/inverter/${device_id}/energy/idealvsactual?agg_func=${filter}&change_timestamp=true`;

    return apiHelper.get(uri);
  }
  getActualVsForecasted(device_id, filter, start_time, end_time) {
    const uri =
      start_time && end_time
        ? `${BASE_URL}/solar/inverter/${device_id}/energy/forecastvsactual?start_time=${start_time}&end_time=${end_time}&agg_func=${filter}&change_timestamp=true`
        : `${BASE_URL}/solar/inverter/${device_id}/energy/forecastvsactual?agg_func=${filter}&change_timestamp=true`;

    return apiHelper.get(uri);
  }
  getIdealGeneration(device_id) {
    const uri = `${BASE_URL}/solar/inverter/${device_id}/ideal/latest_telemetry`;
    return apiHelper.get(uri);
  }
  getChatHistory() {
    const uri = `${ChatURL}/conversation/history`;
    return apiHelper.get(uri);
  }
  // getSingleChatHistory(id) {
  //   const uri = `${BASE_URL}/solar/chatbot/conversations/history/${id}/v2`;
  //   return apiHelper.get(uri);
  // }
  getSingleChatHistory(id) {
    const uri = `${ChatURL}/conversation/${id}/history`;
    return apiHelper.get(uri);
  }
  postChatHisrory(id, data) {
    const uri = `${BASE_URL}/solar/chatbot/conversations/${id}/messages`;
    return apiHelper.post(uri, data);
  }

  //Trckers API
  getTrackerLists() {
    const uri = `${BASE_URL}/solar/tracker/status`;
    return apiHelper.get(uri);
  }
  getTrackerRequest(node_id) {
    const uri = `${BASE_URL}/solar/tracker/node?node_id=${node_id}`;
    return apiHelper.get(uri);
  }
  getFetchTrackerData(node_id) {
    const uri = `${BASE_URL}/solar/tracker/node/setting?node_id=${node_id}`;
    return apiHelper.get(uri);
  }
  getEachTrackerData(node_id) {
    const uri = `${BASE_URL}/solar/tracker/node/monitor?node_id=${node_id}`;
    return apiHelper.get(uri);
  }
  updateTrackerData(data) {
    const uri = `${BASE_URL}/solar/tracker/update`;
    return apiHelper.put(uri, data);
  }

  //// file upload pdf chat
postFileUpload(data) {
  const uri = `${ChatURL}/chatwithdoc/upload`;
  return apiHelper.post(uri, data);
}
getListDocs() {
  const uri = `${ChatURL}/chatwithdoc/list_documents`;
  return apiHelper.get(uri);
}
pdfChat(data) {
  const uri = `${ChatURL}/chatwithdoc/chat`;
  return apiHelper.post(uri,data);
}

//image file upload
postImgFileUpload(data) {
  const uri = `${ChatURL}/cvp/upload`;
  return apiHelper.post(uri, data);
}
getListFol() {
  const uri = `${ChatURL}/cvp/list_reports`;
  return apiHelper.get(uri);
}
getAllUpload(data) {
  const uri = `${ChatURL}/cvp/start_processing`;
  return apiHelper.post(uri,data);
}

}
