import React from 'react';

function ImgUpload({ width = 21, height = 20, strokeColor  }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      
      <path d="M2.46533 18C1.91533 18 1.4445 17.8042 1.05283 17.4125C0.661165 17.0208 0.465332 16.55 0.465332 16V4C0.465332 3.45 0.661165 2.97917 1.05283 2.5875C1.4445 2.19583 1.91533 2 2.46533 2H5.61533L7.46533 0H13.4653L15.3153 2H18.4653C19.0153 2 19.4862 2.19583 19.8778 2.5875C20.2695 2.97917 20.4653 3.45 20.4653 4V16C20.4653 16.55 20.2695 17.0208 19.8778 17.4125C19.4862 17.8042 19.0153 18 18.4653 18H2.46533ZM2.46533 16H18.4653V4H14.4153L12.5903 2H8.34033L6.51533 4H2.46533V16Z" fill={strokeColor}/>
<path d="M4.3433 14.379L3.26294 13.2986L8.66475 7.89683L11.5457 10.7778L16.6594 5.01587L17.6678 6.02421L11.5457 12.9385L8.66475 10.0576L4.3433 14.379Z" fill={strokeColor}/>

    </svg>
  );
}

export default ImgUpload;