import React, { useState } from 'react';

const BatteryGreen = () => {
  const [percentage, setPercentage] = useState(85); // Initial fill percentage
  const [hoverValue, setHoverValue] = useState(null); // Hovered value display

  const rectHeight = 160; // The total height of the rect
  const rectWidth = 500; // The total width of the rect
  const fillWidth = (percentage / 100) * rectWidth; // Calculate filled width based on percentage

  return (
    <div className='expert_div gap_1vw div_width' style={{ position: 'relative', height: `${rectHeight}px` }}>
      <svg
        width={rectWidth}
        height={rectHeight}
        viewBox={`0 0 ${rectWidth} ${rectHeight}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Define the gradient */}
        <defs>
          <linearGradient id="batteryGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#58AC34', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#333333', stopOpacity: 1 }} />
          </linearGradient>
        </defs>

        {/* Background rectangle */}
        <rect
          x="0"
          y="0"
          width={rectWidth}
          height={rectHeight}
          rx="20"
          stroke="#EDEDF2"
          fill="none"
        />
        
        {/* Filled rectangle with gradient */}
        <rect
          x="0"
          y="0"
          width={fillWidth}
          height={rectHeight}
          fill="url(#batteryGradient)" // Use gradient as fill
          style={{
            clipPath:
              percentage === 100
                ? "none" // No clipping for 100% (rounded corners on both sides)
                : `inset(0px -2px 0px 0px round 20px 0px 0px 20px)`, // Left rounded, right sharp
          }}
          stroke={percentage === 100 ? "#FDA737" : "none"} // Border only for 100%
          strokeWidth="2"
        />
      </svg>
      <div className='expert_div battery_front_g'>
      </div>
      {/* Percentage display on hover */}
      <div className='progress_battey_g'
       
      >
       <p className='Poppins_40px_rem font_600'> {percentage}%</p>
      </div>
    </div>
  );
};

export default BatteryGreen;
