import React, { lazy, Suspense, useState } from "react";
import SideBar from "./SideBar";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MyProfile from "../Screens/Profile/MyProfile";
import UpperBar from "./UpperBar";

import NoPage from "../../LifeScience/screens/Common/NoPage";
import InviteUsers from "../Screens/Profile/InviteUsers";
import OnBoardPlant from "../Screens/Profile/OnBoardPlant";
import ServerErrPage from "../Common/ServerErrPage";
import ExecutiveDashboard from "../Screens/ExecutiveDashboard/ExecutiveDashboard";
import Trackers from "../Screens/DeviceMonitoring/Trackers/Trackers";
import SMB from "../Screens/DeviceMonitoring/SMB/SMB";
import WeatherDashbrd from "../Screens/DeviceMonitoring/WeatherDashbrd/WeatherDashbrd";
import Inverter from "../Screens/DeviceMonitoring/Inverter/Inverter";
import HtPanel from "../Screens/DeviceMonitoring/HtPanel";
import PlantLayout from "../Screens/PlantLayout/PlantLayout"
import Reports from "../Screens/Reports/Reports";
import VcbPanel from "../Screens/DeviceMonitoring/VcbPanel";
import MFM from "../Screens/DeviceMonitoring/MFM/MFM";
import AbtMeter from "../Screens/AbtMeter/AbtMeter";
import InverterDashboard from "../Screens/DeviceMonitoring/Inverter/InverterDashboard";
import Alram from "../Screens/Alrams/Alram";

import AlramSurge from "../Screens/Alrams/AlramSurge";

import SmbDetails from "../Screens/DeviceMonitoring/SMB/SmbDetails";
import CompareInverters from "../Screens/DeviceMonitoring/Inverter/CompareInverters";
import ReportFailure from "../Screens/ReportFailure/ReportFailure";
import TrackersHome from "../Screens/DeviceMonitoring/Trackers/TrackersHome";
import VCBHome from "../Screens/DeviceMonitoring/VCBPanel/VCBHome";
import VCBdetails from "../Screens/DeviceMonitoring/VCBPanel/VCBdetails";
import MFMDetails from "../Screens/DeviceMonitoring/MFM/MFMDetails";
import SimpleBackdrop from "../../LifeScience/components/common/SimpleBackdrop";
import ScrollXaxisChart from "../Graphs/ScrollXaxisChart";
import { MobileProvider } from "../Context/MobileContext/MobileContext";
import { MFMProvider } from "../Context/MobileContext/MFMContext";
import FileUpload from "../Screens/FileUpload/FileUpload";
import DetailedDiagnostic from "../Screens/ReportFailure/DetailedDiagnostic";
import ImageUpload from "../Screens/ImageUpload/ImageUpload";
import DeatailedAnalysis from "../Screens/ImageUpload/DeatailedAnalysis";
import Battery from "../Screens/DeviceMonitoring/Battery/Battery";
const mdTheme = createTheme();
function HomePage(props) {
  let { path } = useRouteMatch();
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const isProfilePath = location.pathname.includes(`${path}/profile`);
  const ThreeDTwin = lazy(()=> import("../Screens/ThreeDTwin/ThreeDTwin"));

  return (
    <ThemeProvider theme={mdTheme}>
        <MobileProvider>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100vh",
          width: "100vw",
          overflow: "auto",
        }}
      >
         <MFMProvider>
        <CssBaseline />
        {!isProfilePath && <UpperBar/>}
        <Box
          sx={{ flexGrow: 1, position: "relative", zIndex: 1, display: "flex" }}
        >
         
          <SideBar height={!isProfilePath ? "92vh" : "100vh"} />
          <Suspense fallback={<div><SimpleBackdrop open={loading}  onLoad={() => setLoading(false)} /></div>}>
          <Switch>
         
            <Route exact path={path} component={ExecutiveDashboard} />
           
         
            <Route exact path={`${path}/server-error`} component={ServerErrPage} />
            
            <Route exact path={`${path}/reports`} component={Reports}  />
            <Route exact path={`${path}/alarms`} component={Alram}  />
            <Route exact path={`${path}/alarms-surge`} component={AlramSurge}  />
            

         
            <Route exact path={`${path}/profile`} component={MyProfile}  />
            <Route exact path={`${path}/invite-users`} component={InviteUsers}  />
            <Route exact path={`${path}/onboard`} component={OnBoardPlant}  />
            <Route exact path={`${path}/dev-monitoring/trackers`} component={TrackersHome}  />
            <Route exact path={`${path}/dev-monitoring/trackers/home`} component={Trackers}  />


            <Route exact path={`${path}/dev-monitoring/smb`} component={SMB}  />
            <Route exact path={`${path}/dev-monitoring/smb-details`} component={SmbDetails}  />
            <Route exact path={`${path}/dev-monitoring/weather-dashboard`} component={WeatherDashbrd}  />
            <Route exact path={`${path}/dev-monitoring/inv-dashboard`} component={InverterDashboard}  />
            <Route exact path={`${path}/dev-monitoring/inv-dashboard/compare-inv`} component={CompareInverters}  />
            <Route exact path={`${path}/dev-monitoring/inv-dashboard/inv-details`} component={Inverter}  />
            <Route exact path={`${path}/dev-monitoring/ht-panel`} component={HtPanel}  />
            <Route exact path={`${path}/dev-monitoring/vcb-panel`} component={VCBHome}  />
            <Route exact path={`${path}/dev-monitoring/vcb/vcb-details`} component={VCBdetails}  />
            <Route exact path={`${path}/dev-monitoring/battery`} component={Battery}  />
            <Route exact path={`${path}/dev-monitoring/mfm`} component={MFM}  />
            <Route exact path={`${path}/dev-monitoring/mfm-data`} component={MFMDetails}  />
            <Route exact path={`${path}/fault_analysis`} component={ReportFailure}  />
            <Route exact path={`${path}/fault_analysis/detailed_diagnostic`} component={DetailedDiagnostic}  />
            <Route exact path={`${path}/plant-layout`} component={PlantLayout}  />

            
            <Route exact path={`${path}/file-upload`} component={FileUpload}  />

            <Route exact path={`${path}/img-upload`} component={ImageUpload}  />
            <Route exact path={`${path}/img-upload/detail-analysis`} component={DeatailedAnalysis}  />


            <Route exact path={`${path}/dev-monitoring/abt`} component={AbtMeter}  />

            <Route exact path={`${path}/three-d-twin`} component={ThreeDTwin}  />
            <Route exact path={`${path}/scrollable-chart`} component={ScrollXaxisChart}  />
            

            <Route path="*" component={NoPage} />

           
          </Switch>
          </Suspense>
          
        </Box>
      </MFMProvider>

      </Box>
      </MobileProvider>

    </ThemeProvider>
  );
}

export default HomePage;
