import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "./SMB.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BarPlotForIOT from "../../../Graphs/BarPlotForIOT";
import { useHistory } from "react-router-dom";

function SmbDetails() {
  const dcMonitoring = [
    {
      value: "0 V",
      name:
        "DC voltage" +
        "\u00A0" +
        "\u00A0" +
        "\u00A0" +
        "\u00A0" +
        "\u00A0" +
        "\u00A0" +
        "\u00A0" +
        "\u00A0",
    },
    {
      value: "0 A",
      name: "Sum of Current",
    },
  ];

  const currentStatus = [
    {
      name: "SPD Status",
      status: "Active",
    },
    {
      name: "DC Switch",
      status: "Active",
    },
  ];
  const columns = [
    { field: "string", title: "String" },
    { field: "current1", title: "Current(A)" },
    { field: "current2", title: "Current(A)" },
  ];

  const trackerBody = [
    {
      a: "--",
      b: `--`,
      c: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
      d: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
    },
    {
      a: "--",
      b: `--`,
      c: `--`,
    },
  ];
  const smblist = ["INV2SMB12", "SMB1", "SMB2", "SMB3", "SMB4", "SMB5", "SMB6"];
  const smbStringList = [
    "String 01",
    "String 02",
    "String 03",
    "String 04",
    "String 05",
    "String 06",
    "String7",
    "String 08",
    "String 09",
    "String 10",
    "String11",
    "String 12",
    "String 13",
    "String 14",
    "String 15",
    "String 16",
    "String 17",
    "String 18",
    "String 19",
    "String 20",
    "String 21",
  ];
  const [deviceId, setDeviceId] = useState(0);

  const [gridHeight, setGridHeight] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [smbDataArray, setSmbDataArray] = useState({
    devices_data: [],
    device_summary: {},
  });
  const [deviceFieldValue, setDeviceFieldValue] = useState([]);
  const [tableData, setTableData] = useState([]);
 const [loadingState, setLoadingState] = useState({
    
    smbList: false,
  })

  const [selectedDeviceStringIds, setSelectedDeviceStringIds] = useState([
    0, 1,
  ]);
  const history = useHistory();

  const gridRef = useRef(null);

  useEffect(() => {
    if (gridRef.current) {
      const height = gridRef.current.offsetHeight;
      setGridHeight(height);
    }
  }, [gridRef]);
 

  const handleSMBStringDetails = (index) => {
    // setSelectedDeviceStringIds(prevSelected => {
    //   if (prevSelected.includes(index)) {
    //     return prevSelected.filter(id => id !== index);
    //   } else {
    //     return [...prevSelected, index];
    //   }
    // });
  };
  return (
      <>
        <Grid item lg={4.8} xs={12} sm={12} md={12} className="second_paper">
          <Paper ref={gridRef} className="second_paper">
            <Grid container className="grid-container-smb" ref={gridRef}>
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="paper_borderless"
              >
                <Paper className="paper_borderless">
                  <p className="raleway_24px_rem" style={{ color: "#1B2128" }}>
                    Real-Time Monitoring
                  </p>
                </Paper>
              </Grid>

              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="smb_grid_sub"
              >
                <Paper className="smb_paper_sub">
                  {dcMonitoring.map((item, index) => {
                    return (
                      <div className="smb-one-div" key={index}>
                        <div>
                          <img src="https://s3.amazonaws.com/smarttrak.co/v2Images/gen_icon.svg" />
                        </div>
                        <div className="smb_value_container">
                          <p
                            className="Poppins_24px_rem"
                            style={{ color: "black" }}
                          >
                            {item.value}
                          </p>
                          <p className="raleway_18px_rem">{item.name}</p>
                        </div>
                      </div>
                    );
                  })}
                </Paper>
              </Grid>

              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="smb_grid_sub"
              >
                <Paper className="paper_column">
                  {currentStatus.map((item, index) => {
                    return (
                      <div className="smb-switch" key={index}>
                        <div>
                          <p
                            className="Poppins_20px_rem"
                            style={{ fontWeight: "400", color: "black" }}
                          >
                            {item.name}
                          </p>
                        </div>
                        <div
                          className="active_green"
                          style={{ background: "#04724D" }}
                        >
                          <p>🟢</p>
                          <p
                            className="raleway_14px_rem"
                            style={{ fontWeight: "700", color: "#ffff" }}
                          >
                            {" "}
                            Active
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item lg={7} xs={12} sm={12} md={12} className="abt-first-grid ">
          <Paper sx={{ height: gridHeight }} className="second_paper_smb">
            <div className="smb_value_container">
              <p className="raleway_24px_rem" style={{ color: "#1B2128" }}>
                String-Level Current Monitoring
              </p>
            </div>
            <TableContainer className="table-scrollbar" component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column, index) => (
                      <TableCell
                        sx={{
                          padding: "1rem",
                          backgroundColor: "#FFFBE6",
                        }}
                        key={column.field}
                        align="left"
                      >
                        <p className="raleway_12px_rem">{column.title}</p>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {trackerBody.map((row, index) => (
                    <TableRow
                      key={index}
                      // selected={isSelected(index)}
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "transparent",
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={{ padding: "1rem" }}
                      >
                        <p className="Poppins_12px_rem">{row.a}</p>
                      </TableCell>
                      <TableCell sx={{ padding: "1rem" }} align="left">
                        <p className="Poppins_12px_rem">{row.b}</p>
                      </TableCell>
                      <TableCell sx={{ padding: "1rem" }} align="left">
                        <p className="Poppins_12px_rem">{row.c}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>

        {smbStringList.length > 0 ? (
          <Grid item lg={12} xs={12} sm={12} md={12} className="grid_padding">
            <Grid container className="cummulative_paper">
              {smbStringList.length > 0 ? (
                smbStringList
                  .sort((a, b) => {
                    return a.localeCompare(b);
                  })
                  .map((ele, index) => (
                    <Grid
                      item
                      key={index}
                      lg={1.5}
                      xs={12}
                      sm={2.5}
                      md={1.8}
                      onClick={() => handleSMBStringDetails(index)}
                      className="flex-start_gap"
                    >
                      <Paper
                        className="inv-names_paper"
                        sx={{
                          backgroundColor: selectedDeviceStringIds.includes(
                            index
                          )
                            ? "#FF472E !important"
                            : "#ffff",
                          border: !selectedDeviceStringIds.includes(index)
                            ? "0.0625rem solid #D8D8D8"
                            : "none",
                        }}
                      >
                        <div className="smb-names_div">
                          <p
                            className="Poppins_16px_green"
                            style={{
                              color: selectedDeviceStringIds.includes(index)
                                ? "#ffff"
                                : "#2F67F8",
                            }}
                          >
                            {ele}
                          </p>
                          <p
                            className="Poppins_16px_green"
                            style={{
                              color: selectedDeviceStringIds.includes(index)
                                ? "#ffff"
                                : "#212121",
                            }}
                          >
                            A
                          </p>
                        </div>
                      </Paper>
                    </Grid>
                  ))
              ) : (
                <div className="no-data">
                  <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
                    No data is available!
                  </p>
                </div>
              )}
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        <Grid item lg={12} xs={12} sm={12} md={12} className="exc-graph-grid">
          <Paper className="exc-graph-paper">
            <BarPlotForIOT
              isValueData={[]}
              StartTimer=""
              StopTimer=""
              openDrawerValue={setDrawerOpen}
              averagePerformaceRatio={[]}
              titleText="Forecasted Active Power"
              tooltipName="Power"
              indicateUnit="kWh"
              calenderClick="off"
              selectedDateRangeProp={[
                {
                  startDate: new Date(
                    new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                  ),
                  endDate: new Date(),
                  key: "selection",
                },
              ]}
            />
          </Paper>
        </Grid>
        </>
  );
}

export default SmbDetails;
