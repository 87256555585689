import React, { useState, useRef, useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

function ButtonSlider(props) {
  const { isValueData, handleInvDeatil, activeIndex ,itemwidth} = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(12); // Default items per page
  const [showArrows, setShowArrows] = useState(false);
  const containerRef = useRef(null);
  const buttonsRef = useRef([]);

  const totalPages = Math.ceil(isValueData.length / itemsPerPage);

  // Function to calculate items per page based on container width
  const calculateItemsPerPage = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      // const itemWidth = 150; // Approximate width of each item (adjust as needed)
      const calculatedItemsPerPage = Math.floor(containerWidth / itemwidth);
      setItemsPerPage(calculatedItemsPerPage > 0 ? calculatedItemsPerPage : 1);
    }
  };

  useEffect(() => {
    calculateItemsPerPage(); // Initial calculation
    window.addEventListener("resize", calculateItemsPerPage); // Recalculate on resize

    return () => {
      window.removeEventListener("resize", calculateItemsPerPage); // Cleanup
    };
  }, [isValueData]);

  useEffect(() => {
    if (containerRef.current && buttonsRef.current.length > 0) {
      const containerWidth = containerRef.current.offsetWidth;
      const totalButtonsWidth = buttonsRef.current.reduce(
        (total, btn) => total + (btn ? btn.offsetWidth : 0),
        0
      );
      setShowArrows(totalButtonsWidth > containerWidth * 0.8 || totalPages > 1);
    }
  }, [isValueData, itemsPerPage, currentPage, totalPages]);

  const handleNextPage = () => {
    if (currentPage + 1 < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleInvDetails = (inverter, id, index) => {
    
    handleInvDeatil(inverter, id, index);
  };

  return (
    <Grid container className="inv_names_container">
      <Grid
        item
        lg={12}
        xs={12}
        sm={12}
        md={12}
        className="only_flex-start gap_point_3rem"
      >
        {currentPage > 0 && isValueData.length > 0 && (
          <Grid item className="a-center_j-flex-start">
            <div
              onClick={handlePrevPage}
              className="arrow-btn"
              style={{
                backgroundColor: currentPage === 0 ? "#ebe7e7" : "#2F67F8",
                border: "1px solid #D8D8D8",
                cursor: currentPage === 0 ? "not-allowed" : "pointer",
                pointerEvents: currentPage === 0 ? "none" : "auto",
              }}
            >
              <ChevronLeftIcon
                sx={{ color: currentPage === 0 ? "#b5afaf" : "white" }}
              />
            </div>
          </Grid>
        )}
        <Grid container className="inv-names_grid" ref={containerRef}>
          {isValueData.length > 0 ? (
            <>
              {isValueData
                .slice(
                  currentPage * itemsPerPage,
                  (currentPage + 1) * itemsPerPage
                )
                .map((ele, index) => {
                  const globalIndex = currentPage * itemsPerPage + index;

                  return (
                    <Grid
                      item
                      key={index}
                      onClick={() =>
                        handleInvDetails(
                          ele,
                          ele?.id || ele?.device_details?.id || ele?.NodeID,
                          globalIndex
                        )
                      }
                      sx={{ flex: "0 0 auto", width: "auto" }}
                      className="flex-start_gap"
                      ref={(el) => (buttonsRef.current[globalIndex] = el)}
                    >
                      <Paper
                        className="inv-names_paper"
                        sx={{
                          width: "fit-content",
                          backgroundColor:
                            activeIndex === globalIndex ? "#2F67F8" : "#ffff",
                          border: "0.0625rem solid #D8D8D8 !important",
                        }}
                      >
                        <div
                          className="inv-names_div"
                          style={{
                            justifyContent: "center",
                            backgroundColor:
                              activeIndex === globalIndex ? "#2F67F8" : "#ffff",
                          }}
                        >
                          <p
                            className="Poppins_18px_rem"
                            style={{
                              color:
                                activeIndex === globalIndex ? "#ffff" : "#2F67F8",
                            }}
                          >
                            {ele?.name ||
                              ele?.device_details?.name ||
                              ele?.NodeID}
                          </p>
                        </div>
                      </Paper>
                    </Grid>
                  );
                })}
            </>
          ) : (
            <div className="no-data">
              <p className="Poppins_16px_rem" style={{ fontWeight: "400" }}>
                No data is available!
              </p>
            </div>
          )}
        </Grid>
        {currentPage < totalPages - 1 && isValueData.length > 0 && (
          <Grid item className="a-center_j-flex-end">
            <div
              onClick={handleNextPage}
              className="arrow-btn"
              style={{
                backgroundColor:
                  currentPage === totalPages - 1 ? "#ebe7e7" : "#2F67F8",
                border: "1px solid #D8D8D8",
                cursor:
                  currentPage === totalPages - 1 ? "not-allowed" : "pointer",
                pointerEvents: currentPage === totalPages - 1 ? "none" : "auto",
              }}
            >
              <ChevronRightIcon
                sx={{
                  color: currentPage === totalPages - 1 ? "#b5afaf" : "white",
                }}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ButtonSlider;
