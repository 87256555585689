import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const DummyGraph = () => {
  const isValueData = [
    {
      smb_id: "SMB 1",
      "Active Energy Loss (kWh)": 2.5,
      "Apparent Energy Loss (kVAh)": 3.1,
    },
    {
      smb_id: "SMB 2",
      "Active Energy Loss (kWh)": 3.2,
      "Apparent Energy Loss (kVAh)": 4.0,
    },
    {
      smb_id: "SMB 3",
      "Active Energy Loss (kWh)": 1.8,
      "Apparent Energy Loss (kVAh)": 2.5,
    },
    {
      smb_id: "SMB 4",
      "Active Energy Loss (kWh)": 4.5,
      "Apparent Energy Loss (kVAh)": 5.3,
    },
    {
      smb_id: "SMB 5",
      "Active Energy Loss (kWh)": 2.9,
      "Apparent Energy Loss (kVAh)": 3.6,
    },
  ];

  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);

  useEffect(() => {
    // Extract data for x-axis and y-axis
    const categories = isValueData.map((item) => item.smb_id);
    const activeEnergyLoss = isValueData.map(
      (item) => item["Active Energy Loss (kWh)"]
    );
    const apparentEnergyLoss = isValueData.map(
      (item) => item["Apparent Energy Loss (kVAh)"]
    );

    // Set chart options and series
    setChartOptions({
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories,
      },
      yaxis: [
        {
          title: {
            text: "Active Energy Loss (kWh)",
            // style: { color: "#FF5733" },
          },
          labels: {
            formatter: (value) => value.toFixed(2),
          },
        },
        {
          opposite: true,
          title: {
            text: "Apparent Energy Loss (kVAh)",
            // style: { color: "#33B5FF" },
          },
          labels: {
            formatter: (value) => value.toFixed(2),
          },
        },
      ],
      stroke: {
        width: [2, 2],
        curve: "smooth",
      },
      legend: {
        position: "bottom",
      },
    });

    setChartSeries([
      {
        name: "Active Energy Loss (kWh)",
        type: "line",
        data: activeEnergyLoss,
      },
      {
        name: "Apparent Energy Loss (kVAh)",
        type: "line",
        data: apparentEnergyLoss,
      },
    ]);
  }, []);

  return (
    <div style={{width:"100%"}}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="line"
        height={300}
        // width={500}
      />
    </div>
  );
};

export default DummyGraph;
