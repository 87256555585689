import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import DownloadIcon from "@mui/icons-material/Download";
import "./BarPlotForIOT.scss";
import { Tooltip } from "@mui/material";
import ModalCalender from "../Common/ModalCalender";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import Dropdown from "react-dropdown";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import Divider from "@mui/material/Divider";
const BarPlotForIOT = (props) => {
  const {
    isValueData = {},
    indicateUnit,
    titleText,
    calenderClick,
    selectedDateRangeProp,
    isMobileAndTab,
    isDesktopView,
    isFullScreen, toggleFullScreen, isOnlyMobile,
    showTotal
  } = props;

  const seriesData = isValueData?.series || [];
  const [series, setSeries] = useState(seriesData);
  // const [isFullScreen, setIsFullScreen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const scrollableContainerRef = useRef(null);
  const [isOptionType, setIsOptionType] = useState("");
  const typeValue = ["Select", "Daily", "Weekly", "Monthly"];

  const handleChangeDeviceBox = (devicebox) => {
    setIsOptionType(devicebox.value);
    props.selectedType(devicebox.value);
  };

  

  const [options, setOptions] = useState({
    chart: { type: isValueData?.chart?.type ?? "bar" },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
      },
    },
    colors: ['#90E900', '#4287f5'],
    dataLabels: { enabled: false },
    xaxis: {
      categories: isValueData?.categories || [],
      labels: { style: { colors: "#000000" }, rotate: 0 },
    },
    yaxis: {
      title: { text: isValueData?.yaxis?.title?.text ?? "--" },
      labels: {
        formatter: (value) => Math.round(value),
        style: { colors: "#000000" },
      },
    },
    grid: {
      show: true,
      borderColor: "#BDBDBD",
      strokeDashArray: 5,
      position: "back",
      xaxis: { lines: { show: true } },
    },
    annotations: {
      yaxis: series[0]?.statistics?.average
      ? [
          {
            y: series[0]?.statistics?.average,
            borderColor: "#FF4560",
            strokeDashArray: 5,
            label: {
              borderColor: "#FF4560",
              style: { color: "#fff", background: "#FF4560" },
              text: `Average: ${series[0]?.statistics?.average} ${indicateUnit}`,
            },
          },
        ]
      : [],
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const actualValue = series[seriesIndex][dataPointIndex];
          const seriesName = w.config.series[seriesIndex].name;
          let tooltipContent = `
              <div class="arrow_box">
                  <span class="raleway_12px_rem">
                      <span style="color: #212121;">Date:</span> ${w.config.xaxis.categories[dataPointIndex]}
                  </span><br/>
                  <span class="raleway_12px_rem">
                      <span style="color: #90E900;">${seriesName}:</span> ${parseFloat(actualValue).toFixed(2)} ${indicateUnit}
                  </span><br/>
          `;
          for (let i = 0; i < w.config.series.length; i++) {
              if (i === seriesIndex) continue;
  
              const forecastedValue = series[i][dataPointIndex];
              const forecastedSeriesName = w.config.series[i].name;
  
              tooltipContent += `
                  <span class="raleway_12px_rem">
                      <span style="color: #2F67F8;">${forecastedSeriesName}:</span> ${parseFloat(forecastedValue).toFixed(2)} ${indicateUnit}
                  </span><br/>
              `;
          }
  
          tooltipContent += `</div>`;
          
          return tooltipContent;
      },
    },
    toolbar: { show: false },
  });

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        toggleFullScreen(); 
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [toggleFullScreen]);
  useEffect(() => {
    setSeries(isValueData?.series || []);

    setOptions((prevOptions) => ({
      ...prevOptions,
      plotOptions: {
        bar: { horizontal: false, columnWidth: "30%" },
      },
      xaxis: {
        ...prevOptions.xaxis,
        categories: isValueData?.categories || [],
        labels: { style: { colors: "#000000" } },
      },
      annotations: {
        yaxis: series[0]?.statistics?.average
        ? [
            {
              y: series[0]?.statistics?.average,
              borderColor: "#FF4560",
              strokeDashArray: 5,
              label: {
                borderColor: "#FF4560",
                style: { color: "#fff", background: "#FF4560" },
                text: `Average: ${series[0]?.statistics?.average} ${indicateUnit}`,
              },
            },
          ]
        : [],
      },
      tooltip: {
        shared: true,
        intersect: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const actualValue = series[seriesIndex][dataPointIndex];
            const seriesName = w.config.series[seriesIndex].name;
    
            let tooltipContent = `
                <div class="arrow_box">
                    <span class="raleway_12px_rem">
                        <span style="color: #212121;">Date:</span> ${w.config.xaxis.categories[dataPointIndex]}
                    </span><br/>
                    <span class="raleway_12px_rem">
                        <span style="color: #90E900;">${seriesName}:</span> ${parseFloat(actualValue).toFixed(2)} ${indicateUnit}
                    </span><br/>
            `;
    
            for (let i = 0; i < w.config.series.length; i++) {
                if (i === seriesIndex) continue;
    
                const forecastedValue = series[i][dataPointIndex];
                const forecastedSeriesName = w.config.series[i].name;
    
                tooltipContent += `
                    <span class="raleway_12px_rem">
                        <span style="color: #2F67F8;">${forecastedSeriesName}:</span> ${parseFloat(forecastedValue).toFixed(2)} ${indicateUnit}
                    </span><br/>
                `;
            }
    
            tooltipContent += `</div>`;
            
            return tooltipContent;
        },
    },
    
      yaxis: {
        ...prevOptions.yaxis,
        title: { text: isValueData?.yaxis?.title?.text ?? "--" },
        labels: {
          formatter: (value) => Math.round(value),
          style: { colors: "#000000" },
        },
      },
    }));
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft = scrollableContainerRef.current.scrollWidth;
    }
  }, [isValueData, isFullScreen, indicateUnit, series]);

  const chartContainerRef = useRef(null);
  
  const downloadChart = () => {
    const chartContainer = chartContainerRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const chartImage = chartContainer.querySelector("svg");
    const xml = new XMLSerializer().serializeToString(chartImage);
    const svg64 = btoa(xml);
    const imgSrc = `data:image/svg+xml;base64,${svg64}`;
    const img = new Image();
    img.src = imgSrc;
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const a = document.createElement("a");
      a.href = canvas.toDataURL("image/png");
      a.download = "chart.png";
      a.click();
    };
  };

  const handleTimings = () => {
    setOpenDrawer(true);
  };
 
  const handleStartDateTimeChange = (date) => {
    props.StartTimer(date);
    
    props.openDrawerValue(openDrawer);
  };

  const handleStopDateTimeChange = (date) => {
    props.StopTimer(date);
   
    props.openDrawerValue(openDrawer);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1vw",
          alignItems: "center",
        }}
      >
        <div>
          <p
            className="Poppins_18px_rem"
            style={{ color: "#212121"}}
          >
            {isValueData?.title?.text ?? titleText}
          </p>{" "}
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",

            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Download" arrow>
            <DownloadIcon
              onClick={downloadChart}
              className='icon-css'
            />
          </Tooltip>
          {isValueData?.title?.text === "Generation (kWh)" && (
            <Dropdown
              className="recommendations-text"
              options={typeValue}
              onChange={handleChangeDeviceBox}
              value={isOptionType}
            />
          )}

          <Tooltip title="Calendar" arrow>
            <CalendarMonthRoundedIcon
              onClick={handleTimings}
              className='icon-css'
            />
          </Tooltip>
          <Tooltip title="FullScreen" arrow>
            <button
              onClick={toggleFullScreen}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              {isFullScreen ? (
                <FullscreenExitIcon
                 className='icon-css'
                />
              ) : (
                <FullscreenIcon
                 className='icon-css'
                />
              )}
            </button>
          </Tooltip>
        </div>
      </div>
      <Divider style={{ border: "1px solid #D9D9D9" }} />
      {openDrawer && (
        <ModalCalender
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          handleStopDateChange={handleStopDateTimeChange}
          handleStartDateChange={handleStartDateTimeChange}
          selectedDateRangeVal={selectedDateRangeProp}
          singledaySelect={false}
        />
      )}
      <div
        style={{
          // height: Object.keys(isValueData).length === 0 ? "350px" : "90%",
          // width: "100%",
          // padding: "1vw",
          height: (isOnlyMobile && isFullScreen) ? "100vw" : Object.keys(isValueData).length === 0 ? "350px" : "90%",
          width: (isOnlyMobile && isFullScreen) ? "84vh" : "100%",
          padding: "1vw",
          transform: (isOnlyMobile && isFullScreen) ? "rotate(90deg)" : "rotate(0deg)",
          marginTop: (isOnlyMobile && isFullScreen) ? "4rem" : "0rem",
          marginLeft: (isOnlyMobile && isFullScreen) ? "-7rem" : "0rem"
        }}
        ref={chartContainerRef}
        id="barplotforIotchart"
      >
        {Object.keys(isValueData).length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "350px",

              alignItems: "center",
            }}
          >
            <p className="Poppins_18px_rem">No Data</p>
          </div>
        ) : (
          <div ref={scrollableContainerRef} className="scrollable_chat-container">
        <div style={{ width: isValueData?.categories.length > 20 ? isValueData?.categories.length * 50 : '100%' }}>
        {showTotal ? <p>Total Energy : <span style={{fontWeight:"700"}}>{series[0]?.statistics?.sum} {indicateUnit}</span><span> (as per selected date range)</span></p> : 
        ""}
          <Chart options={options} series={series} type={isValueData?.chart?.type ?? "bar"} 
          height={(isFullScreen && isMobileAndTab) ? "300" : (isFullScreen && isDesktopView) ? "500" : "400"}
           />
          </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BarPlotForIOT;
