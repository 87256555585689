import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import "./FileUpload.scss";
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";

import { Document, Page, pdfjs } from "react-pdf";
import mammoth from "mammoth";
import { styled } from "@mui/system";
import SimpleBackdrop from "../../../LifeScience/components/common/SimpleBackdrop";
import TextToSpeech from "../../Common/TextToSpeech";
import ReactMarkdown from "react-markdown";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TableSortLabel from "@mui/material/TableSortLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { CosmicInverterService } from "../../Services/CosmicInverterService";
import useErrorHandler from "../../../utils/errorHandler";
const invService = new CosmicInverterService();
const handleFetchError = useErrorHandler;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: "#FAFAFA",
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1849D6",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

const styles = {
  chatWindow: {
    display: "flex",
    width: "100%",
    height: "70vh",
    flexDirection: "column",
    overflowY: "auto",
    border: "0.0625rem solid  #F4F4F4",
    padding: "1rem",
    borderRadius: "0.3125rem",
    position: "relative",
  },
  profileImage: {
    width: "2.5rem",
    height: "2.5rem",
    borderRadius: "50%",
    margin: "0rem 0.625rem",
  },
  userMessage: {
    alignSelf: "flex-end",
    // background: "#FEF1B1",
    backgroundColor: "#ECF5FF",

    borderRadius: "1.5rem 0rem 1.5rem 1.5rem",
    wordBreak: "break-word",
    padding: "1rem",
    textAlign: "right",
    width: "fit-content",
  },
  messageDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    marginLeft: "10%",
    marginBottom: "0.625rem",
  },
  userDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginRight: "10%",
    marginBottom: "0.625rem",
  },
  aiMessage: {
    alignSelf: "flex-start",
    // background: "#D9E5F0",
    backgroundColor: "#F7F7F9",

    borderRadius: "0rem 1.5rem 1.5rem 1.5rem",
    width: "fit-content",

    wordBreak: "break-word",
    padding: "1rem",

    textAlign: "left",
  },
};
function FileUpload() {
  const [currentlyStreaming, setCurrentlyStreaming] = useState(false);
  const [isPreTemplate, setIsPreTemplate] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [docContent, setDocContent] = useState([]);
  const [pdfList, setPdfList] = useState([]);
  const [showPdf, setShowPdf] = useState(false);

  const [error, setError] = useState(null);
  const [userMessages, setUserMessages] = useState([
    { role: "ai", content: "" },
  ]);
  const [fileView, setFileView] = useState("list");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [progress, setProgress] = useState(0);
  const [currentPlayingId, setCurrentPlayingId] = useState(null);
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState(null);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [loadingState, setLoadingState] = useState({
    chatData: false,
    getList: false,
    uploadFile: false,
    pdfView: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [threefiles, setThreeFiles] = useState([]);
  const [visibleItems, setVisibleItems] = useState(6);
  const chatWindowRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    handleDocList();
  }, []);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [userMessages, loadingState.chatData]);
  const searchedTabs = [
    {
      question: "Summarize the key terms outlined in this document?",
    },
    {
      question: "List any deadlines or timelines mentioned in this document?",
    },
    {
      question: "Explain the terms for renewal or amendment in this document?",
    },
  ];

  const onDrop = async (acceptedFiles) => {
    const newFile = acceptedFiles[0];
    setUserMessages([]);
    setCurrentPage(1);

    if (
      newFile &&
      (newFile.type === "application/pdf" ||
        newFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
    ) {
      setLoadingState((prevState) => ({ ...prevState, uploadFile: true }));
      setSelectedFile(newFile);
      setFileName(newFile.name);
      setError(null);
      let progress = 0;
      setProgress(progress);
      const totalUploadTime = 5000; // Total time for the upload in milliseconds (5 seconds for example)
      const intervalStep = 10; // Progress step in percentage (10% increments)
      const intervalTime = totalUploadTime / (100 / intervalStep);

      const interval = setInterval(async () => {
        progress += intervalStep;
        if (progress >= 100) {
          clearInterval(interval);
          progress = 100;
          setProgress(progress);
          handleUploadFile(newFile);
        } else {
          setProgress(progress);
        }
      }, intervalTime);
    } else {
      setError("Please upload a single PDF or DOCX file.");
    }
  };

  const handleUploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const localPreviewUrl = URL.createObjectURL(file);

    try {
      setLoadingState((prevState) => ({ ...prevState, uploadFile: true }));

      const res = await invService.postFileUpload(formData);
      if (res.status === 200) {
        setSelectedFile(res.data);
        const uploadedFile = {
          request_id: res.data.request_id,
          document_name: res.data.document_name,
          download_url: res.data.download_url || localPreviewUrl,
        };

        setThreeFiles((prevFiles) => {
          const updatedFiles = [...prevFiles, uploadedFile];
          return updatedFiles.slice(-3);
        });
        setShowPdf(true);

        if (file.type === "application/pdf") {
          const pdfUrl = res.data.download_url || localPreviewUrl; // Use the uploaded file's URL or preview
          setPdfUrl(pdfUrl);
          setDocContent([]); // Clear DOCX content if PDF is uploaded
        } else if (
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const arrayBuffer = e.target.result;
            const result = await mammoth.convertToHtml({ arrayBuffer });
            const content = result.value;

            const words = content.split(/\s+/);
            const contentArray = [];
            for (let i = 0; i < words.length; i += 500) {
              contentArray.push(words.slice(i, i + 500).join(" "));
            }

            setDocContent(contentArray); // Store content pages
            setNumPages(contentArray.length); // Set number of pages for DOCX
            setPdfUrl(null); // Clear PDF content if DOCX is uploaded
          };
          reader.readAsArrayBuffer(file);
        }

        setLoadingState((prevState) => ({ ...prevState, uploadFile: false }));
        enqueueSnackbar("File uploaded successfully", { variant: "success" });
      } else {
        setLoadingState((prevState) => ({ ...prevState, uploadFile: false }));
        enqueueSnackbar("File upload failed. Please try again.", {
          variant: "warning",
        });
      }
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, uploadFile: false }));
   
      handleFetchError(error, "An error occurred during the upload.");

    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setProgress(0);

    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".pdf, .doc, .docx",

    maxSize: 10 * 1024 * 1024,
    multiple: false,
  });
  const handleSerchedQue = (item, index) => {
    setIsPreTemplate(true);
    sendChatMessage(item.question);
  };
  const handleDocList = async () => {
    try {
      setLoadingState((prevState) => ({ ...prevState, getList: true }));

      const res = await invService.getListDocs();
      if (res.status === 200) {
        const valueArray = [];

        res.data.forEach((item) => {
          valueArray.push({
            document_name: item.document_name ? item.document_name : "--",
            date: "--",
            request_id: item.request_id ? item.request_id : "--",

            download_url: item.download_url ? item.download_url : "--",
          });
        });
        setPdfList(valueArray);

        setThreeFiles((prevFiles) => {
          const updatedFiles = [...prevFiles, ...res.data];
          return updatedFiles.slice(-3);
        });
      }
      setLoadingState((prevState) => ({ ...prevState, getList: false }));
    } catch (error) {
      setLoadingState((prevState) => ({ ...prevState, getList: false }));
      handleFetchError(error, "pdf list");
    }
  };
  const handlePlayStop = (id) => {
    setCurrentPlayingId(currentPlayingId === id ? null : id);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleEditClick = (content) => {
    setMessage(content);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  const handleViewMore = () => {
    setVisibleItems((prev) => prev + 6);
  };
  const sendChatMessage = async (content) => {
    if (currentlyStreaming) {
      enqueueSnackbar("Please wait until the current message is completed.", {
        variant: "warning",
      });
      return;
    }
    if (content.length === 0) {
      enqueueSnackbar("Message cannot be empty", { variant: "warning" });
      return;
    }
    setLoadingState((prevState) => ({ ...prevState, chatData: true }));
    setIsPreTemplate(true);

    const newMessageStrings = [];

    let lastAiMessage = "";
    let lastUserMessage = "";

    for (let i = userMessages.length - 1; i >= 0; i--) {
      const message = userMessages[i];
      if (message.role === "user" && !lastUserMessage) {
        lastUserMessage = message.content || " ";
      } else if (message.role === "ai" && !lastAiMessage) {
        lastAiMessage = message.content;
      }

      if (lastAiMessage && lastUserMessage) {
        break;
      }
    }

    if (userMessages.length > 1) {
      newMessageStrings.push(lastUserMessage);
      newMessageStrings.push(lastAiMessage);
      newMessageStrings.push(content);
    } else {
      newMessageStrings.push(lastAiMessage);
      newMessageStrings.push(content);
    }

    setUserMessages((prevUserMessages) => [
      ...prevUserMessages,
      { role: "user", content: content },
    ]);
    setMessage("");

    const reqData = {
      request_id: selectedFile.request_id,
      document_name: selectedFile.document_name,
      question: content,
    };

    try {
      const res = await invService.pdfChat(reqData);

      if (res.status === 200 && res.data) {
        const aiMessage = res.data.answer;

        streamAiMessage(aiMessage);
      } else if (res?.data?.length <= 0) {
        enqueueSnackbar("No data available,", {
          variant: "warning",
        });
      }
      setLoadingState((prevState) => ({ ...prevState, chatData: false }));
    } catch (err) {
      setLoadingState((prevState) => ({ ...prevState, chatData: false }));
      handleFetchError(err, "No chats  data found");
    }
  };
  const streamAiMessage = (aiMessage) => {
    // Add an extra space to the message to ensure spacing is handled correctly
    const words = (aiMessage + " ").split(" ");
    let wordIndex = 0;
    let accumulatedMessage = "";

    const updateInterval = 70;
    const maxInterval = words.length * updateInterval;
    let currentInterval = 0;

    setCurrentlyStreaming(true);

    const interval = setInterval(() => {
      setUserMessages((prevUserMessages) => {
        const lastMessageIndex = prevUserMessages.length - 1;
        const lastMessage =
          lastMessageIndex >= 0 ? prevUserMessages[lastMessageIndex] : null;

        // If no AI message exists, initialize the first AI message
        if (!lastMessage || lastMessage.role !== "ai") {
          return [
            ...prevUserMessages,
            { role: "ai", content: accumulatedMessage },
          ];
        }

        if (wordIndex < words.length - 1) {
          accumulatedMessage +=
            (accumulatedMessage ? " " : "") + words[wordIndex];
          wordIndex += 1;
        }

        const updatedMessages = [...prevUserMessages];
        updatedMessages[lastMessageIndex] = {
          ...lastMessage,
          content: accumulatedMessage,
        };

        if (wordIndex >= words.length - 1) {
          clearInterval(interval);
          setCurrentlyStreaming(false);

          // Ensure the last word is added correctly
          updatedMessages[lastMessageIndex] = {
            ...lastMessage,
            content: accumulatedMessage.trim(),
          };
        }

        return updatedMessages;
      });

      currentInterval += updateInterval;

      if (currentInterval >= maxInterval) {
        clearInterval(interval);
        setCurrentlyStreaming(false);
      }
    }, updateInterval);
  };
  const handlePdfDoc = (ele) => {
    setIsPreTemplate(false);
    setThreeFiles((prevFiles) => {
      if (prevFiles.some((file) => file.request_id === ele.request_id)) {
        return prevFiles;
      }

      const updatedFiles = [...prevFiles, ele];
      return updatedFiles.slice(-3);
    });
    setShowPdf(true);
    setUserMessages([]);
    setCurrentPage(1);
    setSelectedFile(ele);
    setLoadingState((prevState) => ({ ...prevState, pdfView: true }));

    if (ele.document_name.endsWith(".pdf")) {
      setPdfUrl(ele.download_url);
      setDocContent([]);
      setLoadingState((prevState) => ({ ...prevState, pdfView: false }));
    } else if (ele.document_name.endsWith(".docx")) {
      setPdfUrl(null);
      fetch(ele.download_url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          mammoth
            .convertToHtml({ arrayBuffer })
            .then((result) => {
              const content = result.value;
              const contentArray = content
                .split(/\s+/)
                .reduce((acc, _, idx, arr) => {
                  if (idx % 500 === 0)
                    acc.push(arr.slice(idx, idx + 500).join(" "));
                  return acc;
                }, []);

              setDocContent(contentArray);
              setLoadingState((prevState) => ({
                ...prevState,
                pdfView: false,
              }));
            })
            .catch((error) => {
              console.error("Error converting DOCX:", error);
              setDocContent([]);
              setLoadingState((prevState) => ({
                ...prevState,
                pdfView: false,
              }));
            });
        })
        .catch((error) => {
          console.error("Error fetching DOCX file:", error);
          setDocContent([]);
        });
    }
  };

  const addWidthToImages = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    const images = tempDiv.querySelectorAll("img");
    images.forEach((img) => {
      img.style.width = "100%"; // Set the desired width here, e.g., 100px
    });

    return tempDiv.innerHTML;
  };
  const handleViewAll = () => {
    setFileName(null);
    setShowPdf(false);
  };
  const columns = [
    { field: "document_name", title: "File Name" },
    { field: "date", title: "Date" },
    { field: "download", title: "Download" },
  ];
  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
      : (a, b) => ascendingComparator(a[orderBy], b[orderBy]);
  };

  const ascendingComparator = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const descendingComparator = (a, b) => {
    if (b < a) return -1;
    if (b > a) return 1;
    return 0;
  };

  const handleViewList = (ele) => {
    setFileView(ele);
  };

  const handleHistoryDotsClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };
  const handleDownloadClick = (downloadUrl) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = true;
    link.click();

    handlePopoverClose();
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoadingState((prevState) => ({
      ...prevState,
      pdfView: false,
    }));
  };
  const handleLoadStart = () => {
    setLoadingState((prevState) => ({
      ...prevState,
      pdfView: true,
    }));
  };

  const filteredPdfList = pdfList.filter((ele) =>
    ele.document_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="home-container">
      <Grid
        container
        className="file-container gap_2vw"
        style={{ height: pdfList.length !== 0 ? "auto" : "100%" }}
      >
        {showPdf ? (
          <Grid
            item
            lg={12}
            xs={12}
            sm={12}
            md={12}
            className="powerplant-sec-paper hei_auto "
          >
            <Grid
              container
              className="grid-container"
              style={{ position: "relative" }}
            >
              {loadingState.uploadFile && (
                <SimpleBackdrop open={loadingState.uploadFile} />
              )}
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="powerplant-sec-paper"
              >
                <Paper className="powerplant-sec-paper gap_1vw foo_one">
                  <div>
                    <p className="Poppins_20px_rem c_lightdrak font_500">
                      Recent Files
                    </p>
                  </div>
                </Paper>
              </Grid>

              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="powerplant-sec-paper"
              >
                <Paper className="powerplant-sec-paper gap_1vw foo_one">
                  <div
                    className="expert_div gap_1vw width_pdflist display_none"
                    id="#justify-start"
                  >
                    {loadingState.getList && (
                      <SimpleBackdrop open={loadingState.getList} />
                    )}
                    {threefiles.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            backgroundColor:
                              ele.request_id === selectedFile.request_id
                                ? "#A6C5FA"
                                : "white",
                          }}
                          className="svg_upload_name  svg_upload  gap_1vw expert_div width_pdfdata"
                          onClick={() => handlePdfDoc(ele)}
                        >
                          <img
                            src={
                              ele.document_name.endsWith(".pdf")
                                ? "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/pdficon.svg"
                                : "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/docicon.svg"
                            }
                            alt={
                              ele.document_name.endsWith(".pdf")
                                ? "PDF Icon"
                                : "DOC Icon"
                            }
                          />

                          <p
                            className="search_Text c_black font_500 "
                            id="scrollname"
                          >
                            {ele.document_name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <div className="foo_one gap_1vw">
                    <div>
                      <Button
                        type="submit"
                        variant="contained"
                        className="file-upload-w "
                        onClick={handleViewAll}
                      >
                        View all
                        <ArrowForwardIcon sx={{ marginRight: "1vw" }} />
                      </Button>
                    </div>
                    <input
                      {...getInputProps({ accept: ".pdf, .doc, .docx" })}
                    />

                    <Button
                      type="submit"
                      variant="contained"
                      className="file-upload-w"
                      onClick={() =>
                        document.querySelector('input[type="file"]').click()
                      }
                      sx={{ mt: 0 }}
                    >
                      Add Files
                    </Button>
                  </div>
                </Paper>
              </Grid>
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                md={12}
                className="powerplant-sec-paper"
              >
                <Paper className="powerplant-sec-paper gap_1vw foo_one">
                  <div>
                    <p className="Poppins_20px_rem c_black font_500">
                      {selectedFile.document_name}
                    </p>
                  </div>
                </Paper>
              </Grid>

              <Grid
                item
                lg={6.95}
                xs={12}
                sm={12}
                md={12}
                className="scrolling-grid-exc"
              >
                <Paper className="inv_total_paper gap_1vw align_item">
                  <div className="foo_one height_w flex_col">
                    {!isPreTemplate ? (
                      <div
                        style={styles.chatWindow}
                        className="msgview expert_div"
                        ref={chatWindowRef}
                      >
                        <div className="chat-middle-div">
                          <div className="chat-gif-div">
                            <img
                              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/ai_pdf.svg"
                              alt="chat-gif"
                            />

                            <p className="Poppins_20px_rem c_black font_500">
                              Welcome to the AI Assistant{" "}
                            </p>
                            <p className="Poppins_16px_400 c_graymix">
                              How can I assist you?
                            </p>
                          </div>
                        </div>

                        <div className="search-div-pdfchat flex_col">
                          <div className="chat-gif-div">
                            <p className="Poppins_16px_400 c_graymix">
                              Type a question or choose a suggestion below.
                            </p>
                          </div>
                          {searchedTabs.map((item, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => handleSerchedQue(item, index)}
                                className="search_pdf_div foo_one div_width cursor_p"
                              >
                                <div className="smb_value_container">
                                  <p className="Poppins_12px_500">
                                    {item.question}
                                  </p>
                                </div>
                                <div className="expert_div">
                                  <img
                                    src="https://s3.amazonaws.com/smarttrak.co/newHomePage/send_q.svg"
                                    alt="arrow"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          style={styles.chatWindow}
                          className="msgview"
                          ref={chatWindowRef}
                        >
                          {userMessages
                            .filter((message) => message.content !== "")
                            .map((message, index) => (
                              <div key={index}>
                                {message.role === "user" ? (
                                  <div style={styles.messageDiv}>
                                    <div className="editChat">
                                      <EditIcon
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleEditClick(message.content);
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={styles.userMessage}>
                                        <p className="chatText" id="usermsg">
                                          <ReactMarkdown>
                                            {message.content}
                                          </ReactMarkdown>{" "}
                                        </p>
                                      </div>
                                      <div></div>
                                    </div>
                                    <img
                                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/profilephoto.svg"
                                      alt="User Profile"
                                      style={styles.profileImage}
                                    />
                                  </div>
                                ) : (
                                  <div style={styles.userDiv}>
                                    <img
                                      src="https://s3.amazonaws.com/smarttrak.co/v2Images/botChat.svg"
                                      alt="User Profile"
                                      style={styles.profileImage}
                                    />
                                    <div style={styles.aiMessage}>
                                      <p className="chatText">
                                        <ReactMarkdown>
                                          {message.content}
                                        </ReactMarkdown>
                                      </p>
                                      <TextToSpeech
                                        markdownText={message.content}
                                        key={index}
                                        id={index}
                                        isCurrentPlaying={
                                          currentPlayingId === index
                                        }
                                        onPlayStop={handlePlayStop}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          {loadingState.chatData && (
                            <div style={styles.aiMessage}>
                              <p className="loader"></p>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    <div className="main-message-box">
                      <div style={{ width: "100%", display: "flex" }}>
                        <TextField
                          className="message-box"
                          id="text-chat"
                          value={message}
                          onChange={handleMessageChange}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              if (currentlyStreaming) {
                                enqueueSnackbar(
                                  "Please wait until the current message is completed.",
                                  { variant: "warning" }
                                );
                                e.preventDefault();
                                return;
                              }
                              sendChatMessage(e.target.value);
                              e.target.value = "";
                              e.preventDefault();
                            }
                          }}
                          autoComplete="off"
                          InputProps={{
                            endAdornment: message ? (
                              <div
                                onClick={() => sendChatMessage(message)}
                                className="send-arrow arrow-bg"
                              >
                                <img
                                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/up_arrow_icon.svg"
                                  alt="arrow"
                                />
                              </div>
                            ) : (
                              <div
                                disabled
                                className="send-arrow"
                                style={{
                                  backgroundColor: "#E6F1ED",
                                  cursor: "no-drop",
                                  opacity: 0.5,
                                }}
                              >
                                <img
                                  src="https://s3.amazonaws.com/smarttrak.co/v2Images/up_arrow_icon.svg"
                                  alt="arrow"
                                />
                              </div>
                            ),
                            classes: { input: "Poppins_16px_400" },
                          }}
                          placeholder="Ask the AI assistant anything about this document"
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid
                item
                lg={4.95}
                xs={12}
                sm={12}
                md={12}
                className="scrolling-grid-exc "
              >
                <Paper
                  className="inv_total_paper gap_1vw align_item"
                  sx={{ position: "relative" }}
                >
                  {loadingState.pdfView && (
                    <SimpleBackdrop open={loadingState.pdfView} />
                  )}
                  <div className="expert_div flex_col div_width gap_1vw">
                    <div className="pdfurl">
                      {pdfUrl ? (
                        <Document
                          file={pdfUrl}
                          onLoadSuccess={handleLoadSuccess}
                          onLoadStart={handleLoadStart}
                          loading={<SimpleBackdrop open={true} />}
                        >
                          <Page pageNumber={currentPage} />
                        </Document>
                      ) : (
                        <div className="expert_div">
                          <p
                            className="Poppins_24px_black  font_500"
                            style={{ color: "red" }}
                          >
                            {error}
                          </p>
                        </div>
                      )}
                      {docContent.length > 0 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: addWidthToImages(
                              docContent[currentPage - 1]
                            ),
                          }}
                          style={{
                            padding: "20px",
                            border: "1px solid #ddd",
                          }}
                        />
                      ) : (
                        <div className="expert_div">
                          <p
                            className="Poppins_24px_black  font_500"
                            style={{ color: "red" }}
                          >
                            {error}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="expert_div gap_1vw">
                      {Array.from(new Array(numPages), (el, index) => (
                        <button
                          key={index}
                          onClick={() => handlePageChange(index + 1)}
                          className={`pagination-button ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                        >
                          {index + 1}
                        </button>
                      ))}
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            {pdfList.length !== 0 && (
              <>
                <Grid
                  item
                  lg={7.9}
                  xs={12}
                  sm={12}
                  md={12}
                  className="exc-graph-paper"
                >
                  <Paper className="powerplant-sec-paper gap_1vw foo_one flex_col  padding_top">
                    <div className="search-box r_border" id="search_pad">
                      <input
                        className="semibold-smalltext"
                        id="search-input"
                        value={searchTerm}
                        onChange={handleSearch}
                        type="text"
                        placeholder="Search Files Here..."
                      />
                      <div className="expert_div search_case_pdf r_border">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                        >
                          <path
                            d="M19.1129 17.5178L17.4596 15.9617M10.4329 16.7398C11.4642 16.7398 12.4855 16.5486 13.4383 16.1771C14.3911 15.8057 15.2568 15.2612 15.9861 14.5749C16.7153 13.8885 17.2938 13.0737 17.6885 12.177C18.0831 11.2802 18.2863 10.3191 18.2863 9.3484C18.2863 8.37775 18.0831 7.41661 17.6885 6.51985C17.2938 5.62308 16.7153 4.80827 15.9861 4.12191C15.2568 3.43556 14.3911 2.89112 13.4383 2.51967C12.4855 2.14821 11.4642 1.95703 10.4329 1.95703C8.35009 1.95703 6.35256 2.73576 4.87978 4.12191C3.40699 5.50807 2.57959 7.38809 2.57959 9.3484C2.57959 11.3087 3.40699 13.1887 4.87978 14.5749C6.35256 15.961 8.35009 16.7398 10.4329 16.7398V16.7398Z"
                            stroke="black"
                            stroke-width="1.25967"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </Paper>
                </Grid>

                <Grid
                  item
                  lg={7.9}
                  xs={12}
                  sm={12}
                  md={12}
                  className="powerplant-sec-paper align_item"
                >
                  <Paper className="exc-graph-paper gap_1vw foo_one flex_col">
                    <div className="foo_one div_width">
                      <div>
                        <p className="Poppins_16px_rem  font_500 ">
                          Click any document to start chatting with the AI
                          assistant for insights
                        </p>
                      </div>
                      <div className="expert_div">
                        <div
                          className="expert_div div_listing l_border cursor_p"
                          onClick={() => handleViewList("list")}
                          style={{
                            backgroundColor:
                              fileView === "list" ? "#FDA737" : "white",
                          }}
                        >
                          <img
                            src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/listView.svg"
                            alt=""
                          />
                        </div>
                        <div
                          className="expert_div div_listing r_border cursor_p"
                          onClick={() => handleViewList("grid")}
                          style={{
                            backgroundColor:
                              fileView === "grid" ? "#FDA737" : "white",
                          }}
                        >
                          <img
                            src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/fileView.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>

                    {fileView === "list" ? (
                      <TableContainer className="scroll_pdf table-scrollbar-pdf">
                        {filteredPdfList.length > 0 ? (
                          <div className="abt_table_scrol">
                            {pdfList.length === 0 ? (
                              <Table
                                stickyHeader
                                sx={{ minWidth: 650 }}
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      borderBottom:
                                        "0rem solid gray !important",
                                    }}
                                    colSpan={15}
                                    align="center"
                                    className="div-borderText"
                                  >
                                    No data available
                                  </TableCell>
                                </TableRow>
                                <Divider
                                  style={{ border: "1px solid #D9D9D9" }}
                                />
                              </Table>
                            ) : (
                              <Table
                                stickyHeader
                                sx={{ minWidth: 650 }}
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableHead>
                                  <TableRow>
                                    {columns.map((column) => (
                                      <TableCell
                                        key={column.field}
                                        align="left"
                                        sortDirection={
                                          orderBy === column.field
                                            ? order
                                            : false
                                        }
                                        className="alaram-check"
                                        sx={{
                                          backgroundColor: "#E6EEF5 !important",
                                        }}
                                      >
                                        {column.field === "download" ? (
                                          <p className="Poppins_20px_rem c_black font_500">
                                            {column.title}
                                          </p>
                                        ) : (
                                          <TableSortLabel
                                            active={orderBy === column.field}
                                            direction={
                                              orderBy === column.field
                                                ? order
                                                : "asc"
                                            }
                                            onClick={() => {
                                              setOrderBy(column.field);
                                              setOrder((prevOrder) =>
                                                prevOrder === "asc"
                                                  ? "desc"
                                                  : "asc"
                                              );
                                            }}
                                          >
                                            <p className="Poppins_20px_rem c_black font_500">
                                              {column.title}
                                            </p>
                                          </TableSortLabel>
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {stableSort(
                                    filteredPdfList,
                                    getComparator(order, orderBy)
                                  )
                                    .slice(0, visibleItems)
                                    .map((row, index) => (
                                      <TableRow
                                        id="file-b"
                                        key={index}
                                        sx={{
                                          textTransform: "capitalize",
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        <TableCell
                                          onClick={() => handlePdfDoc(row)}
                                          id="border-b"
                                          className="table_icpon gap_1vw"
                                          sx={{ cursor: "pointer" }}
                                          align="left"
                                          // onClick={() => handleSurge(row)}
                                        >
                                          <div className="table_icpon gap_1vw">
                                            {" "}
                                            <img
                                              src={
                                                row.document_name.endsWith(
                                                  ".pdf"
                                                )
                                                  ? "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/pdficon.svg"
                                                  : "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/docicon.svg"
                                              }
                                              alt={
                                                row.document_name.endsWith(
                                                  ".pdf"
                                                )
                                                  ? "PDF Icon"
                                                  : "DOC Icon"
                                              }
                                            />
                                            <p
                                              // id="scrollingHistory"
                                              className="Poppins_14px_rem font_500"
                                            >
                                              {row.document_name}
                                            </p>
                                          </div>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{ cursor: "pointer" }}
                                          align="left"
                                        >
                                          <p
                                            id="scrollingHistory"
                                            className="Poppins_14px_rem font_500"
                                          >
                                            12-11-2024
                                          </p>
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          sx={{ cursor: "pointer" }}
                                        >
                                          <p
                                            className="Poppins_14px_rem font_500 text_cen sol_sec"
                                            onClick={() => {
                                              handleDownloadClick(
                                                row.download_url
                                              );
                                            }}
                                          >
                                            <img
                                              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/download.svg"
                                              alt="share"
                                            />
                                          </p>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            )}
                          </div>
                        ) : (
                          <div className="expert_div div_width">
                            <p
                              className="Poppins_24px_black  font_500"
                              style={{ color: "red" }}
                            >
                              No relevant search found !
                            </p>
                          </div>
                        )}
                      </TableContainer>
                    ) : (
                      <>
                        <Grid
                          container
                          className="grid-container gap_1vw scroll_pdf"
                        >
                          {pdfList.length === 0 ? (
                            <div className="expert_div div_width no-data">
                              <p
                                className="Poppins_24px_black font_500"
                                style={{ textAlign: "center" }}
                              >
                                No data available!
                              </p>
                            </div>
                          ) : filteredPdfList.length > 0 ? (
                            filteredPdfList
                              .slice(0, visibleItems)
                              .map((ele, index) => {
                                const isPdf =
                                  ele.document_name.endsWith(".pdf");
                                const fileIcon = isPdf
                                  ? "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/pdficon.svg"
                                  : "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/docicon.svg";

                                return (
                                  <Grid
                                    key={index}
                                    item
                                    lg={3.7}
                                    xs={12}
                                    sm={12}
                                    md={3}
                                    className="exc-graph-paper"
                                  >
                                    <Paper
                                      className="powerplant-sec-paper gap_1vw foo_one "
                                      style={{ position: "relative" }}
                                    >
                                      <div className="grid_view  flex_col  expert_div gap_1vw div_width">
                                        <div className="foo_one div_width gap_1vw">
                                          <img
                                            src={
                                              isPdf
                                                ? "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/minipdf.svg"
                                                : "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/minidoc.svg"
                                            }
                                            alt="file icon"
                                          />
                                          <p
                                            id="scrollingHistory"
                                            className="Poppins_14px_rem font_400"
                                          >
                                            {ele.document_name}
                                          </p>
                                          <MoreVertIcon
                                            fontSize="small"
                                            className="cursor_p"
                                            onClick={(e) =>
                                              handleHistoryDotsClick(e, index)
                                            }
                                          />
                                        </div>

                                        <Popover
                                          open={
                                            Boolean(anchorEl) &&
                                            selectedIndex === index
                                          }
                                          anchorEl={anchorEl}
                                          onClose={handlePopoverClose}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                          }}
                                          transformOrigin={{
                                            vertical: "left",
                                            horizontal: "right",
                                          }}
                                          PaperProps={{
                                            className: "customPopoverPaper-p",
                                          }}
                                        >
                                          <MenuItem
                                            onClick={() =>
                                              handleDownloadClick(
                                                ele.download_url
                                              )
                                            }
                                            className="popover-menu"
                                          >
                                            <img
                                              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/download.svg"
                                              alt="share"
                                            />
                                            <p className="Poppins_14px_regular">
                                              Download
                                            </p>
                                          </MenuItem>
                                          <MenuItem
                                            onClick={handlePopoverClose}
                                            className="popover-menu"
                                          >
                                            <img
                                              src="https://s3.amazonaws.com/smarttrak.co/newHomePage/delete.svg"
                                              alt="share"
                                            />
                                            <p className="Poppins_14px_regular">
                                              Delete
                                            </p>
                                          </MenuItem>
                                        </Popover>
                                        <div
                                          className="file_bg div_width expert_div cursor_p"
                                          onClick={() => handlePdfDoc(ele)}
                                        >
                                          <img
                                            src={fileIcon}
                                            alt="file icon"
                                            className="file-icon"
                                          />
                                        </div>
                                        <div className="table_icpon div_width">
                                          <img
                                            src={
                                              "https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/opened.svg"
                                            }
                                            alt="file icon"
                                            className="file-icon"
                                          />

                                          <p className="Poppins_11px_rem ">
                                            Opened at
                                          </p>
                                          <p className="Poppins_11px_rem ">
                                            . Aug 16, 2024
                                          </p>
                                        </div>
                                      </div>
                                    </Paper>
                                  </Grid>
                                );
                              })
                          ) : (
                            <div className="expert_div div_width">
                              <p
                                className="Poppins_24px_black  font_500"
                                style={{ color: "red" }}
                              >
                                No relevant search found !
                              </p>
                            </div>
                          )}
                        </Grid>
                      </>
                    )}
                    {visibleItems < filteredPdfList.length && (
                      <div
                        className="table_icpon cursor_p div_width"
                        onClick={handleViewMore}
                      >
                        <p className="view_more Poppins_14px_rem font_500">
                          View More
                        </p>
                      </div>
                    )}
                  </Paper>
                </Grid>
              </>
            )}
            <Grid
              item
              lg={pdfList.length === 0 ? 12 : 3.8}
              xs={12}
              sm={12}
              md={12}
              className="energy-grid"
            >
              <Paper
                className="energy-grid flex_col gap_1vw"
                id="padding_4"
                style={{
                  position: "relative",
                  alignSelf: pdfList.length === 0 ? "center" : "flex-start",
                }}
              >
                {loadingState.uploadFile && (
                  <SimpleBackdrop open={loadingState.uploadFile} />
                )}
                <div className="expert_div flex_col gap_1vw ">
                  <p
                    className={`${
                      pdfList.length > 0
                        ? "Poppins_16px_rem"
                        : "Poppins_20px_rem"
                    } font_500 c_black text_cen`}
                  >
                    Upload new documents to explore with AI assistant
                  </p>
                </div>

                <div
                  className="expert_div flex_col gap_1vw svg_upload"
                  id="our_sol"
                >
                  <img
                    src="https://s3.us-east-1.amazonaws.com/smarttrak.co/newHomePage/upload.svg"
                    alt="file upload"
                  />
                  <p className="Poppins_20px_rem c_black font_400 text_cen">
                    Drag & drop files or <span className="c_blue">Browse</span>
                  </p>
                  <p className="search_Text c_mixGray text_cen">
                    {" "}
                    PDF or DOC/DOCX, file size no more than 10MB
                  </p>

                  <input {...getInputProps({ accept: ".pdf, .doc, .docx" })} />

                  <Button
                    type="submit"
                    variant="contained"
                    className="login-btn file-upload"
                    onClick={() =>
                      document.querySelector('input[type="file"]').click()
                    }
                    sx={{ mt: 0 }}
                  >
                    Select Files
                  </Button>
                </div>
                {fileName !== null && (
                  <div className="svg_upload_name  svg_upload padd_1vw gap_1vw expert_div flex_col div_width">
                    <div className="foo_one gap_1vw div_width">
                      <p className="search_Text c_black font_600 ">
                        {fileName}
                      </p>

                      <svg
                        width="21"
                        height="20"
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={handleCancel}
                        className="cursor_p"
                      >
                        <path
                          d="M13.09 6L10.5 8.59L7.91 6L6.5 7.41L9.09 10L6.5 12.59L7.91 14L10.5 11.41L13.09 14L14.5 12.59L11.91 10L14.5 7.41L13.09 6ZM10.5 0C4.97 0 0.5 4.47 0.5 10C0.5 15.53 4.97 20 10.5 20C16.03 20 20.5 15.53 20.5 10C20.5 4.47 16.03 0 10.5 0ZM10.5 18C6.09 18 2.5 14.41 2.5 10C2.5 5.59 6.09 2 10.5 2C14.91 2 18.5 5.59 18.5 10C18.5 14.41 14.91 18 10.5 18Z"
                          fill="#FF3636"
                        />
                      </svg>
                    </div>

                    <div className="div_width">
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                      />
                    </div>
                  </div>
                )}
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default FileUpload;